import { array, string } from "prop-types";

export const environments = [
  {
    key: "local",
    label: "Local",
    url: "http://localhost:3000/",
  },
  {
    key: "staging",
    label: "Staging",
    url: "https://givsly-staging.herokuapp.com/",
  },
  {
    key: "pre-production",
    label: "Pre-production",
    url: "https://givsly-pre-production.herokuapp.com/",
  },
  {
    key: "sandbox",
    label: "Sandbox",
    url: "https://givsly-sandbox.herokuapp.com/",
  },
  {
    key: "production",
    label: "Production",
    url: "https://www.givsly.com/",
  },
];

export const invitation = {
  fieldConfiguration: {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    companyName: string,
    companyUrl: string,
    boardMemberNPOs: array,
  },
};

export const nonprofitTermsAndPrivacyApprovalVersions = [
  "2020-01-04",
  "2020-11-15",
];

export const latestNonprofitTermsAndPrivacyApprovalVersion =
  nonprofitTermsAndPrivacyApprovalVersions[
    nonprofitTermsAndPrivacyApprovalVersions.length - 1
  ];

export const categories = [
  {
    key: "Animals",
    label: "Animals",
  },
  {
    key: "Arts",
    label: "Arts",
  },
  {
    key: "Education",
    label: "Education",
  },
  {
    key: "Environment",
    label: "Environment",
  },
  {
    key: "Health",
    label: "Health",
  },
  {
    key: "Human Services",
    label: "Human Services",
  },
  {
    key: "Human and Civil Rights",
    label: "Human and Civil Rights",
  },
  {
    key: "International Development and Relief Services",
    label: "International Development and Relief Services",
  },
  {
    key: "Youth Development",
    label: "Youth Development",
  },
];
