import React from "react";
import classNames from "classnames";
import { string } from "prop-types";

class MainContent extends React.Component {
  render() {
    const { children, className } = this.props;
    const classes = classNames("content", className);
    return <div className={classes}>{children}</div>;
  }
}

MainContent.propTypes = {
  className: string,
};

MainContent.defaultProps = {
  className: null,
};

export default MainContent;
