import React from "react";
import css from "./Filters.module.css";
import { object, shape, string } from "prop-types";
import { parse } from "../../utils/urlHelper";
import { NamedLink } from "../index";
import { filterNames } from "../../utils/filterHelper";

class Filters extends React.Component {
  protectedQueryParameters = ["keywords", "page"];

  render() {
    const { children, location, pageName, pathParams } = this.props;
    const queryParams = parse(location.search);

    return (
      <div className={css.root}>
        <div className={css.activeFilters}>
          {Object.keys(queryParams).map((key) => {
            if (this.protectedQueryParameters.indexOf(key) >= 0) {
              return null;
            } else {
              const filteredQueryParams = { ...queryParams };
              delete filteredQueryParams[key];
              return (
                <NamedLink
                  className={css.activeFilter}
                  key={`filter-${key}`}
                  name={pageName}
                  pathParams={pathParams}
                  params={filteredQueryParams}
                >
                  {filterNames[key]}: {queryParams[key]}
                </NamedLink>
              );
            }
          })}
        </div>
        {children}
      </div>
    );
  }
}

Filters.propTypes = {
  location: shape({
    search: string,
  }).isRequired,
  pageName: string.isRequired,
  pathParams: object,
};

export default Filters;
