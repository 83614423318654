import React from "react";
import { MainContent } from "../../components";
import { LoginForm } from "../../forms";
import css from "./AuthenticationPage.module.css";
import { connect } from "react-redux";
import { login } from "../../ducks/auth.duck";
import { compose } from "redux";
import { bool, func } from "prop-types";
import { Redirect } from "react-router";
import { LEVEL_ERROR } from "../../utils/notificationHelper";
import { addNotification } from "../../ducks/notification.duck";
import { STATUS_ERROR } from "../../utils/statusHelper";

class AuthenticationPage extends React.Component {
  handleLogin = (submittedValues) => {
    const { onAddNotification, onLogin } = this.props;
    const { email, password } = submittedValues;
    onLogin(email, password)
      .then((response) => {
        if (response.status === STATUS_ERROR) {
          onAddNotification(`Login failed: ${response.reason}`);
        }
        return response;
      })
      .catch((e) => {
        onAddNotification(`Login failed: ${e}`);
      });
  };

  render() {
    const { loggedIn, loginInProgress } = this.props;

    if (loggedIn && !loginInProgress) {
      return <Redirect to={"/invitations"} />;
    }

    return (
      <MainContent className={css.mainContent}>
        <LoginForm onSubmit={this.handleLogin} />
      </MainContent>
    );
  }
}

AuthenticationPage.propTypes = {
  loggedIn: bool.isRequired,
  loginInProgress: bool,
  onLogin: func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onAddNotification: (message, level = LEVEL_ERROR) =>
    dispatch(addNotification(message, level)),
  onLogin: (email, password) => dispatch(login(email, password)),
});

const mapStateToProps = (state) => {
  const { loggedIn, loginFailReason, loginInProgress } = state.auth;

  return {
    loggedIn,
    loginFailReason,
    loginInProgress,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AuthenticationPage
);
