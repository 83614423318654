import React, { FC } from "react";
import { Field } from "react-final-form";
import { Badge } from "../Badge";

interface iFormSelectField {
  name: string;
  label: string;
  options: string[] | Array<{ value: string; label: string }>;
  st?: boolean;
  hint?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

export const FormSelectField: FC<iFormSelectField> = ({
  name,
  label,
  options,
  st = false,
  hint,
  disabled = false,
  onChange,
}) => {
  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <label>
          {label} {st && <Badge>ST</Badge>}
          {hint && (
            <>
              <br />
              <small>{hint}</small>
            </>
          )}
          <select
            {...input}
            disabled={disabled}
            onChange={(...args) => {
              input.onChange(...args);
              if (typeof onChange === "function") {
                const value = args[0].target.value;
                onChange(value);
              }
            }}
          >
            {options.map((value) => {
              if (typeof value === "string") {
                return (
                  <option value={value} key={value}>
                    {value}
                  </option>
                );
              }

              return (
                <option value={value.value} key={value.value}>
                  {value.label}
                </option>
              );
            })}
          </select>
          {meta.error && meta.touched && (
            <span style={{ color: "var(--failColor)" }}>{meta.error}</span>
          )}
        </label>
      )}
    />
  );
};
