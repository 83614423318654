import React from "react";
import { Marketplace } from "../../constants";

enum FlexLinkType {
  listing = "listings",
  transaction = "transactions",
  user = "users",
}

type FlexTypedLinkProps = {
  children: React.ReactNode;
  marketplace: Marketplace;
  uuid: string;
};

type FlexLinkProps = FlexTypedLinkProps & {
  linkType: FlexLinkType;
};

/**
 * Generic Flex Console link component with flexible link type and marketplace.
 *
 * @param props
 * @constructor
 */
const FlexLink = (props: FlexLinkProps) => {
  return (
    <a
      href={`https://flex-console.sharetribe.com/o/givsly/m/${props.marketplace}/${props.linkType}?id=${props.uuid}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  );
};

/**
 * Link to a listing in the Flex Console
 *
 * @param props
 * @constructor
 */
export const FlexListingLink = (props: FlexTypedLinkProps) => (
  <FlexLink
    marketplace={props.marketplace}
    uuid={props.uuid}
    linkType={FlexLinkType.listing}
  >
    {props.children}
  </FlexLink>
);

/**
 * Link to a transaction in Flex Console
 *
 * @param props
 * @constructor
 */
export const FlexTransactionLink = (props: FlexTypedLinkProps) => (
  <FlexLink
    marketplace={props.marketplace}
    uuid={props.uuid}
    linkType={FlexLinkType.transaction}
  >
    {props.children}
  </FlexLink>
);

/**
 * Link to a user in Flex Console
 *
 * @param props
 * @constructor
 */
export const FlexUserLink = (props: FlexTypedLinkProps) => (
  <FlexLink
    marketplace={props.marketplace}
    uuid={props.uuid}
    linkType={FlexLinkType.user}
  >
    {props.children}
  </FlexLink>
);
