import { FormApi } from "final-form";
import React, { FC, useCallback, useMemo, useState } from "react";
import {
  FormRenderProps,
  Form as FinalForm,
  AnyObject,
  Field,
} from "react-final-form";
import { Button } from "../../../components";
import { createTenantUser } from "../api";
import css from "../TenantManagerPage.module.css";

interface iTenantUserForm {
  tenant_id: string;
  isGA: boolean;
  isGM: boolean;
  onAfterSubmit?: <T = any>(result: T) => void;
}

interface BadgeProps {
  text: string;
  variant: "info" | "error" | "success" | "warning";
}

const Badge: FC<BadgeProps> = ({ text, variant }) => {
  const styles: React.CSSProperties = useMemo(() => {
    let bgColor: string;

    switch (variant) {
      case "info":
        bgColor = "blue";
        break;
      case "error":
        bgColor = "red";
        break;
      case "success":
        bgColor = "green";
        break;
      case "warning":
        bgColor = "orange";
        break;
      default:
        bgColor = "gray";
        break;
    }

    return {
      backgroundColor: bgColor,
      color: "#fff",
      fontSize: 12,
      padding: "2px 4px",
      lineHeight: "14px",
      borderRadius: 4,
      marginLeft: 8,
      textTransform: "uppercase",
    };
  }, [variant]);

  return <span style={styles}>{text}</span>;
};

const TenantUserForm: FC<iTenantUserForm> = ({
  tenant_id,
  onAfterSubmit,
  isGA,
  isGM,
}) => {
  const [error, setError] = useState<string>();
  const [submitting, setIsSubmitting] = useState(false);
  const onSubmit = useCallback(
    async (values: AnyObject, form: FormApi<AnyObject, Partial<AnyObject>>) => {
      setIsSubmitting(true);
      setError(undefined);

      const email = values["email"];

      if (!email || typeof email !== "string") {
        setError("Email is invalid");
        setIsSubmitting(false);
        return;
      }

      const payload = { ...values, tenant_id };
      const result = await createTenantUser(payload);

      if (result.error) {
        setError(result.error);
        setIsSubmitting(false);
        return;
      }

      if (onAfterSubmit && typeof onAfterSubmit === "function") {
        onAfterSubmit(result);
      }

      setIsSubmitting(false);
      setTimeout(() => {
        form.reset();
      });
    },
    [onAfterSubmit, tenant_id]
  );

  return (
    <>
      {error && <div className={css.error}>{error}</div>}
      <FinalForm
        onSubmit={onSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { handleSubmit, form } = formRenderProps;

          return (
            <form onSubmit={handleSubmit}>
              <Field
                type="email"
                name="email"
                required
                render={(fieldProps) => {
                  const { input } = fieldProps;

                  return (
                    <div>
                      <label htmlFor="email">
                        Email for tenant user to login to GA and/or GM services
                      </label>
                      <input id="email" {...input} />
                    </div>
                  );
                }}
              />
              <div style={{ marginBottom: 32 }}>
                <Field
                  type="checkbox"
                  name="is_sub_gm"
                  render={(fieldProps) => (
                    <label
                      className={css["check-box-field"]}
                      style={{ fontWeight: "bold" }}
                    >
                      <input
                        {...fieldProps.input}
                        disabled={!isGM}
                        onChange={(event) => {
                          const checked = event.target.checked;
                          form.change("is_sub_gm", checked);
                          if (checked === false) {
                            form.change("is_payer", checked);
                            // form.change("is_gm_manager", checked);
                          }
                        }}
                      />{" "}
                      Subscribes to Good Marketing{" "}
                      {!isGM && (
                        <Badge text="Tenant isn't GM capable" variant="error" />
                      )}
                    </label>
                  )}
                />
                <div className={css["checkbox-sub-group"]}>
                  {/* <Field
                    name="is_gm_manager"
                    type="checkbox"
                    render={(fieldProps) => (
                      <label className={css["check-box-field"]}>
                        <input
                          {...fieldProps.input}
                          disabled={
                            form.getFieldState("is_sub_gm")?.value === false
                          }
                        />{" "}
                        This user is tenant’s GM organization manager
                      </label>
                    )}
                  /> */}
                  <Field
                    name="is_payer"
                    type="checkbox"
                    render={(fieldProps) => (
                      <label className={css["check-box-field"]}>
                        <input
                          {...fieldProps.input}
                          disabled={
                            form.getFieldState("is_sub_gm")?.value === false ||
                            !isGM
                          }
                        />{" "}
                        This user is tenant’s GM payer
                      </label>
                    )}
                  />
                </div>
                <Field
                  type="checkbox"
                  name="is_sub_ga"
                  render={(fieldProps) => (
                    <label
                      className={css["check-box-field"]}
                      style={{ fontWeight: "bold" }}
                    >
                      <input
                        {...fieldProps.input}
                        onChange={(event) => {
                          const checked = event.target.checked;
                          form.change("is_sub_ga", checked);
                          form.change("is_sales", checked);
                          form.change("is_ops", checked);
                          if (checked === false) {
                            form.change("email_verified", checked);
                          }
                        }}
                        disabled={!isGA}
                      />{" "}
                      Subscribes to Good Advertising
                      {!isGA && (
                        <Badge text="Tenant isn't GA capable" variant="error" />
                      )}
                    </label>
                  )}
                />
                <div className={css["checkbox-sub-group"]}>
                  <label style={{ fontWeight: 600, fontSize: 14 }}>
                    User GA role-group(s) (one is mandatory, multiple are OK)
                  </label>
                  <Field
                    name="is_sales"
                    type="checkbox"
                    render={(fieldProps) => (
                      <label className={css["check-box-field"]}>
                        <input
                          {...fieldProps.input}
                          disabled={
                            !isGA ||
                            form.getFieldState("is_sub_ga")?.value === false ||
                            form.getFieldState("is_ops")?.value === false
                          }
                        />{" "}
                        Sales/Business
                      </label>
                    )}
                  />
                  <Field
                    name="is_ops"
                    type="checkbox"
                    render={(fieldProps) => (
                      <label className={css["check-box-field"]}>
                        <input
                          {...fieldProps.input}
                          disabled={
                            !isGA ||
                            form.getFieldState("is_sub_ga")?.value === false ||
                            form.getFieldState("is_sales")?.value === false
                          }
                        />{" "}
                        Campaign Operations
                      </label>
                    )}
                  />
                </div>
                <Field
                  type="checkbox"
                  name="send_invite"
                  render={(fieldProps) => (
                    <label className={css["check-box-field"]}>
                      <input {...fieldProps.input} /> Send email invite
                    </label>
                  )}
                />
              </div>
              <Button type="submit" disabled={submitting}>
                {submitting ? "Saving..." : "Save user"}
              </Button>
            </form>
          );
        }}
      />
    </>
  );
};

TenantUserForm.defaultProps = {
  onAfterSubmit: undefined,
};

export default TenantUserForm;
