export interface iAuthInfo {
  email: string;
  firstName: string;
  lastName: string;
}

export function isRecord(obj: unknown): obj is Record<string, unknown> {
  return (
    typeof obj === "object" &&
    obj !== null &&
    !Array.isArray(obj) &&
    !(obj instanceof Date)
  );
}

export function getAuthInfo(props: unknown): iAuthInfo | undefined {
  if (
    props &&
    isRecord(props) &&
    "authInfo" in props &&
    props["authInfo"] &&
    isRecord(props["authInfo"]) &&
    "email" in props["authInfo"] &&
    "firstName" in props["authInfo"] &&
    "lastName" in props["authInfo"] &&
    typeof props["authInfo"]["email"] === "string" &&
    typeof props["authInfo"]["firstName"] === "string" &&
    typeof props["authInfo"]["lastName"] === "string"
  ) {
    return {
      email: props["authInfo"]["email"],
      firstName: props["authInfo"]["firstName"],
      lastName: props["authInfo"]["lastName"],
    };
  }

  return undefined;
}


export function isValidUUID(value: string): boolean {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(value);
}

export function isValidDomain(value: string): boolean {
  const domainRegex =
  /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;

  return domainRegex.test(value);
}

export function isValidCountryCode(value: string): boolean {
  const countryRegex = /^[A-Z]{2}$/;
  return countryRegex.test(value);
}

export function isValidEmail(value: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
}

export function isValidEINNumber(value: string): boolean {
  const einRegex = /^(?:\d{2}-\d{7}|\d{9})$/;
  return einRegex.test(value);
}
