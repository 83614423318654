import React from "react";

const formatMoney = (value: number | undefined): string => {
  if (!value) {
    value = 0;
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(value);
};

const DonationOfferOverview = ({ counts }: { counts: any }) => {
  return (
    <table>
      <thead>
        <tr>
          <th />
          <th>All time</th>
          <th>YTD</th>
          <th>This month</th>
          <th>Last full day</th>
        </tr>
      </thead>
      <tbody>
        {counts &&
          counts.map((row: any, i: number) => {
            return (
              <tr key={i}>
                <td>{row.label}</td>
                <td>{row.isMoney ? formatMoney(row.count) : row.count}</td>
                <td>
                  {row.isMoney ? formatMoney(row.countYTD) : row.countYTD}
                </td>
                <td>
                  {row.isMoney
                    ? formatMoney(row.countThisMonth)
                    : row.countThisMonth}
                </td>
                <td>
                  {row.isMoney
                    ? formatMoney(row.countLastFullDay)
                    : row.countLastFullDay}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default DonationOfferOverview;
