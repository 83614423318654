import React from "react";
import css from "./TagField.module.css";
import classNames from "classnames";
import { arrayOf, func, shape, string } from "prop-types";
import { Field } from "react-final-form";
import { Tag } from "../index";

class TagFieldComponent extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.result = React.createRef();
    this.handleAddTag = this.handleAddTag.bind(this);
    this.handleRemoveTag = this.handleRemoveTag.bind(this);
    this.onSelectionChange = this.onSelectionChange.bind(this);

    this.state = {
      selectedOption: "",
      options: this.getOptions(),
    };
  }

  componentDidMount() {
    this.setState({ options: this.getOptions() });
  }

  handleAddTag() {
    const result = Array.isArray(JSON.parse(this.result.current.value))
      ? JSON.parse(this.result.current.value)
      : [];
    const newValue = this.input.current.value;

    if (!newValue) return;

    if (result.indexOf(newValue) < 0) {
      result.push(newValue);
    }

    this.result.current.value = JSON.stringify(result);
    this.input.current.value = "";
    this.props.onChange(result);

    this.setState({ options: this.getOptions(), selectedOption: "" });
  }

  handleRemoveTag(e) {
    const result = JSON.parse(this.result.current.value);
    const newResult = result.filter((tag) => {
      return tag !== e.target.innerText;
    });
    this.props.onChange(newResult);

    this.result.current.value = JSON.stringify(newResult);

    this.setState({ options: this.getOptions(), selectedOption: "" });
  }

  onSelectionChange(event) {
    this.setState({ selectedOption: event.target.value });
  }

  getOptions() {
    const result = this.result.current
      ? JSON.parse(this.result.current.value)
      : [];

    return this.props.options
      .filter((option) => !result.includes(option.key))
      .map((option) => (
        <option value={option.key} key={option.key}>
          {option.value}
        </option>
      ));
  }

  render() {
    const {
      className,
      id,
      input,
      label,
      meta,
      name,
      options,
      ...rest
    } = this.props;
    const { valid, invalid, touched, error } = meta;
    const { value: defaultValue, ...inputWithoutValue } = input;

    const inputProps = {
      id,
      defaultValue: JSON.stringify(defaultValue),
      ...inputWithoutValue,
      ...rest,
    };

    const hasError = (meta.error && meta.touched) || invalid;
    const hasSuccess = (touched && error) || valid;
    const classes = classNames(
      css.root,
      className,
      hasSuccess ? css.success : null,
      hasError ? css.error : null
    );

    const tags = [...defaultValue];

    return (
      <div className={classes}>
        {label && <label htmlFor={id}>{label}</label>}
        <div className={css.tags}>
          {tags.map((tag) => {
            return <Tag key={tag} onClick={this.handleRemoveTag} label={tag} />;
          })}
        </div>
        <div className={css.addTag}>
          {!options && (
            <input id={`${id}-add`} ref={this.input} type={"text"} />
          )}
          {options && (
            <select
              id={`${id}-add`}
              ref={this.input}
              className={css.selectTag}
              onChange={this.onSelectionChange}
              disabled={this.state.options.length === 0}
            >
              <option value="">Select a role</option>
              {this.state.options}
            </select>
          )}
          <button
            className={css.addButton}
            onClick={this.handleAddTag}
            type={"button"}
            disabled={options && !this.state.selectedOption}
          >
            + Add
          </button>
        </div>
        <input id={id} ref={this.result} type={"hidden"} {...inputProps} />
        {hasError && <span className={css.message}>{meta.error}</span>}
      </div>
    );
  }
}

TagFieldComponent.propTypes = {
  className: string,
  id: string.isRequired,
  label: string,
  onChange: func.isRequired,
  options: arrayOf(shape({ key: string.isRequired, value: string.isRequired })),
};

TagFieldComponent.defaultProps = {
  className: null,
  label: null,
};

class TagField extends React.Component {
  render() {
    return <Field component={TagFieldComponent} {...this.props} />;
  }
}

export default TagField;
