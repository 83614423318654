import React from "react";
import css from "./Button.module.css";
import { string } from "prop-types";
import classNames from "classnames";

class Button extends React.Component {
  render() {
    const { children, className, ...rest } = this.props;
    const classes = classNames(css.root, className);
    return (
      <button className={classes} {...rest}>
        {children}
      </button>
    );
  }
}

Button.propTypes = {
  className: string,
  type: string,
};

Button.defaultProps = {
  className: null,
  type: "button",
};

export default Button;
