import { STATUS_ERROR, STATUS_OK } from "../utils/statusHelper";
import axios from "axios";
import { getApiHeaders } from "../utils/api";

const API_PATH = "nonprofits";

// Actions
const ACTION_FETCH_NONPROFIT_REQUEST =
  "givsly-dashboard/nonprofit/FETCH_NONPROFIT_REQUEST";
const ACTION_FETCH_NONPROFIT_SUCCESS =
  "givsly-dashboard/nonprofit/FETCH_NONPROFIT_SUCCESS";
const ACTION_FETCH_NONPROFIT_ERROR =
  "givsly-dashboard/nonprofit/FETCH_NONPROFIT_ERROR";
const ACTION_QUERY_NONPROFITS_REQUEST =
  "givsly-dashboard/nonprofit/QUERY_NONPROFITS_REQUEST";
const ACTION_QUERY_NONPROFITS_SUCCESS =
  "givsly-dashboard/nonprofit/QUERY_NONPROFITS_SUCCESS";
const ACTION_QUERY_NONPROFITS_ERROR =
  "givsly-dashboard/nonprofit/QUERY_NONPROFITS_ERROR";
const ACTION_CLEAR_QUERY_RESULTS =
  "givsly-dashboard/nonprofit/CLEAR_QUERY_RESULTS";
const ACTION_UPDATE_NONPROFIT_REQUEST =
  "givsly-dashboard/nonprofit/UPDATE_NONPROFIT_REQUEST";
const ACTION_UPDATE_NONPROFIT_SUCCESS =
  "givsly-dashboard/nonprofit/UPDATE_NONPROFIT_SUCCESS";
const ACTION_UPDATE_NONPROFIT_ERROR =
  "givsly-dashboard/nonprofit/UPDATE_NONPROFIT_ERROR";

// Initial state
const initialState = {
  fetchNonprofitError: false,
  fetchNonprofitInProgress: false,
  nonprofit: null,
  nonprofits: [],
  queryError: false,
  queryInProgress: false,
  queryMeta: {},
  updateNonprofitError: false,
  updateNonprofitInProgress: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
    case ACTION_CLEAR_QUERY_RESULTS:
      return {
        ...state,
        nonprofits: [],
      };
    case ACTION_FETCH_NONPROFIT_REQUEST:
      return {
        ...state,
        fetchNonprofitError: false,
        fetchNonprofitInProgress: true,
        nonprofit: null,
      };
    case ACTION_FETCH_NONPROFIT_SUCCESS:
      return {
        ...state,
        fetchNonprofitInProgress: false,
        nonprofit: payload.data,
      };
    case ACTION_FETCH_NONPROFIT_ERROR:
      return {
        ...state,
        fetchNonprofitError: true,
        fetchNonprofitInProgress: false,
      };
    case ACTION_QUERY_NONPROFITS_REQUEST:
      return {
        ...state,
        nonprofits: [],
        queryError: false,
        queryInProgress: true,
        queryMeta: {},
      };
    case ACTION_QUERY_NONPROFITS_SUCCESS:
      return {
        ...state,
        nonprofits: payload.data.data,
        queryInProgress: false,
        queryMeta: payload.data.meta,
      };
    case ACTION_QUERY_NONPROFITS_ERROR:
      return {
        ...state,
        nonprofits: [],
        queryError: true,
        queryInProgress: false,
        queryMeta: {},
      };
    case ACTION_UPDATE_NONPROFIT_REQUEST:
      return {
        ...state,
        updateMemberError: false,
        updateMemberInProgress: true,
      };
    case ACTION_UPDATE_NONPROFIT_SUCCESS:
      return {
        ...state,
        updateNonprofitError: false,
        updateNonprofitInProgress: false,
      };
    case ACTION_UPDATE_NONPROFIT_ERROR:
      return {
        ...state,
        updateNonprofitError: true,
        updateNonprofitInProgress: false,
      };
  }
}

// Action creators
export const clearQueryResults = () => {
  return {
    type: ACTION_CLEAR_QUERY_RESULTS,
  };
};

const fetchNonprofitRequest = () => {
  return {
    type: ACTION_FETCH_NONPROFIT_REQUEST,
  };
};

const fetchNonprofitSuccess = (data) => {
  return {
    type: ACTION_FETCH_NONPROFIT_SUCCESS,
    payload: data,
  };
};

const fetchNonprofitError = (error) => {
  return {
    type: ACTION_FETCH_NONPROFIT_ERROR,
    payload: error,
  };
};

const queryNonprofitsRequest = () => {
  return {
    type: ACTION_QUERY_NONPROFITS_REQUEST,
  };
};

const queryNonprofitsSuccess = (data) => {
  return {
    type: ACTION_QUERY_NONPROFITS_SUCCESS,
    payload: data,
    status: STATUS_OK,
  };
};

const queryNonprofitsError = (error) => {
  return {
    type: ACTION_QUERY_NONPROFITS_ERROR,
    payload: error,
    status: STATUS_ERROR,
  };
};

const updateNonprofitRequest = () => {
  return {
    type: ACTION_UPDATE_NONPROFIT_REQUEST,
  };
};

const updateNonprofitSuccess = () => {
  return {
    type: ACTION_UPDATE_NONPROFIT_SUCCESS,
    status: STATUS_OK,
  };
};

const updateNonprofitError = (error) => {
  return {
    type: ACTION_UPDATE_NONPROFIT_ERROR,
    payload: error,
    status: STATUS_ERROR,
  };
};

// Thunks
export const fetchNonprofit = (id) => (dispatch) => {
  dispatch(fetchNonprofitRequest());
  return axios
    .get(`${process.env.REACT_APP_API_END_POINT}${API_PATH}/${id}`, {
      timeout: 5000,
      headers: getApiHeaders(),
    })
    .then((response) => {
      dispatch(fetchNonprofitSuccess(response.data));
      return response.data.data;
    })
    .catch((e) => {
      return dispatch(fetchNonprofitError(e));
    });
};

export const queryNonprofits = (queryParams = {}) => (dispatch) => {
  dispatch(queryNonprofitsRequest());
  return axios
    .get(`${process.env.REACT_APP_API_END_POINT}${API_PATH}`, {
      params: queryParams,
      timeout: 5000,
      headers: getApiHeaders(),
    })
    .then((response) => {
      return dispatch(queryNonprofitsSuccess(response));
    })
    .catch((e) => {
      return dispatch(queryNonprofitsError(e));
    });
};

export const updateNonprofit = (id, updatedValues) => (dispatch) => {
  dispatch(updateNonprofitRequest());
  return axios
    .patch(
      `${process.env.REACT_APP_API_END_POINT}${API_PATH}/${id}`,
      updatedValues,
      {
        timeout: 5000,
        headers: getApiHeaders(),
      }
    )
    .then((response) => {
      dispatch(updateNonprofitSuccess());
      return response.data;
    })
    .catch((e) => {
      dispatch(updateNonprofitError(e));
      return e;
    });
};
