import { GROUPS, iTenant,iNonprofitCreate, iUser } from "@givsly/aws-tenant-manager";
import { getApiHeaders } from "../../utils/api";
import {
  iDAFNonprofit,
  iNonprofit,
} from "@givsly/aws-tenant-manager/lib/types";

export async function fetchTenants(): Promise<iTenant[]> {
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}tenants`;
  const response = await fetch(url, { headers });
  const json = await response.json();
  return json as iTenant[];
}

export async function fetchTenant(tenant_id: string): Promise<iTenant> {
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}tenants/${tenant_id}`;
  const response = await fetch(url, { headers });
  const json = await response.json();
  return json as iTenant;
}

export async function fetchTenantUsers(tenant_id: string): Promise<iUser[]> {
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}tenants/${tenant_id}/users`;
  const response = await fetch(url, { headers });
  const json = await response.json();
  return json as iUser[];
}

export async function createTenantUser(payload: any) {
  const body = JSON.stringify(payload);
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}tenants/users`;
  const response = await fetch(url, {
    headers: { ...headers, "Content-Type": "application/json" },
    method: "POST",
    body,
  });

  switch (response.status) {
    case 404:
    case 403:
    case 401:
    case 201:
    case 400:
    case 200: {
      const json = await response.json();
      return json;
    }
    default: {
      return { error: "unknown error" };
    }
  }
}

interface iGroupMutationParams {
  username: string;
  email: string;
  group: GROUPS;
  tenant_id: string;
}
export async function addUserToGroup(params: iGroupMutationParams) {
  const { username, email, group, tenant_id } = params;
  const body = JSON.stringify({ username, email, group, tenant_id });
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}tenants/users/groups`;
  const response = await fetch(url, {
    headers: { ...headers, "Content-Type": "application/json" },
    method: "POST",
    body,
  });

  return response.status;
}

export async function removeUserFromGroup(params: iGroupMutationParams) {
  const { username, email, group, tenant_id } = params;
  const body = JSON.stringify({ username, email, group, tenant_id });
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}tenants/users/groups`;
  const response = await fetch(url, {
    headers: { ...headers, "Content-Type": "application/json" },
    method: "DELETE",
    body,
  });

  return response.status;
}

export async function removeTenantUser(username: string, email: string) {
  const body = JSON.stringify({ username, email });
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}tenants/users`;
  const response = await fetch(url, {
    headers: { ...headers, "Content-Type": "application/json" },
    method: "DELETE",
    body,
  });

  return response.status;
}

export async function createTenant(payload: object) {
  const body = JSON.stringify(payload);
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}tenants`;
  const response = await fetch(url, {
    headers: { ...headers, "Content-Type": "application/json" },
    method: "POST",
    body,
  });

  let json: Record<any, any> = {};
  if (response.headers.get("Content-Type")?.match("application/json")) {
    json = await response.json();
  } else if (response.status >= 400) {
    const error = await response.text();
    json = { error };
  }

  return { status: response.status, json };
}

export async function verifyUserEmail(username: string) {
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}tenants/users/${username}/verify_email`;
  const response = await fetch(url, {
    headers: { ...headers, "Content-Type": "application/json" },
    method: "POST",
  });
  return response.status;
}

export async function setDefaultPayer(tenant_id: string, payer_id: string) {
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}tenants/set-default-payer`;
  const response = await fetch(url, {
    headers: { ...headers, "Content-Type": "application/json" },
    body: JSON.stringify({ tenant_id, payer_id }),
    method: "POST",
  });

  const json = await response.json();

  return { status: response.status, json };
}

export async function getNonprofits() {
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}npos`;
  const response = await fetch(url, { headers });
  const json = await response.json();
  return json as iNonprofit[];
}

export async function getNonprofit(id: string) {
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}npos/${id}`;
  const response = await fetch(url, { headers });
  const json = await response.json();
  return json as iNonprofit;
}

export async function getNonprofitUsers(id: string) {
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}npos/${id}/users`;
  const response = await fetch(url, { headers });
  const json = await response.json();
  return json as iUser[];
}

export async function createNonprofitUser(npo_id: string, data: unknown) {
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}npos/${npo_id}/users`;
  const response = await fetch(url, {
    headers: { ...headers, "Content-Type": "application/json" },
    body: JSON.stringify(data),
    method: "POST",
  });

  const json = await response.json();

  return { status: response.status, json };
}

export async function ui_getSharetribeListingWithUser(listing_id: string) {
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}sharetribe/${listing_id}`;
  try {
    const response = await fetch(url, { headers });
    if (!response.ok) {
      const errorResponse = await response.json();
      const error = new Error(`${errorResponse.errorMessage}`);
      (error as any).status = response.status;
      throw error;
    }
    const json = await response.json();
    return json;
  } catch (error) {
    console.error('Error fetching Sharetribe listing with user:', error);
    throw error;
  }
}


export async function ui_createNonprofit(
  payload: iNonprofitCreate,
  isAdmin: boolean,
  sendEmailInvite: boolean
) {
  const headers = getApiHeaders();
  const url = new URL(`${process.env.REACT_APP_API_END_POINT}npos`);

  if (isAdmin) {
    url.searchParams.set("is_admin", "true");
  }

  if (sendEmailInvite) {
    url.searchParams.set("send_email_invite", "true");
  }

  const response = await fetch(url.toString(), {
    headers: { ...headers, "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    method: "POST",
  });

  const json = await response.json();

  return { status: response.status, json };
}

export async function updateNonprofitPrivateData(
  npo_id: string,
  listing_id: string,
  payload: unknown
) {
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}npos/${npo_id}/private?listing_id=${listing_id}`;
  const response = await fetch(url, {
    headers: { ...headers, "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    method: "PUT",
  });

  const json = await response.json();

  return { status: response.status, json };
}

export async function updateNonprofitPublicData(
  npo_id: string,
  listing_id: string,
  payload: unknown
) {
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}npos/${npo_id}/public?listing_id=${listing_id}`;
  const response = await fetch(url, {
    headers: { ...headers, "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    method: "PUT",
  });

  const json = await response.json();

  return { status: response.status, json };
}

export async function ui_updateTenantCapabilities(
  tenant_id: string,
  capabilities: Record<string, boolean>
) {
  const headers = getApiHeaders();
  const url = `${process.env.REACT_APP_API_END_POINT}tenants/${tenant_id}/capabilities`;
  const response = await fetch(url, {
    headers: { ...headers, "Content-Type": "application/json" },
    body: JSON.stringify(capabilities),
    method: "PUT",
  });

  const json = await response.json();

  return { status: response.status, json };
}

function isValidEINNumber(value: string): boolean {
  const einRegex = /^(?:\d{2}-\d{7}|\d{9})$/;
  return einRegex.test(value);
}

function sanitizeEIN(ein: string): string {
  // Use a regular expression to replace all non-numeric characters with an empty string
  return ein.replace(/\D/g, '');
}

export async function searchDafs(
  body: any
): Promise<{ nonprofits: iDAFNonprofit[] }> {
  
  if (!body) return { nonprofits: [] };
  if (!body.country) return { nonprofits: [] };
  if (!body.tax_id) return { nonprofits: [] };
  body.tax_id = sanitizeEIN(body.tax_id)
  if (!isValidEINNumber(body.tax_id)) return { nonprofits: [] };

  const headers = { ...getApiHeaders(), "Content-Type": "application/json" };
  const url = `${process.env.REACT_APP_API_END_POINT}npos/search_dafs`;
  const response = await fetch(url, {
    headers,
    method: "POST",
    body: JSON.stringify(body),
  });
  const json = (await response.json()) as { nonprofits: iDAFNonprofit[] };
  return json as any;
}
