import React, { FC, useCallback, useRef } from "react";

export const checkboxStyle: React.CSSProperties = {
  padding: 0,
  margin: 0,
  width: 18,
  height: 18,
};

interface iAsyncCheckbox {
  checked: boolean;
  onChange: (checked: boolean) => Promise<any>;
  forwardRef?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
}
export const AsyncCheckbox: FC<iAsyncCheckbox> = ({
  checked = false,
  onChange = (checked: boolean) => Promise.resolve(),
  forwardRef = {},
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const asyncHandler = useCallback(
    async (checked: boolean, onChange: (checked: boolean) => Promise<any>) => {
      if (ref.current) {
        ref.current.indeterminate = true;
      }

      await onChange(checked);

      if (ref.current) {
        ref.current.indeterminate = false;
      }
    },
    []
  );
  return (
    <input
      style={checkboxStyle}
      {...forwardRef}
      type="checkbox"
      checked={checked}
      ref={ref}
      onChange={() => asyncHandler(checked, onChange)}
    />
  );
};
