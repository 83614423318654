import React from "react";
import { Form as FinalForm } from "react-final-form";
import css from "./MemberForm.module.css";
import {
  Button,
  Form,
  NonprofitField,
  RadioGroup,
  TagField,
  TextField,
} from "../../components";
import { withRouter } from "react-router-dom";
import { array, arrayOf, bool, func, object, shape, string } from "prop-types";
import * as validators from "../../utils/validator";
import arrayMutators from "final-form-arrays";

class MemberForm extends React.Component {
  get initialValues() {
    const {
      boardMemberNPOs,
      companyName,
      eventRoles,
      firstName,
      lastName,
    } = this.props.member.attributes.publicData;
    const { isListingVisible } = this.props.member.attributes.metadata;
    return {
      boardMemberNPOs,
      companyName,
      eventRoles,
      firstName,
      lastName,
      isListingVisible: Boolean(isListingVisible).toString(),
    };
  }

  render() {
    const {
      cachedNonprofits,
      eventRoles,
      member = {},
      nonprofitsResult,
      nonprofitsQueryInProgress,
      onAttachNonprofit,
      onClearNonprofitsResult,
      onQueryNonprofits,
      submitInProgress,
      ...rest
    } = this.props;
    const { title } = member.attributes;
    const requiredValidator = validators.isRequired("This field is required");

    return (
      <div className={css.root}>
        <FinalForm
          {...rest}
          initialValues={this.initialValues}
          mutators={{
            ...arrayMutators,
            setEventRoles: (args, state, utils) => {
              utils.changeValue(state, "eventRoles", () => args[0]);
            },
            setBoardMemberNPOs: (args, state, utils) => {
              utils.changeValue(state, "boardMemberNPOs", () => args[0]);
            },
          }}
          render={(fieldRenderProps) => {
            const {
              form,
              handleSubmit,
              invalid,
              pristine,
              values,
            } = fieldRenderProps;

            const disabled = invalid || pristine || submitInProgress;
            return (
              <Form
                className={css.form}
                onSubmit={(e) => {
                  this.submittedValues = values;
                  return handleSubmit(e);
                }}
              >
                <h2 className={css.header}>Manage member '{title}'</h2>
                <TextField
                  id={"firstName"}
                  label={"First name"}
                  name={"firstName"}
                  validate={requiredValidator}
                />
                <TextField
                  id={"lastName"}
                  label={"Last name"}
                  name={"lastName"}
                  validate={requiredValidator}
                />
                <TextField
                  id={"companyName"}
                  label={"Company"}
                  name={"companyName"}
                  validate={requiredValidator}
                />
                <RadioGroup
                  id={"isListingVisible"}
                  label={"Is listing visible?"}
                  name={"isListingVisible"}
                  options={[
                    {
                      key: "yes",
                      label: "Yes",
                      value: Boolean(true).toString(),
                    },
                    {
                      key: "no",
                      label: "No",
                      value: Boolean(false).toString(),
                    },
                  ]}
                />
                <TagField
                  id={"eventRoles"}
                  label={"Event roles"}
                  name={"eventRoles"}
                  onChange={form.mutators.setEventRoles}
                  options={eventRoles.map((role) => ({
                    key: role,
                    value: role,
                  }))}
                />
                <NonprofitField
                  cachedNonprofits={cachedNonprofits}
                  id={"boardMemberNPOs"}
                  label={"Board member positions"}
                  name={"boardMemberNPOs"}
                  onAttachNonprofit={onAttachNonprofit}
                  onChange={form.mutators.setBoardMemberNPOs}
                  onClearQueryResults={onClearNonprofitsResult}
                  onQuery={onQueryNonprofits}
                  queryResults={nonprofitsResult}
                  queryInProgress={nonprofitsQueryInProgress}
                />
                <Button
                  className={css.submit}
                  disabled={disabled}
                  type={"submit"}
                >
                  Update member data
                </Button>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

MemberForm.propTypes = {
  cachedNonprofits: object,
  eventRoles: arrayOf(string),
  member: shape({
    id: shape({
      uuid: string,
    }),
    attributes: shape({
      title: string,
    }),
  }).isRequired,
  nonprofitsResult: array,
  nonprofitsQueryInProgress: bool,
  onAttachNonprofit: func.isRequired,
  onClearNonprofitsResult: func.isRequired,
  onSubmit: func.isRequired,
  onQueryNonprofits: func.isRequired,
  submitInProgress: bool,
};

MemberForm.defaultProps = {
  cachedNonprofits: {},
  initialValues: {},
  nonprofits: [],
  nonprofitsQueryInProgress: false,
  submitInProgress: false,
};

export default withRouter(MemberForm);
