import { useCallback, useEffect, useState } from "react";

export function useAsyncCallback<T = any>(fn: () => Promise<T>) {
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<Error>();

  const _controlledAsyncCallback = useCallback(
    async (controller?: AbortController) => {
      setIsLoading(true);
      setIsError(false);
      try {
        const result: T = await new Promise((resolve, reject) => {
          const _pending = fn();
          if (controller) {
            controller.signal.addEventListener("abort", () => {
              reject(new DOMException("Promise cancelled"));
            });
          }
          _pending.then(resolve).catch(reject);
        });
        setData(result);
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        } else {
          setError(new Error("Unknown error"));
        }
        setIsError(true);
      }
      setIsLoading(false);
    },
    [fn]
  );

  useEffect(() => {
    const controller = new AbortController();
    _controlledAsyncCallback(controller);
    return () => {
      controller.abort();
    };
  }, [_controlledAsyncCallback]);

  return {
    data,
    isLoading,
    isError,
    error,
    retry: _controlledAsyncCallback,
    setData,
  };
}
