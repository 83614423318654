import axios from "axios";
import { getApiHeaders } from "../utils/api";
import { STATUS_ERROR } from "../utils/statusHelper";

const API_PATH = "auth";

// Actions
const ACTION_LOGIN_REQUEST = "givsly-dashboard/auth/LOGIN_REQUEST";
const ACTION_LOGIN_SUCCESS = "givsly-dashboard/auth/LOGIN_SUCCESS";
const ACTION_LOGIN_FAIL = "givsly-dashboard/auth/LOGIN_FAIL";
const ACTION_LOGOUT_REQUEST = "givsly-dashboard/auth/LOGOUT_REQUEST";
const ACTION_AUTH_INFO_REQUEST = "givsly-dashboard/auth/AUTH_INFO_REQUEST";
const ACTION_AUTH_INFO_SUCCESS = "givsly-dashboard/auth/AUTH_INFO_SUCCESS";
const ACTION_AUTH_INFO_FAIL = "givsly-dashboard/auth/AUTH_INFO_FAIL";

const initialState = {
  authInfo: {},
  authInfoLoaded: false,
  authInfoInProgress: false,
  authInfoFail: false,
  loggedIn: false,
  loginInProgress: false,
  loginFailReason: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;
  switch (type) {
    default:
      return state;
    case ACTION_LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
      };
    case ACTION_LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loginInProgress: false,
      };
    case ACTION_LOGIN_FAIL:
      return {
        ...state,
        loginInProgress: false,
      };
    case ACTION_LOGOUT_REQUEST:
      return {
        ...state,
        loggedIn: false,
      };
    case ACTION_AUTH_INFO_REQUEST:
      return {
        ...state,
        loggedIn: false,
        authInfoInProgress: true,
        authInfoLoaded: true,
      };
    case ACTION_AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfo: payload,
        authInfoInProgress: false,
        loggedIn: true,
      };
    case ACTION_AUTH_INFO_FAIL:
      return {
        ...state,
        authInfoInProgress: false,
        authInfoFail: true,
        authInfoLoaded: true,
        loggedIn: false,
      };
  }
}

// Action creators
const loginRequest = () => {
  return {
    type: ACTION_LOGIN_REQUEST,
  };
};
const loginSuccess = () => {
  return {
    type: ACTION_LOGIN_SUCCESS,
  };
};
const loginFail = () => {
  return {
    type: ACTION_LOGIN_FAIL,
  };
};
const logoutRequest = () => {
  return {
    type: ACTION_LOGOUT_REQUEST,
  };
};
const authInfoRequest = () => {
  return {
    type: ACTION_AUTH_INFO_REQUEST,
  };
};
const authInfoSuccess = (data) => {
  return {
    type: ACTION_AUTH_INFO_SUCCESS,
    payload: data,
  };
};
const authInfoFail = () => {
  return {
    type: ACTION_AUTH_INFO_FAIL,
  };
};

// Thunks
export const login = (email, password) => (dispatch) => {
  dispatch(loginRequest());

  return axios
    .post(
      `${process.env.REACT_APP_API_END_POINT}${API_PATH}/login`,
      {
        email,
        password,
      },
      {
        timeout: 5000,
        headers: getApiHeaders(),
      }
    )
    .then((response) => {
      storeToken(response.data.data.token);
      dispatch(loginSuccess(response.data));
      return response.data;
    })
    .catch((e) => {
      dispatch(loginFail(e));
      return {
        status: STATUS_ERROR,
        reason: e.message
      };
    });
};

export const logout = () => (dispatch) => {
  dispatch(logoutRequest());
  removeToken();
};

export const authInfo = () => (dispatch) => {
  dispatch(authInfoRequest());

  if (hasToken()) {
    return axios
      .get(`${process.env.REACT_APP_API_END_POINT}${API_PATH}/info`, {
        timeout: 5000,
        headers: getApiHeaders(),
      })
      .then((response) => {
        storeToken(response.data.data.token);
        dispatch(authInfoSuccess(response.data.data));
      })
      .catch((e) => {
        dispatch(authInfoFail(e));
      });
  } else {
    dispatch(authInfoFail("Token not set"));
    return Promise.resolve({});
  }
};

// Helpers
const removeToken = () => {
  console.log("Removing token!");
  sessionStorage.removeItem("token");
};

const hasToken = () => {
  const token = sessionStorage.getItem("token");
  return token && token.length > 0;
};

const storeToken = (token) => {
  sessionStorage.setItem("token", token);
};
