import { GROUPS, iUser } from "@givsly/aws-tenant-manager";
import React, { FC, useMemo } from "react";
import { Form as FinalForm, AnyObject, Field } from "react-final-form";
import { setDefaultPayer } from "../api";

interface iTenantPayerForm {
  tenantId: string;
  currentDefaultPayer: string;
  options: iUser[];
}

export const TenantPayerForm: FC<iTenantPayerForm> = ({
  tenantId,
  options,
  currentDefaultPayer,
}) => {
  const payerUsers = useMemo(
    () =>
      options.filter(
        (user) =>
          typeof user.sharetribe_id === "string" &&
          user.sharetribe_id.length > 0 &&
          user.groups?.includes(GROUPS.GM_PAYER)
      ),
    [options]
  );

  console.log(payerUsers);

  return (
    <div
      style={{
        maxWidth: 400,
        marginTop: 64,
        marginBottom: 64,
        border: "1px solid #dadada",
        padding: 32,
        borderRadius: 4,
      }}
    >
      <h4>Select default payer for tenant</h4>
      <FinalForm
        onSubmit={async (values: AnyObject) => {
          const payer = values.payer;
          const { status, json } = await setDefaultPayer(tenantId, payer);
          if (json.error) {
            alert("Error Occured: " + json.error);
            return;
          }

          if (status >= 400) {
            alert("Error Occured: " + status);
            return;
          }

          alert("Payer set to: " + payer);
        }}
        initialValues={{ payer: currentDefaultPayer }}
        render={({ handleSubmit, submitting, pristine, values, form }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="payer"
              render={(fieldProps) => (
                <select
                  {...fieldProps}
                  value={values.payer}
                  onChange={(event) => {
                    const value = event.target.value;
                    form.change("payer", value);
                  }}
                >
                  <option value="" unselectable="on">
                    Select payer
                  </option>
                  {payerUsers.map((user) => (
                    <option key={user.sharetribe_id} value={user.sharetribe_id}>
                      {user.email}
                    </option>
                  ))}
                </select>
              )}
            />
            <button type="submit" style={{ marginTop: 16 }}>
              {submitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        )}
      />
    </div>
  );
};
