const VALID = undefined;
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const composeValidators = (...validators) => (value) => {
  return validators.reduce(
    (error, validator) => error || validator(value),
    undefined
  );
};

export const isValidEmail = (message) => (value) => {
  if (typeof value === "undefined" || value === null) {
    return VALID;
  }

  return EMAIL_REGEX.test(value) ? VALID : message;
};

export const isRequired = (message) => (value) => {
  return value ? VALID : message;
};
