import classNames from "classnames";
import React from "react";
import { Form as FinalForm } from "react-final-form";
import css from "./NonprofitForm.module.css";
import { Button, Form, RadioGroup, TextField } from "../../components";
import { withRouter } from "react-router-dom";
import { bool, func, shape, string } from "prop-types";
import * as validators from "../../utils/validator";
import arrayMutators from "final-form-arrays";
import {
  categories,
  latestNonprofitTermsAndPrivacyApprovalVersion,
} from "../../config";
import Select from "../../components/Select/Select";

class NonprofitForm extends React.Component {
  get initialValues() {
    const {
      publicData: { isApproved, nonprofitType, organizationCategory },
      title,
    } = this.props.nonprofit.attributes;
    return {
      nonprofitType,
      organizationCategory,
      title,
      isApproved: Boolean(isApproved).toString(),
    };
  }

  render() {
    const { nonprofit = {}, submitInProgress, ...rest } = this.props;

    const { title, createdAt } = nonprofit.attributes;

    const {
      id: { uuid: authorId },
      attributes: {
        profile: { displayName: authorName },
      },
      stripeAccount: { payouts_enabled: stripeAccountStatus },
    } = nonprofit.author;

    const {
      nonprofitTermsAndPrivacyApprovals,
      activity,
    } = nonprofit.author.attributes.profile.privateData;

    const lastSeen = activity && (activity.online || activity.logout);

    const { agreePrivacyPolicy, agreeTermsOfService, agreedOn } =
      (nonprofitTermsAndPrivacyApprovals &&
        nonprofitTermsAndPrivacyApprovals[
          latestNonprofitTermsAndPrivacyApprovalVersion
        ]) ||
      {};

    const termsAndPrivacyPolicyApprovalState =
      agreePrivacyPolicy && agreeTermsOfService ? "approved" : "notApproved";

    const requiredValidator = validators.isRequired("This field is required");

    return (
      <div className={css.root}>
        <FinalForm
          {...rest}
          initialValues={this.initialValues}
          mutators={{
            ...arrayMutators,
          }}
          render={(fieldRenderProps) => {
            const {
              handleSubmit,
              invalid,
              pristine,
              values,
            } = fieldRenderProps;

            const disabled = invalid || pristine || submitInProgress;

            return (
              <Form
                className={css.form}
                onSubmit={(e) => {
                  this.submittedValues = values;
                  return handleSubmit(e);
                }}
              >
                <h2 className={css.header}>Manage nonprofit '{title}'</h2>
                <TextField
                  id={"title"}
                  label={"Title"}
                  name={"title"}
                  validate={requiredValidator}
                />

                <Select
                  id={"organizationCategory"}
                  label={"Nonprofit Category"}
                  name={"organizationCategory"}
                  options={categories}
                />

                <RadioGroup
                  label={"Is approved and visible?"}
                  id={"isApproved"}
                  name={"isApproved"}
                  options={[
                    {
                      key: "yes",
                      label: "Yes",
                      value: Boolean(true).toString(),
                    },
                    {
                      key: "no",
                      label: "No",
                      value: Boolean(false).toString(),
                    },
                  ]}
                />

                <div className={css.readOnlyField}>
                  <label htmlFor="user">User</label>
                  <p id="user">
                    <a
                      href={`${process.env.REACT_APP_SHARETRIBE_CONSOLE_URL}users?id=${authorId}`}
                    >
                      {authorName}
                    </a>
                  </p>
                </div>

                <div className={css.readOnlyField}>
                  <label htmlFor="stripeAccountStatus">
                    Stripe Account Status
                  </label>
                  <p id="stripeAccountStatus">
                    <span
                      className={classNames(
                        css.status,
                        css[stripeAccountStatus ? "approved" : "notApproved"]
                      )}
                    />
                    {stripeAccountStatus ? " Ready" : " Not ready"}
                  </p>
                </div>

                <div className={css.readOnlyField}>
                  <label htmlFor="termsAndPrivacyPolicyApproval">
                    Latest Terms of Service and Privacy Policy Approval
                  </label>
                  <p id="termsAndPrivacyPolicyApproval">
                    <span
                      className={classNames(
                        css.status,
                        css[termsAndPrivacyPolicyApprovalState]
                      )}
                    />{" "}
                    {agreedOn}
                  </p>
                </div>

                <div className={css.readOnlyField}>
                  <label htmlFor="lastSeen">Last seen</label>
                  <p id="lastSeen">{lastSeen}</p>
                </div>

                <div className={css.readOnlyField}>
                  <label htmlFor="listingCreated">Listing created</label>
                  <p id="listingCreated">{createdAt}</p>
                </div>

                <Button
                  className={css.submit}
                  disabled={disabled}
                  type={"submit"}
                >
                  Update nonprofit data
                </Button>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

NonprofitForm.propTypes = {
  member: shape({
    id: shape({
      uuid: string,
    }),
    attributes: shape({
      title: string,
    }),
  }).isRequired,
  onSubmit: func.isRequired,
  onQueryNonprofits: func.isRequired,
  submitInProgress: bool,
};

NonprofitForm.defaultProps = {
  initialValues: {},
  submitInProgress: false,
};

export default withRouter(NonprofitForm);
