import React, { FC } from "react";
import { useAsyncCallback } from "../shared/useAsyncCallback";
import { fetchTenants } from "../api";
import { NamedLink } from "../../../components";
import Table from "../shared/Table";
import TenantForm from "./TenantForm";
const TABLE_HEADERS = ["Tenant", "Ads", "Admin", "Marketing", "Admin"];

const TenantList: FC = () => {
  const {
    data = [],
    isLoading,
    isError,
    retry,
  } = useAsyncCallback(fetchTenants);

  if (isError || !data)
    return (
      <p>
        Something went wrong.{" "}
        <button type="button" onClick={() => retry()}>
          refresh
        </button>
      </p>
    );

  return (
    <>
      <Table headers={TABLE_HEADERS} loading={isLoading}>
        {data.map(
          ({ tenant, profile: { name, admin, admin_gm }, admin: { capabilities } }) => (
            <tr key={tenant}>
              <td>
                <NamedLink
                  name="TenantManagerDetailPage"
                  pathParams={{ id: tenant, tab: "subscribers" }}
                >
                  {name} ( {tenant} )
                </NamedLink>
              </td>
              <td>
                {capabilities.advertising ? "Yes" : "No"}
              </td>
              <td>
                <a href={`mailto:${admin.email}`}>
                  {admin.name ? admin.name : `${admin.first_name} ${admin.last_name}`}
                </a>
              </td>
              <td>
                {capabilities.marketing ? "Yes" : "No"}
              </td>
              <td>
                <a href={`mailto:${admin_gm.email}`}>
                  {admin_gm.name ? admin_gm.name : `${admin_gm.first_name} ${admin_gm.last_name}`}
                </a>
              </td>
            </tr>
          )
        )}
      </Table>

      <div>
        <h2>Add new tenant</h2>
        <TenantForm onAfterSubmit={() => retry()} />
      </div>
    </>
  );
};

export default TenantList;
