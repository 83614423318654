import React from "react";

const GoodAdvertisingNPOs = ({
  goodAdvertisingNPOs,
}: {
  goodAdvertisingNPOs: any;
}) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "America/New_York",
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Row number</th>
          <th>NPO name</th>
          <th>Last updated date</th>
          <th>Joined date</th>
        </tr>
      </thead>
      <tbody>
        {goodAdvertisingNPOs &&
          [...goodAdvertisingNPOs]
            .sort(
              (a: any, b: any) =>
                new Date(b.created).getTime() - new Date(a.created).getTime()
            )
            .map((row: any, i: number) => {
              return (
                <tr key={i}>
                  <td>{goodAdvertisingNPOs.length - i}</td>
                  <td>
                    <a
                      href={`https://flex-console.sharetribe.com/o/givsly/m/givsly/listings?id=${row.id}`}
                    >
                      {row.name}
                    </a>
                  </td>
                  <td>
                    {new Date(row.updated).toLocaleDateString("en-US", options)}
                  </td>
                  <td>
                    {new Date(row.created).toLocaleDateString("en-US", options)}
                  </td>
                </tr>
              );
            })}
      </tbody>
    </table>
  );
};

export default GoodAdvertisingNPOs;
