import React, { useCallback, useState } from "react";
import { Button, MainContent, Sidebar } from "../../components";
import css from "./GoodAdvertisingPage.module.css";
import { gql, useQuery } from "@apollo/client";
import axios from "axios";
import { getApiHeaders } from "../../utils/api";
import spinnerUrl from "../../assets/images/spinner.svg";
import GoodAdvertisingNPOs from "./GoodAdvertisingNPOs";

const API_PATH = "goodAdvertising/xlsx";

const QUERY_TOTAL = gql`
  query getData {
    goodAdvertisingNPOs {
      id
      name
      created
      updated
    }
  }
`;

const exportXlsx = () => {
  return axios
    .get(`${process.env.REACT_APP_API_END_POINT}${API_PATH}`, {
      timeout: 60000,
      headers: getApiHeaders(),
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        response.headers["content-disposition"].split("=").pop()
      );
      document.body.appendChild(link);
      link.click();
    });
};

const GoodAdvertisingPage = () => {
  // Query GraphQL and unpack the results
  const { error, data } = useQuery(QUERY_TOTAL);
  const [isXlsxBuilding, setIsXlsxlBuilding] = useState(false);

  const buildXlsx = useCallback(async () => {
    setIsXlsxlBuilding(true);
    await exportXlsx();
    setIsXlsxlBuilding(false);
  }, []);

  return (
    <div className={css.root}>
      <Sidebar />
      <MainContent className={css.mainContent}>
        <h1>Good Advertising</h1>
        <h5>Times in EST</h5>
        {error ? (
          <div className={css.error}>
            An error occurred while attempting to load the data:
            <code>{error?.message}</code>
          </div>
        ) : null}
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={buildXlsx}
            style={{ width: 390 }}
            disabled={isXlsxBuilding}
          >
            {isXlsxBuilding ? "Building xlsx for export" : "Download xlsx"}
          </Button>
          {isXlsxBuilding && (
            <img
              src={spinnerUrl}
              width="32px"
              style={{ padding: 16 }}
              alt="loading"
            />
          )}
        </div>
        <h2>Good Advertising NPOs</h2>
        <GoodAdvertisingNPOs goodAdvertisingNPOs={data?.goodAdvertisingNPOs} />
      </MainContent>
    </div>
  );
};

export default GoodAdvertisingPage;
