import React from "react";
import classNames from "classnames";
import css from "./Tag.module.css";
import { func, object, string } from "prop-types";
import { NamedLink } from "../index";

class Tag extends React.Component {
  render() {
    const {
      className,
      label,
      pageName,
      onClick,
      pageParams,
      pathParams,
    } = this.props;

    const actAsButton = typeof onClick === "function";
    const actAsLink = !actAsButton && typeof pageName === "string" && pageName;
    const classes = classNames(css.root, className);

    if (actAsButton) {
      return (
        <button className={classes} onClick={onClick} type={"button"}>
          {label}
        </button>
      );
    } else if (actAsLink) {
      return (
        <NamedLink
          className={classes}
          name={pageName}
          params={pageParams}
          pathParams={pathParams}
        >
          {label}
        </NamedLink>
      );
    } else {
      return <span className={classes}>{label}</span>;
    }
  }
}

Tag.propTypes = {
  className: string,
  label: string.isRequired,
  onClick: func,
  pageName: string,
  pageParams: object,
  pathParams: object,
};

Tag.defaultProps = {
  className: null,
  onClick: null,
  pageName: null,
  pageParams: {},
  pathParams: {},
};

export default Tag;
