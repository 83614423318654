import React, { FC } from "react";
import { useAsyncCallback } from "../shared/useAsyncCallback";
import { getNonprofits } from "../api";
import { NonprofitForm } from "./NonprofitForm";
import Table from "../shared/Table";
import { NamedLink } from "../../../components";

const TABLE_HEADERS = ["Nonprofit", "EIN", "Sharetribe", "DAF", "DAF ID"];

export const NonprofitList: FC = () => {
  const {
    data = [],
    isLoading,
    isError,
    error,
    retry,
  } = useAsyncCallback(getNonprofits);

  if (error) {
    console.log(">>> Error", error)
  }

  if (isError || !data || !Array.isArray(data) )
    return (
      <p>
        Something went wrong.{" "}{error}
        <button type="button" onClick={() => retry()}>
          refresh
        </button>
      </p>
    );

  return (
    <>
      <Table headers={TABLE_HEADERS} loading={isLoading}>
        {data.map(({ id, tax_id, listing_id, daf_key, daf_id}) => (
          <tr key={id}>
            <td>
              <NamedLink
                name="TenantManagerDetailPage"
                pathParams={{ id, tab: "nonprofits", section: "" }}
              >
                {id}
              </NamedLink>
            </td>
            <td>
              {tax_id}
            </td>

            <td>
              {listing_id}
            </td>

            <td>
              {daf_key}
            </td>

            <td>
              {daf_id}
            </td>

          </tr>
        ))}
      </Table>
      <div>
        <h2>Add new Nonprofit</h2>
        <NonprofitForm onAfterSubmit={() => retry()} />
      </div>
    </>
  );
};
