import React, { FC } from "react";
import { Button } from "../../../components";

interface iSubmitButton {
  submitting: boolean;
  submitSucceeded: boolean;
  label?: string;
}
export const SubmitButton: FC<iSubmitButton> = ({
  submitSucceeded,
  submitting,
  label = "Save",
}) => (
  <Button
    type="submit"
    style={{
      fontSize: 16,
      padding: "12px 24px",
      marginTop: 16,
      backgroundColor: submitSucceeded ? "var(--successColor)" : undefined,
      alignSelf: "flex-start",
    }}
  >
    {submitting ? "Saving..." : submitSucceeded ? "✔️ Saved" : label}
  </Button>
);
