export enum Marketplace {
  production = "givsly",
  sandbox = "givsly-sandbox",
  test = "givsly-test",
}

export enum OrderDirection {
  DESC = "DESC",
  ASC = "ASC",
  NONE = "",
}
