import { v4 as uuidv4 } from "uuid";
import { LEVEL_NOTIFICATION } from "../utils/notificationHelper";

const ACTION_ADD_NOTIFICATION =
  "givsly-dashboard/notification/ADD_NOTIFICATION";
const ACTION_CLEAR_ALL_NOTIFICATION =
  "givsly-dashboard/notification/CLEAR_ALL_NOTIFICATIONS";
const ACTION_CLEAR_NOTIFICATION =
  "givsly-dashboard/notification/CLEAR_NOTIFICATION";

const initialState = {
  notifications: [],
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
    case ACTION_ADD_NOTIFICATION:
      const notifications = [...state.notifications];
      notifications.push(payload);
      return {
        ...state,
        notifications,
      };
    case ACTION_CLEAR_ALL_NOTIFICATION:
      return {
        ...state,
        notifications: [],
      };
    case ACTION_CLEAR_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== payload.id
        ),
      };
  }
}

// Action creators
export const addNotification = (message, level = LEVEL_NOTIFICATION) => {
  return {
    type: ACTION_ADD_NOTIFICATION,
    payload: {
      id: uuidv4(),
      level,
      message,
    },
  };
};

export const clearNotification = (id) => {
  return {
    type: ACTION_CLEAR_NOTIFICATION,
    payload: {
      id,
    },
  };
};

export const clearAllNotifications = () => {
  return {
    type: ACTION_CLEAR_ALL_NOTIFICATION,
  };
};
