import React from "react";
import css from "./NonprofitField.module.css";
import { array, bool, func, object, string } from "prop-types";
import classNames from "classnames";
import { Field } from "react-final-form";
import debounce from "lodash/debounce";
import { ensureImage, ensureNonprofit, ensureUser } from "../../utils/data";

const DEBOUNCE_WAIT_TIME = 600;

const debouncedSearch = debounce(
  (onSearch, queryParams) => {
    onSearch(queryParams);
  },
  DEBOUNCE_WAIT_TIME,
  { leading: false, trailing: true }
);

class NonprofitFieldComponent extends React.Component {
  constructor(props) {
    super(props);

    this.input = React.createRef();
    this.result = React.createRef();

    this.handleAddNonprofit = this.handleAddNonprofit.bind(this);
    this.handleRemoveNonprofit = this.handleRemoveNonprofit.bind(this);
    this.handleSuggest = this.handleSuggest.bind(this);
  }
  //
  // static getDerivedStateFromProps(props, state) {
  //   return null;
  // }

  handleAddNonprofit(e) {
    const { queryResults, onAttachNonprofit, onClearQueryResults } = this.props;

    // Add the value to the local selection (if not added already)
    const result = JSON.parse(this.result.current.value) || [];
    if (result.indexOf(e.target.id) < 0) {
      result.push(e.target.id);

      // Attach the nonprofit so this user so it's data can be used
      onAttachNonprofit(
        queryResults.find((nonprofit) => nonprofit.id.uuid === e.target.id)
      );
    }

    // Reset the search and keywords
    this.input.current.value = "";
    this.props.onChange(result);
    onClearQueryResults();
  }

  handleRemoveNonprofit(e) {
    const result = JSON.parse(this.result.current.value);
    this.props.onChange(
      result.filter((nonprofit) => {
        return nonprofit !== e.target.id;
      })
    );
  }

  handleSuggest(e) {
    const { value } = e.target;
    const { onQuery } = this.props;

    if (value.length > 2) {
      debouncedSearch.cancel();
      const queryParams = {
        keywords: value,
        perPage: 5,
      };
      debouncedSearch(onQuery, queryParams);
    }
  }

  render() {
    const {
      cachedNonprofits,
      className,
      id,
      input,
      label,
      meta,
      name,
      onAttachNonprofit,
      onClearQueryResults,
      onQuery,
      onSuggest,
      queryResults,
      queryInProgress,
      ...rest
    } = this.props;
    const { valid, invalid, touched, error } = meta;
    const { value: defaultValue, ...inputWithoutValue } = input;
    const nonprofits = [...defaultValue];

    const inputProps = {
      id,
      defaultValue: JSON.stringify(defaultValue),
      ...inputWithoutValue,
      ...rest,
    };

    const hasError = (meta.error && meta.touched) || invalid;
    const hasSuccess = (touched && error) || valid;
    const classes = classNames(
      css.root,
      className,
      hasSuccess ? css.success : null,
      hasError ? css.error : null
    );

    return (
      <div className={classes}>
        {label && (
          <label htmlFor={id}>
            {label} ({nonprofits.length})
          </label>
        )}
        <div className={css.nonprofits}>
          {nonprofits.map((id) => {
            const nonprofit = ensureNonprofit(
              cachedNonprofits[id] ? cachedNonprofits[id] : {}
            );
            const title = nonprofit.attributes.title || id;
            const author = ensureUser(nonprofit.author);
            const profileImage = ensureImage(author.profileImage);
            const image =
              profileImage.attributes.variants["square-small"] || "";

            return (
              <button
                className={css.nonprofit}
                key={`${id}-${id}`}
                id={id}
                onClick={this.handleRemoveNonprofit}
              >
                <img
                  alt={`${title}'s logo`}
                  className={css.logo}
                  src={image.url}
                />
                {title}
              </button>
            );
          })}
        </div>
        <div className={css.addNonprofit}>
          <input
            id={`${id}-add`}
            onChange={this.handleSuggest}
            placeholder={"Type to search for nonprofits ..."}
            ref={this.input}
            type={"text"}
          />
        </div>
        {queryResults.length ? (
          <ul className={css.suggestions}>
            {queryResults.map((result) => {
              const nonprofit = ensureNonprofit(result);
              const title = nonprofit.attributes.title || id;
              const author = ensureUser(nonprofit.author);
              const profileImage = ensureImage(author.profileImage);
              const image =
                profileImage.attributes.variants["square-small"] || "";

              return (
                <li
                  className={css.suggestion}
                  key={`suggestion-${nonprofit.id.uuid}`}
                  id={nonprofit.id.uuid}
                  onClick={this.handleAddNonprofit}
                >
                  <img
                    alt={`${title}'s logo`}
                    className={css.logo}
                    src={image.url}
                  />
                  {title}
                </li>
              );
            })}
          </ul>
        ) : null}
        <input id={id} ref={this.result} type={"hidden"} {...inputProps} />
        {hasError && <span className={css.message}>{meta.error}</span>}
      </div>
    );
  }
}

NonprofitFieldComponent.propTypes = {
  cachedNonprofits: object,
  className: string,
  id: string.isRequired,
  label: string,
  onAttachNonprofit: func.isRequired,
  onChange: func.isRequired,
  onClearQueryResults: func.isRequired,
  onQuery: func.isRequired,
  queryResults: array,
  queryInProgress: bool,
};

NonprofitFieldComponent.defaultProps = {
  cachedNonprofits: {},
  className: null,
  label: null,
  queryResults: [],
  queryInProgress: false,
};

class NonprofitField extends React.Component {
  render() {
    return <Field component={NonprofitFieldComponent} {...this.props} />;
  }
}

export default NonprofitField;
