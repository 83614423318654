import React from "react";
import { Form as FinalForm } from "react-final-form";
import css from "./InvitationForm.module.css";
import Dropzone from "react-dropzone";
import csv from "csv";
import * as jwt from "jsonwebtoken";
import { environments, invitation } from "../../config";
import { array } from "prop-types";
import { propTypeToString } from "../../utils/data";
import { Button } from "../../components";

class InvitationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      csvData: "",
      data: {},
      hasError: false,
      isLoaded: false,
      loadInProgress: false,
      selectedEnvironment: environments.find(
        (environment) => environment.key === "production"
      ).url,
      uploadProgress: 0,
      uploadInProgress: false,
    };

    this.getMaxUrlLength = this.getMaxUrlLength.bind(this);
    this.handleEnvironmentChange = this.handleEnvironmentChange.bind(this);
    this.handleOnDownload = this.handleOnDownload.bind(this);
    this.handleOnUpload = this.handleOnUpload.bind(this);
  }

  createSignUpLink = (data) => {
    const token = jwt.sign(data, process.env.REACT_APP_GIVSLY_SECRET);
    return `${this.state.selectedEnvironment}signup?t=${token}`;
  };

  getMaxUrlLength = () => {
    const { data } = this.state;
    if (!data.length) {
      return 0;
    }
    const sorted = data.sort((a, b) => b.url.length - a.url.length);
    return sorted[0].url.length;
  };

  mapUrlsToData = (data) => {
    return data.map((row) => {
      delete row.url;
      row.url = this.createSignUpLink(row);
      return row;
    });
  };

  handleOnDownload = () => {
    csv.stringify(
      this.state.data,
      {
        cast: this.castToString,
        delimiter: ",",
        header: true,
        quoted: true,
        quoted_empty: true,
      },
      (error, data) => {
        const element = document.createElement("a");
        element.setAttribute("href", `data:text/plain;charset=utf-8,${data}`);
        element.setAttribute("download", "invitations.csv");
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    );
  };

  /**
   * @todo cleanup
   * @param acceptedFiles
   */
  handleOnUpload = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => {
        console.log("Aborted");
      };
      reader.onerror = () => {
        console.log("Upload failed");
      };

      reader.onloadstart = () => {
        this.setState({
          loadInProgress: true,
          uploadInProgress: true,
          uploadProgress: 0,
        });
      };
      reader.onload = () => {
        csv.parse(
          reader.result,
          { columns: true, cast: this.castToType },
          (error, data) => {
            this.setState({
              data: this.mapUrlsToData(data),
            });
          }
        );
      };
      reader.onprogress = (e) => {
        this.setState({
          uploadProgress: Math.round((e.loaded / e.total) * 100),
          uploadInProgress: e.loaded !== e.total,
        });
      };
      reader.onloadend = () => {
        setTimeout(() => {
          this.setState({
            loadInProgress: false,
            isLoaded: true,
          });
        }, 100);
      };

      reader.readAsBinaryString(file);
    });
  };

  castToType = (value, context) => {
    const fieldType = invitation.fieldConfiguration[context.column];
    if (fieldType && fieldType === array) {
      return value.split(",").map((subValue) => subValue.trim());
    }
    return value;
  };

  castToString = (value, context) => {
    console.log("The value", value);
    if (Array.isArray(JSON.parse(value))) {
      console.log("IS array!");
      return value.join(",");
    }
    return value;
  };

  handleEnvironmentChange = (e) => {
    this.setState({
      selectedEnvironment: e.target.value,
    });

    setTimeout(() => {
      this.setState({ data: this.mapUrlsToData(this.state.data) });
    }, 100);
  };

  render() {
    const {
      data,
      hasError,
      isLoaded,
      loadInProgress,
      selectedEnvironment,
      uploadProgress,
      uploadInProgress,
    } = this.state;

    const maxUrlLength = this.getMaxUrlLength();

    return (
      <div className={css.root}>
        <h1>Invitations</h1>
        <p>
          This component allows you to generate invitation links which may be
          sent out by e-mail to assist users in the sign-up process. This
          assisted sign-up process allows you to pre-fill some of the details
          that are needed for new users.
        </p>
        <p>
          You can either copy/paste CSV content into the form below, or upload a
          CSV file directly. Please stick to the format for the CSV files as is
          defined below the form.
        </p>
        <h2>Additional information</h2>
        <ul>
          <li>
            <a href={"https://luckyforks.atlassian.net/wiki/home"}>
              Read more about the CSV format on Confluence
            </a>
          </li>
          <li>
            <a href={"https://luckyforks.atlassian.net/wiki/home"}>
              Read more about allowed fields on Confluence
            </a>
          </li>
        </ul>
        <FinalForm
          {...this.props}
          onSubmit={() => {}}
          render={(fieldRenderProps) => {
            return (
              <form>
                {!isLoaded && !loadInProgress && (
                  <>
                    <h2>Upload</h2>
                    <Dropzone onDrop={this.handleOnUpload}>
                      {({ getRootProps, getInputProps }) => (
                        <section className={css.dropzone}>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p className={css.dropzoneText}>
                              Drag and drop your CSV file into this box
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </>
                )}
                {loadInProgress && (
                  <>
                    <h2>Upload</h2>
                    <div className={css.loading}>
                      <svg
                        className={css.loader}
                        xmlns="http://www.w3.org/2000/svg"
                        width="200px"
                        height="200px"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                      >
                        <clipPath id="cp">
                          <path d="M0 -40.5 A40.5 40.5 0 0 1 0 40.5 A40.5 40.5 0 0 1 0 -40.5 M23.5 -1L23.5 1L30.5 1L30.5 -1Z" />
                        </clipPath>
                        <g transform="translate(50,50)">
                          <circle
                            clipPath="url(#cp)"
                            cx="0"
                            cy="0"
                            fill="none"
                            r="26"
                            stroke="#E87461"
                            strokeWidth="5"
                            strokeDasharray="40.840704496667314 0 0 0 0 163.36281798666926"
                            transform="rotate(0)"
                          >
                            <animate
                              attributeName="stroke-dasharray"
                              dur="2.3255813953488373s"
                              repeatCount="indefinite"
                              begin="-0.23255813953488375s"
                              keyTimes="0;0.2;0.4;0.6;0.8;1"
                              values="
  0 0 0 0 0 163.36281798666926;
  0 0 0 0 0 163.36281798666926;
  0 0 81.68140899333463 0 0 163.36281798666926;
  0 0 163.36281798666926 0 0 163.36281798666926;
  0 0 81.68140899333463 0 0 163.36281798666926;
  0 0 0 0 0 163.36281798666926
  "
                            />
                            <animateTransform
                              attributeName="transform"
                              type="rotate"
                              dur="2.3255813953488373s"
                              repeatCount="indefinite"
                              begin="-0.23255813953488375s"
                              values="0;0;0;0;180;360"
                            />
                          </circle>

                          <circle
                            cx="0"
                            cy="0"
                            fill="none"
                            r="32"
                            stroke="#fac51d"
                            strokeWidth="5"
                            strokeDasharray="100.53096491487338 0 0 201.06192982974676"
                            transform="rotate(334.204)"
                          >
                            <animate
                              attributeName="stroke-dasharray"
                              dur="2.3255813953488373s"
                              repeatCount="indefinite"
                              begin="0s"
                              values="
  0 0 0 0 0 201.06192982974676;
  0 0 100.53096491487338 0 0 201.06192982974676;
  0 0 100.53096491487338 0 0 201.06192982974676;
  0 0 100.53096491487338 0 0 201.06192982974676;
  0 0 100.53096491487338 0 0 201.06192982974676;
  0 0 0 0 0 201.06192982974676
  "
                            />
                            <animateTransform
                              attributeName="transform"
                              type="rotate"
                              dur="2.3255813953488373s"
                              repeatCount="indefinite"
                              begin="0s"
                              values="0;0;0;180;180;360"
                            />
                          </circle>

                          <circle
                            cx="0"
                            cy="0"
                            fill="none"
                            r="38"
                            stroke="#889696"
                            strokeWidth="5"
                            strokeDasharray="119.38052083641213 0 0 238.76104167282426"
                            transform="rotate(265.606)"
                          >
                            <animate
                              attributeName="stroke-dasharray"
                              dur="2.3255813953488373s"
                              repeatCount="indefinite"
                              begin="0s"
                              keyTimes="0;0.06;0.1;0.3;0.45;0.5;0.7;0.90;1"
                              values="
  0 0 89.5353906273091 0 0 238.76104167282426;
  0 0 89.5353906273091 0 0 238.76104167282426;
  0 0 119.38052083641213 0 0 238.76104167282426;
  0 0 119.38052083641213 0 0 238.76104167282426;
  0 0 29.845130209103033 0 0 238.76104167282426;
  0 0 29.845130209103033 0 0 238.76104167282426;
  0 0 119.38052083641213 0 0 238.76104167282426;
  0 0 119.38052083641213 0 0 238.76104167282426;
  0 0 89.5353906273091 0 0 238.76104167282426
  "
                            />
                            <animateTransform
                              attributeName="transform"
                              type="rotate"
                              dur="2.3255813953488373s"
                              repeatCount="indefinite"
                              begin="0s"
                              keyTimes="0;0.06;0.1;0.3;0.5;0.6;0.8;0.90;1"
                              values="-60;0;0;0;180;180;180;180;300"
                            />
                          </circle>
                        </g>
                      </svg>
                      {uploadInProgress
                        ? `Uploading ${uploadProgress}%`
                        : "Processing ..."}
                    </div>
                  </>
                )}
                {!hasError && isLoaded && !loadInProgress && (
                  <>
                    <h2>Configuration</h2>
                    <div className={css.fieldWrapper}>
                      <label htmlFor={"environment"}>Environment</label>
                      <select
                        id={"environment"}
                        onChange={this.handleEnvironmentChange}
                        defaultValue={selectedEnvironment}
                      >
                        {environments.map((environment) => {
                          return (
                            <option
                              key={environment.key}
                              value={environment.url}
                            >
                              {environment.label} ({environment.url})
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <h2>Details</h2>
                    <div className={css.details}>
                      <table className={css.dataTable}>
                        <tbody>
                          {Object.keys(data[0]).map((column) => {
                            return (
                              column !== "url" && (
                                <tr key={`column-${column}`}>
                                  <td className={css.dataKeyColumn}>
                                    {column}
                                  </td>
                                  <td className={css.dataColumn}>
                                    {propTypeToString(
                                      invitation.fieldConfiguration[column]
                                    )}
                                  </td>
                                </tr>
                              )
                            );
                          })}
                        </tbody>
                      </table>
                      <div className={css.statistics}>
                        <dl>
                          <dt>Rows</dt>
                          <dd>{data.length}</dd>
                          <dt>Columns</dt>
                          <dd>{Object.keys(data[0]).length}</dd>
                          <dt>Max URL length</dt>
                          <dd>
                            {maxUrlLength}{" "}
                            {maxUrlLength < 2000 ? (
                              <svg
                                className={css.success}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 1333.33 1333.33"
                                shapeRendering="geometricPrecision"
                                textRendering="geometricPrecision"
                                imageRendering="optimizeQuality"
                                fillRule="evenodd"
                                clipRule="evenodd"
                              >
                                <path d="M666.67 0c368.19 0 666.67 298.48 666.67 666.67 0 368.19-298.48 666.67-666.67 666.67C298.48 1333.34 0 1034.86 0 666.67 0 298.48 298.48 0 666.67 0zM371.73 684.41c8.94-51.87 68.14-80.73 114.85-52.63 4.24 2.53 8.28 5.54 12.04 8.98l.37.35c20.96 20.09 44.44 41 67.72 61.72l19.98 17.93 236.99-248.58c14.15-14.81 24.5-24.41 45.74-29.17 72.72-16.04 123.83 72.83 72.3 127.15L646.33 880.1c-27.82 29.68-77.55 32.39-107.44 4.05-17.15-15.91-35.78-32.1-54.62-48.47-32.63-28.35-65.94-57.29-93.07-85.92-16.3-16.3-23.32-42.94-19.47-65.33z" />
                              </svg>
                            ) : (
                              <svg
                                className={css.warn}
                                xmlns="http://www.w3.org/2000/svg"
                                width="64"
                                height="64"
                                viewBox="0 0 640 640"
                                shapeRendering="geometricPrecision"
                                textRendering="geometricPrecision"
                                imageRendering="optimizeQuality"
                                fillRule="evenodd"
                                clipRule="evenodd"
                              >
                                <path d="M340.882 76.855l289.563 454.813c8.717 13.701 16.24 41.753 0 41.753H9.567c-16.24 0-8.728-28.052 0-41.753L299.118 76.855c8.728-13.7 33.036-13.689 41.764 0zM294.677 452.77h50.422v44.587h-50.422V452.77zm50.398-30.886h-50.386c-5.02-61.229-15.532-100.088-15.532-161.222 0-22.56 18.284-40.843 40.843-40.843 22.56 0 40.843 18.284 40.843 40.843 0 61.111-10.654 100.041-15.768 161.222z" />
                              </svg>
                            )}
                          </dd>
                        </dl>
                      </div>
                    </div>
                    <div className={css.controls}>
                      <Button onClick={this.handleOnDownload}>Download</Button>
                    </div>
                    <div>{this.state.csvData}</div>
                  </>
                )}
              </form>
            );
          }}
        />
      </div>
    );
  }
}

InvitationForm.propTypes = {};

InvitationForm.defaultProps = {};

export default InvitationForm;
