import React from "react";
import css from "./KeywordFilter.module.css";
import { func, string } from "prop-types";

class KeywordFilter extends React.Component {
  render() {
    const { keywords, onChange } = this.props;
    return (
      <div className={css.root}>
        <input
          className={css.input}
          defaultValue={keywords}
          onChange={onChange}
          placeholder={"Enter keywords ..."}
        />
      </div>
    );
  }
}

KeywordFilter.propTypes = {
  keywords: string,
  onChange: func.isRequired,
};

KeywordFilter.defaultProps = {
  keywords: "",
};

export default KeywordFilter;
