import React from "react";
import classNames from "classnames";
import css from "./TransactionStatus.module.css";

export enum TransactionState {
  accepted = "accepted",
  cancelled = "cancelled",
  closed = "closed",
  completed = "completed",
  declined = "declined",
  delivered = "delivered",
  expired = "expired",
  failed = "failed",
  initiated = "initiated",
  unknown = "unknown",
}

type TransactionStatusProps = {
  status?: TransactionState | undefined | null;
};

export const statusColor = (status: TransactionState | string) => {
  switch (status) {
    case TransactionState.accepted:
      return "dodgerblue";
    default:
    case TransactionState.unknown:
    case TransactionState.cancelled:
      return "red";
    case TransactionState.closed:
    case TransactionState.completed:
    case TransactionState.delivered:
      return "green";
    case TransactionState.declined:
      return "darkgrey";
    case TransactionState.expired:
      return "dimgrey";
    case TransactionState.failed:
      return "#ff3300";
    case TransactionState.initiated:
      return "orange";
  }
};

/**
 * Displays a transaction status component. This is a simple label that displays in which simplified state the
 * transaction is currently.
 *
 * @param props
 * @constructor
 */
const TransactionStatus = (props: TransactionStatusProps) => {
  const { status } = props;
  return (
    <React.Fragment>
      {status ? (
        <span className={classNames(css.root, css[status || "unknown"])}>
          {status}
        </span>
      ) : (
        <span className={classNames(css.root, css.failed)}>failed</span>
      )}
    </React.Fragment>
  );
};

export default TransactionStatus;
