import React from "react";
import classNames from "classnames";
import css from "./Pagination.module.css";
import { NamedLink } from "../index";

type PaginationProps = {
  currentPage: number;
  pageName: string;
  params?: object;
  pathParams?: object;
  perPage: number;
  totalItems: number;
  totalPages: number;
};

const getParamsForPage = (params: object | undefined, page: number) => {
  return {
    ...(typeof params === "object" ? params : {}),
    page,
  };
};

const Pagination = (props: PaginationProps) => {
  const { currentPage, pathParams, pageName, totalPages, params } = props;

  // The page numbers that should be rendered. This either starts from the 1st page if the current page is less than 6,
  // or 4 numbers up from the current page.
  const renderPages: Array<number> = Array.from(
    { length: Math.min(10, totalPages) },
    (_, i) =>
      currentPage + 10 > totalPages
        ? i + Math.max(1, totalPages - 9)
        : i + Math.max(1, currentPage - 4)
  );

  const hasFirst = !renderPages.includes(2);
  const hasPrevious = currentPage > 1;
  const hasNext = currentPage < totalPages;
  const hasLast = hasNext && !renderPages.includes(totalPages);

  return totalPages > 1 ? (
    <ol className={css.root}>
      {hasPrevious && (
        <li className={css.previous}>
          <NamedLink
            key={`page-previous`}
            name={pageName}
            params={getParamsForPage(params, currentPage - 1)}
            pathParams={pathParams}
          >
            &lt;
          </NamedLink>
        </li>
      )}
      {hasFirst && (
        <li className={css.first}>
          <NamedLink
            key={`page-first`}
            name={pageName}
            params={getParamsForPage(params, 1)}
            pathParams={pathParams}
          >
            1
          </NamedLink>
          &hellip;
        </li>
      )}
      {renderPages.map((p) => (
        <li
          className={classNames(
            css.page,
            p === currentPage ? css.currentPage : null
          )}
          key={`page-${p}`}
        >
          <NamedLink
            key={`page-${p}`}
            name={pageName}
            params={getParamsForPage(params, p)}
            pathParams={pathParams}
          >
            {p}
          </NamedLink>
        </li>
      ))}
      {hasLast && (
        <li className={css.last}>
          &hellip;
          <NamedLink
            key={`page-next`}
            name={pageName}
            params={getParamsForPage(params, totalPages)}
            pathParams={pathParams}
          >
            {totalPages}
          </NamedLink>
        </li>
      )}
      {hasNext && (
        <li className={css.next}>
          <NamedLink
            key={`page-next`}
            name={pageName}
            params={getParamsForPage(params, currentPage + 1)}
            pathParams={pathParams}
          >
            &gt;
          </NamedLink>
        </li>
      )}
    </ol>
  ) : null;
};

export default Pagination;
