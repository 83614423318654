import React from "react";
import css from "./UserInfo.module.css";
import { connect } from "react-redux";
import { compose } from "redux";
import { logout } from "../../ducks/auth.duck";

class UserInfo extends React.Component {
  render() {
    const { authInfo, handleLogout } = this.props;
    return (
      <div className={css.root}>
        <span className={css.userName}>
          Logged in as {authInfo.firstName} {authInfo.lastName}
        </span>
        <button className={css.logout} onClick={handleLogout} type={"button"}>
          Logout
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { authInfo } = state.auth;
  return {
    authInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleLogout: () => dispatch(logout()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(UserInfo);
