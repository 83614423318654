import { useAuthInfo } from "../shared/AuthInfoProvider";
import { AD_SERVERS, AD_TYPES, iAdType, iContact, iSubscription, SubscriptionState } from "@givsly/aws-tenant-manager";
import { FormApi } from "final-form";
import React, { FC, useCallback, useState } from "react";
import { AnyObject, Form as FinalForm } from "react-final-form";
import { Button, TextField } from "../../../components";
import arrayMutators from "final-form-arrays";
import ReactSelect from "react-select";

import css from "../TenantManagerPage.module.css";
import { FieldArray } from "react-final-form-arrays";
import { createTenant } from "../api";
import { TENANT_CAPABILITIES, AD_ENTITY_TYPE } from "@givsly/aws-tenant-manager/lib/enum";

interface iTenantForm {
  onAfterSubmit?: () => void;
}

interface AdTypeConfig {
  ad_type: string; 
  config: iAdType;
}

const AMLiz: iContact = {
  first_name: "Liz", last_name: "Rodrigez", name: "Liz Rodrigez", email: "liz@givsly.com"
}

const AMLotta: iContact = {
  first_name: "Lotta", last_name: "Lautsuo", name: "Lotta Lautsuo", email: "lotta@givsly.com"
}

const nullConfig: AdTypeConfig = { ad_type: '', config: {approved: true, servers: [], fee_cents: 0.0, min_donation_cents: 0.0} }

const subscriptionGA: iSubscription = {
  product_code: "GA",
  level: "GOLD", 
  seats: 10,
  state: SubscriptionState.Active,
  expires_on: 0,
  account_manager: AMLiz
}

const subscriptionGM: iSubscription = {
  product_code: "GM",
  level: "GOLD", 
  seats: 10,
  state: SubscriptionState.Active,
  expires_on: 0,
  account_manager: AMLotta 
}

const initialValues = {
  admin: {
    ad_types_v2_helper: [nullConfig],
    capabilities_helper: [],
    subscriptions: {
      sub_ga: subscriptionGA,
      sub_gm: subscriptionGM
    }
  },
};

function toTitleCase(str: string): string {
  return str
    .split('_') // Split by underscore
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(' '); // Join words back with space
}

const TenantForm: FC<iTenantForm> = ({ onAfterSubmit }) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const authInfo = useAuthInfo();
  const onSubmit = useCallback(
    async (values: AnyObject, form: FormApi<AnyObject, Partial<AnyObject>>) => {
      setSubmitting(true);
      setError(undefined);
      try {
        const {
          admin: { ad_types_v2_helper, capabilities_helper, ...rest_admin },
          ...rest
        } = values;

        const tenant =
          typeof values.tenant === "string"
            ? values.tenant.trim().toLowerCase()
            : undefined;

        if (!tenant) {
          throw new Error("Tenant is required!");
        }

        const payload = {
          ...rest,
          admin: rest_admin,
          tenant,
          creator: {
            name: authInfo ? `${authInfo.firstName} ${authInfo.lastName}` : "",
            email: authInfo?.email || "",
            first_name: authInfo?.firstName || "",
            last_name: authInfo?.lastName || "",
          }
        };

        // Initialize ad_types_v2 with a proper structure
        let ad_types_v2: Record<string, iAdType> = {};

        // Convert the helper from array of configs into key/value of iAdType
        ad_types_v2_helper.forEach((item: {ad_type: string, config: iAdType}) => {
          item.config.approved = true
          ad_types_v2[item.ad_type] = item.config;
        });

        const capabilities_default: Partial<
          Record<TENANT_CAPABILITIES, boolean>
        > = {};
        const capabilities = capabilities_helper.reduce(
          (
            acc: Partial<Record<TENANT_CAPABILITIES, boolean>>,
            curr: string
          ) => {
            return { ...acc, [curr]: true };
          },
          capabilities_default
        );

        payload.admin["capabilities"] = capabilities;

        if (
          Object.keys(ad_types_v2).length > 0 &&
          Object.keys(ad_types_v2)[0] !== ''
        ) {
          payload.admin["ad_types_v2"] = ad_types_v2;
        }

        console.log(`CREATE TENANT: ${JSON.stringify(payload)}`)

        const result = await createTenant(payload);
        if ("error" in result.json) {
          setError(result.json.error);
        } else {
          
          setTimeout(() => {
            form.reset();
          }, 1000);

        }
      } catch (err) {
        console.warn(err);
        if (err instanceof Error) {
          setError(err.message);
        }
      }
      setSubmitting(false);
      if (typeof onAfterSubmit === "function") {
        onAfterSubmit();
      }
    },
    [onAfterSubmit, authInfo]
  );

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      render={(formRenderProps) => {
        const { handleSubmit, form, values } = formRenderProps;

        return (
          <>
            {error && <div className={css.error}>{error}</div>}
            <form onSubmit={handleSubmit}>
              

            <fieldset name="tenant" className={css.fieldset}>
            <legend>Tenant Info</legend>

              <TextField
                  required
                  name="tenant"
                  type="text"
                  id="tenant"
                  label="Tenant domain"
                  placeholder="example.com"
                />

                <TextField
                  required
                  name="profile.name"
                  type="text"
                  id="profilename"
                  label="Tenant name"
                  placeholder="Example inc."
                />

                <fieldset name="profile" className={css.fieldset}>
                  <legend>Advertising Admin</legend>
                  <div className={[css.row, css.grow].join(' ')}>
                    <TextField
                        name={`profile.admin.first_name`}
                        type="text"
                        id="first_name"
                        label="Admin First Name"
                      />

                      <TextField
                        name={`profile.admin.last_name`}
                        type="text"
                        id="last_name"
                        label="Admin Last Name"
                      />
                    </div>

                    <TextField
                      name={`profile.admin.email`}
                      type="email"
                      id="email"
                      label="Admin Email"
                    />
                </fieldset>

                <fieldset name="profile" className={css.fieldset}>
                  <legend>Marketing Admin</legend>
                  <div className={[css.row, css.grow].join(' ')}>
                    <TextField
                        name={`profile.admin_gm.first_name`}
                        type="text"
                        id="first_name"
                        label="Admin First Name"
                      />

                      <TextField
                        name={`profile.admin_gm.last_name`}
                        type="text"
                        id="last_name"
                        label="Admin Last Name"
                      />
                    </div>

                    <TextField
                      name={`profile.admin_gm.email`}
                      type="email"
                      id="email"
                      label="Admin Email"
                    />
                </fieldset>

           

                <TextField
                  name="profile.logo"
                  type="url"
                  id="profilelogo"
                  label="Logo URL"
                  placeholder="https://example.com/logo.jpg"
                />
              </fieldset>


              <fieldset name="admin" className={css.fieldset}>
                <legend>Admin</legend>

                <div style={{marginBottom: 20}}>
                  <label>Tenant type</label>
                  <ReactSelect
                    name={`admin.ad_entity_type`}
                    options={Object.entries(AD_ENTITY_TYPE)
                      .filter(([enumName]) => isNaN(Number(enumName))) 
                      .map(([enumName, enumValue]) => ({
                        label: toTitleCase(enumName),
                        value: enumValue,
                      })
                    )}
                  />
                </div>

                <label>Tenant capabilities</label>
                <ReactSelect
                  isMulti
                  onChange={(selectedOptions) =>
                    selectedOptions !== null
                      ? form.change(
                          "admin.capabilities_helper",
                          selectedOptions.map((option) => option.value)
                        )
                      : form.change("admin.capabilities_helper", [])
                  }
                  options={Object.entries(TENANT_CAPABILITIES)
                    .map(([enumLabel, enumValue]) => ({
                      label: toTitleCase(enumLabel),
                      value: enumValue,
                  }))}
                ></ReactSelect>

                {values.admin.capabilities_helper.indexOf(
                  TENANT_CAPABILITIES.ADS
                ) > -1 && (
                  <>
                    <label>Ad Type configurations</label>
              
                      <FieldArray name="admin.ad_types_v2_helper">
                        {({ fields }) => (
                          <div >
                            {fields.map((name, index) => {

                              const usedAdTypes = values.admin.ad_types_v2_helper.map((item: AdTypeConfig) => item.ad_type);

                              return (
                                <fieldset name="admin" className={css.fieldset} key={`ad-type-config-${index}`}>
                                  <legend>{`Config`} </legend>
                             
                                  <label>Ad Type</label>
                                  <ReactSelect
                                    isClearable
                                    name={`${name}.ad_type`}
                                    options={Object.entries(AD_TYPES)
                                      .filter(([_, enumValue]) => !usedAdTypes.includes(enumValue))
                                      .map(([enumLabel, enumValue]) => ({
                                        label: toTitleCase(enumLabel),
                                        value: enumValue,
                                      })
                                    )}
                                    onChange={(selection) => {
                                      if (selection) {
                                        form.change(`${name}.ad_type`, selection.value)
                                      } else {
                                        form.change(`${name}.ad_type`, undefined)
                                      }
                                    }}
                                    
                                  />

                                  <label>Supported servers</label>
                                  <ReactSelect
                                    name={`${name}.config.servers`}
                                    isMulti
                                    options={Object.entries(AD_SERVERS)
                                      .filter((value) => !!value)
                                      .map(([enumLabel, enumValue]) => ({
                                        label: toTitleCase(enumLabel),
                                        value: enumValue,
                                      }))
                                    }
                                    onChange={(selectedServers) => {
                                      if (selectedServers) {
                                        form.change(`${name}.config.servers`, selectedServers.map(
                                          (server) => server.value
                                        ))
                                      }
                                    }}
                                  />

                                  <label>Pricing</label>
                                  <div className={css.row}>

                                    <TextField
                                      name={`${name}.config.min_donation_cents`}
                                      type="number"
                                      id="min_donation_cents"
                                      label="Min Donation (cents)"
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const numberValue = parseFloat(e.target.value);
                                        const valueToUpdate = isNaN(numberValue) ? 0.0 : numberValue;
                                        if (valueToUpdate > 0.0) {
                                          form.change(`${name}.config.min_donation_cents`, valueToUpdate);
                                        }
                                      }}
                                    />

                                    <TextField
                                      name={`${name}.config.fee_cents`}
                                      type="number"
                                      id="fee_cents"
                                      label="Fee (cents)"
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const numberValue = parseFloat(e.target.value);
                                        const valueToUpdate = isNaN(numberValue) ? 0.0 : numberValue;
                                        if (valueToUpdate> 0) {
                                          form.change(`${name}.config.fee_cents`, valueToUpdate);
                                        }
                                      }}
                                    />
                                  </div>

                                </fieldset>
                              );
                            })}

                            <button
                              className={css["secondary-button"]}
                              type="button"
                              onClick={() =>
                                fields.push(nullConfig)
                              }
                            >
                              + Add config
                            </button>
                          </div>
                        )}
                      </FieldArray>
           
                  </>
                )}
              </fieldset>


              <Button type="submit" disabled={submitting}>
                {submitting ? "Saving..." : "Create tenant"}
              </Button>
            </form>
          </>
        );
      }}
    />
  );
};

TenantForm.defaultProps = {
  onAfterSubmit: undefined,
};

export default TenantForm;
