import React, { FC, PropsWithChildren, createContext } from "react";
import { iAuthInfo } from "./utils";

export const AuthInfoContext = createContext<iAuthInfo | undefined>(undefined);

export const AuthInfoProvider: FC<PropsWithChildren<{ value?: iAuthInfo }>> = ({
  children,
  value,
}) => (
  <AuthInfoContext.Provider value={value}>{children}</AuthInfoContext.Provider>
);

export const useAuthInfo = () => {
  const authInfo = React.useContext(AuthInfoContext);
  return authInfo;
};
