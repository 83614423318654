import React from "react";

const MostAcceptedNPOs = ({ mostAcceptedNPOs }: { mostAcceptedNPOs: any }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Nonprofit name</th>
          <th>Acceptances</th>
        </tr>
      </thead>
      <tbody>
        {mostAcceptedNPOs &&
          mostAcceptedNPOs.map((row: any, i: number) => {
            return (
              <tr key={i}>
                <td>{ row.name !== null ? row.name : "Unknown"}</td>
                <td>{ row.count }</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default MostAcceptedNPOs;
