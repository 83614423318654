import axios from "axios";
import { getApiHeaders } from "../utils/api";
import { STATUS_ERROR, STATUS_OK } from "../utils/statusHelper";

const API_PATH = "users";

// Actions
const ACTION_CREATE_USER_REQUEST = "givsly-dashboard/user/CREATE_USER_REQUEST";
const ACTION_CREATE_USER_SUCCESS = "givsly-dashboard/user/CREATE_USER_SUCCESS";
const ACTION_CREATE_USER_ERROR = "givsly-dashboard/user/CREATE_USER_ERROR";
const ACTION_DELETE_USER_REQUEST = "givsly-dashboard/user/DELETE_USER_REQUEST";
const ACTION_DELETE_USER_SUCCESS = "givsly-dashboard/user/DELETE_USER_SUCCESS";
const ACTION_DELETE_USER_ERROR = "givsly-dashboard/user/DELETE_USER_ERROR";
const ACTION_FETCH_SINGLE_USER_REQUEST =
  "givsly-dashboard/user/FETCH_SINGLE_USER_REQUEST";
const ACTION_FETCH_SINGLE_USER_SUCCESS =
  "givsly-dashboard/user/FETCH_SINGLE_USER_SUCCESS";
const ACTION_FETCH_SINGLE_USER_ERROR =
  "givsly-dashboard/user/FETCH_SINGLE_USER_ERROR";
const ACTION_FETCH_USERS_REQUEST = "givsly-dashboard/user/FETCH_USERS_REQUEST";
const ACTION_FETCH_USERS_SUCCESS = "givsly-dashboard/user/FETCH_USERS_SUCCESS";
const ACTION_FETCH_USERS_ERROR = "givsly-dashboard/user/FETCH_USERS_ERROR";
const ACTION_UPDATE_USER_REQUEST = "givsly-dashboard/user/UPDATE_USER_REQUEST";
const ACTION_UPDATE_USER_SUCCESS = "givsly-dashboard/user/UPDATE_USER_SUCCESS";
const ACTION_UPDATE_USER_ERROR = "givsly-dashboard/user/UPDATE_USER_ERROR";

// Initial state
const initialState = {
  createUserError: false,
  createUserInProgress: false,
  deleteUserInProgress: false,
  deleteUserFail: false,
  fetchSingleUserError: false,
  fetchSingleUserInProgress: false,
  fetchUsersError: false,
  fetchUsersInProgress: false,
  updateUserError: false,
  updateUserInProgress: false,
  user: {},
  users: [],
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
    case ACTION_CREATE_USER_REQUEST:
      return {
        ...state,
        createUserError: false,
        createUserInProgress: true,
        user: {},
      };
    case ACTION_CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserInProgress: false,
        user: payload.data.user,
      };
    case ACTION_CREATE_USER_ERROR:
      return {
        ...state,
        createUserInProgress: false,
        createUserError: true,
      };
    case ACTION_DELETE_USER_REQUEST:
      return {
        ...state,
        deleteUserInProgress: true,
        deleteUserFail: false,
      };
    case ACTION_DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserInProgress: false,
      };
    case ACTION_DELETE_USER_ERROR:
      return {
        ...state,
        deleteUserInProgress: false,
        deleteUserFail: true,
      };
    case ACTION_FETCH_SINGLE_USER_REQUEST:
      return {
        ...state,
        fetchSingleUserInProgress: true,
        user: {},
      };
    case ACTION_FETCH_SINGLE_USER_SUCCESS:
      return {
        ...state,
        fetchSingleUserInProgress: false,
        user: payload.data.user,
      };
    case ACTION_FETCH_SINGLE_USER_ERROR:
      return {
        ...state,
        fetchSingleUserError: true,
        fetchSingleUserInProgress: false,
      };
    case ACTION_FETCH_USERS_REQUEST:
      return {
        ...state,
        fetchUsersInProgress: true,
        users: [],
      };
    case ACTION_FETCH_USERS_SUCCESS:
      return {
        ...state,
        fetchUsersInProgress: false,
        users: payload.data.users,
      };
    case ACTION_FETCH_USERS_ERROR:
      return {
        ...state,
        fetchUsersInProgress: false,
        fetchUsersError: true,
        users: [],
      };
    case ACTION_UPDATE_USER_REQUEST:
      return {
        ...state,
        updateUserError: false,
        updateUserInProgress: true,
      };
    case ACTION_UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserInProgress: false,
      };
    case ACTION_UPDATE_USER_ERROR:
      return {
        ...state,
        updateUserError: true,
        updateUserInProgress: false,
      };
  }
}

// Action creators
export const createUserRequest = () => {
  return {
    type: ACTION_CREATE_USER_REQUEST,
  };
};

export const createUserSuccess = (data) => {
  return {
    type: ACTION_CREATE_USER_SUCCESS,
    payload: data,
    status: STATUS_OK,
  };
};

export const createUserError = (error) => {
  return {
    type: ACTION_CREATE_USER_ERROR,
    payload: error,
    status: STATUS_ERROR,
  };
};

export const deleteUserRequest = () => {
  return {
    type: ACTION_DELETE_USER_REQUEST,
  };
};

export const deleteUserSuccess = () => {
  return {
    type: ACTION_DELETE_USER_SUCCESS,
    status: STATUS_OK,
  };
};

export const deleteUserError = (error) => {
  return {
    type: ACTION_DELETE_USER_ERROR,
    payload: error,
    status: STATUS_ERROR,
  };
};

export const fetchSingleUserRequest = () => {
  return {
    type: ACTION_FETCH_SINGLE_USER_REQUEST,
  };
};

export const fetchSingleUserSuccess = (data) => {
  return {
    type: ACTION_FETCH_SINGLE_USER_SUCCESS,
    payload: data,
    status: STATUS_OK,
  };
};

export const fetchSingleUserError = (error) => {
  return {
    type: ACTION_FETCH_SINGLE_USER_ERROR,
    payload: error,
    status: STATUS_ERROR,
  };
};

export const fetchUsersRequest = () => {
  return {
    type: ACTION_FETCH_USERS_REQUEST,
  };
};

export const fetchUsersSuccess = (data) => {
  return {
    type: ACTION_FETCH_USERS_SUCCESS,
    payload: data,
    status: STATUS_OK,
  };
};

export const fetchUsersError = (error) => {
  return {
    type: ACTION_FETCH_USERS_ERROR,
    payload: error,
    status: STATUS_ERROR,
  };
};

export const updateUserRequest = () => {
  return {
    type: ACTION_UPDATE_USER_REQUEST,
  };
};

export const updateUserSuccess = () => {
  return {
    type: ACTION_UPDATE_USER_SUCCESS,
    status: STATUS_OK,
  };
};

export const updateUserError = (error) => {
  return {
    type: ACTION_UPDATE_USER_ERROR,
    payload: error,
    status: STATUS_ERROR,
  };
};

// Thunks
export const createUser = (data) => (dispatch) => {
  dispatch(createUserRequest());
  return axios
    .post(`${process.env.REACT_APP_API_END_POINT}${API_PATH}`, data, {
      timeout: 5000,
      headers: getApiHeaders(),
    })
    .then((response) => {
      dispatch(createUserSuccess(response.data));
      return response.data;
    })
    .catch((e) => {
      return dispatch(createUserError(e));
    });
};

export const deleteUser = (id) => (dispatch) => {
  dispatch(deleteUserRequest());
  return axios
    .delete(`${process.env.REACT_APP_API_END_POINT}${API_PATH}/${id}`, {
      timeout: 5000,
      headers: getApiHeaders(),
    })
    .then(() => {
      return dispatch(deleteUserSuccess());
    })
    .catch((e) => {
      return dispatch(deleteUserError(e.response.data.reason));
    });
};

export const fetchSingleUser = (id) => (dispatch) => {
  dispatch(fetchSingleUserRequest());
  return axios
    .get(`${process.env.REACT_APP_API_END_POINT}${API_PATH}/${id}`, {
      timeout: 5000,
      headers: getApiHeaders(),
    })
    .then((response) => {
      return dispatch(fetchSingleUserSuccess(response.data));
    })
    .catch((e) => {
      return dispatch(fetchSingleUserError(e));
    });
};

export const fetchUsers = () => (dispatch) => {
  dispatch(fetchUsersRequest());
  return axios
    .get(`${process.env.REACT_APP_API_END_POINT}${API_PATH}`, {
      timeout: 5000,
      headers: getApiHeaders(),
    })
    .then((response) => {
      return dispatch(fetchUsersSuccess(response.data));
    })
    .catch((e) => {
      return dispatch(fetchUsersError(e));
    });
};

export const updateUser = (id, data) => (dispatch) => {
  dispatch(updateUserRequest());
  return axios
    .put(`${process.env.REACT_APP_API_END_POINT}${API_PATH}/${id}`, data, {
      timeout: 5000,
      headers: getApiHeaders(),
    })
    .then((response) => {
      return dispatch(updateUserSuccess());
    })
    .catch((e) => {
      return dispatch(updateUserError(e));
    });
};
