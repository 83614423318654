import React from "react";
import { array, bool } from "prop-types";
import { NamedLink } from "../index";
import css from "./MemberTable.module.css";
import { ensureImage, ensureImageVariant, ensureUser } from "../../utils/data";

class MemberTable extends React.Component {
  render() {
    const { members } = this.props;
    return (
      <table>
        <thead>
          <tr>
            <th className={css.statusHeader} />
            <th className={css.photoHeader} />
            <th>Name</th>
            <th>Company</th>
          </tr>
        </thead>
        <tbody>
          {members && members.length > 0
            ? members.map((member) => {
                const { publicData, state, title: name } = member.attributes;
                const { companyName } = publicData;
                const user = ensureUser(member.author);
                const profileImage = ensureImageVariant(
                  ensureImage(user.profileImage).attributes.variants[
                    "square-small"
                  ]
                );

                return (
                  <tr key={`member-${member.id.uuid}`}>
                    <td>
                      <span className={css[state]} />
                    </td>
                    <td>
                      {profileImage.url ? (
                        <img
                          alt={`${name}`}
                          className={css.profileImage}
                          src={profileImage.url}
                        />
                      ) : (
                        <span className={css.profileImagePlaceholder} />
                      )}
                    </td>
                    <td>
                      <NamedLink
                        name={"ManageMemberPage"}
                        pathParams={{ id: member.id.uuid }}
                      >
                        {name}
                      </NamedLink>
                    </td>
                    <td>
                      <NamedLink
                        name={"MembersPage"}
                        params={{ pub_companyNameFilter: companyName }}
                      >
                        {companyName}
                      </NamedLink>
                    </td>
                  </tr>
                );
              })
            : Array.from({ length: 20 }).map((dummy, index) => {
                return (
                  <tr className={css.loading} key={`dummy-${index}`}>
                    <td>
                      <span className={css.dummyStatus} />
                    </td>
                    <td>
                      <span className={css.dummyPhoto} />
                    </td>
                    <td>
                      <span className={css.dummyName} />
                    </td>
                    <td>
                      <span className={css.dummyCompany} />
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    );
  }
}

MemberTable.propTypes = {
  isLoading: bool,
  members: array,
};

MemberTable.defaultProps = {
  isLoading: true,
  members: [],
};

export default MemberTable;
