import React from "react";
import { ActionButton } from "../index";
import css from "./UserTable.module.css";
import { array, func } from "prop-types";

class UserTable extends React.Component {
  render() {
    const { onDelete, users } = this.props;
    return (
      <table className={css.root}>
        <thead>
          <tr>
            <th className={css.headerCel}>#</th>
            <th className={css.headerCel}>First name</th>
            <th className={css.headerCel}>Last name</th>
            <th className={css.headerCel}>E-mail address</th>
            <th className={css.headerCel}>Last login</th>
            <th className={css.headerCel} />
            <th className={css.headerCel} />
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            return (
              <tr key={`user-${user.id}`}>
                <td className={css.bodyCel}>{user.id}</td>
                <td className={css.bodyCel}>{user.firstName}</td>
                <td className={css.bodyCel}>{user.lastName}</td>
                <td className={css.bodyCel}>{user.email}</td>
                <td className={css.bodyCel}>
                  {user.loggedInAt ? user.loggedInAt : "Never"}
                </td>
                <td className={css.bodyCel}>
                  <ActionButton
                    name={"EditUserPage"}
                    pathParams={{ id: user.id }}
                  >
                    Edit
                  </ActionButton>
                </td>
                <td className={css.bodyCel}>
                  <ActionButton onClick={() => onDelete(user.id)}>
                    Delete
                  </ActionButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

UserTable.propTypes = {
  onDelete: func.isRequired,
  user: array,
};

UserTable.defaultProps = {
  users: [],
};

export default UserTable;
