import React from "react";
import { Form as FinalForm } from "react-final-form";
import css from "./LoginForm.module.css";
import { Button, Form, TextField } from "../../components";
import { withRouter } from "react-router-dom";
import { func } from "prop-types";

class LoginForm extends React.Component {
  render() {
    return (
      <div className={css.root}>
        <FinalForm
          {...this.props}
          render={(fieldRenderProps) => {
            const { handleSubmit, values } = fieldRenderProps;
            return (
              <Form
                className={css.form}
                onSubmit={(e) => {
                  this.submittedValues = values;
                  return handleSubmit(e);
                }}
              >
                <h2 className={css.header}>Login</h2>
                <TextField
                  id={"email"}
                  name={"email"}
                  label={"E-mail address"}
                />
                <TextField
                  id={"password"}
                  name={"password"}
                  label={"Password"}
                  type={"password"}
                />
                <Button type={"submit"}>Log in</Button>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

LoginForm.propTypes = {
  onSubmit: func.isRequired,
};

export default withRouter(LoginForm);
