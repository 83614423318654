import React, { FC, PropsWithChildren } from "react";
import css from "../TenantManagerPage.module.css";

interface iTable {
  headers: string[];
  loading?: boolean;
  className?: string;
  caption?: string;
}

const TableLoaderRow: FC<iTable> = ({ headers }) => (
  <tr>
    {headers.map((str, i) => (
      <td key={i}>
        <div
          className={css["loader-background"]}
          style={{ width: 120, height: 19.2 }}
        ></div>
      </td>
    ))}
  </tr>
);

const Table: FC<PropsWithChildren<iTable>> = ({
  children,
  headers = [],
  loading = false,
  className = "",
  caption,
}) => {
  return (
    <table className={className}>
      {caption ? <caption>{caption}</caption> : null}
      <thead>
        <tr>
          {headers.map((str, index) => (
            <th key={index}>{str}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {loading
          ? new Array(3)
              .fill(null)
              .map((_, index) => (
                <TableLoaderRow key={index} headers={headers} />
              ))
          : children}
      </tbody>
    </table>
  );
};

Table.defaultProps = {
  loading: false,
  headers: [],
  className: "",
  caption: "",
};

export default Table;
