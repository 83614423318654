import React, { FC, PropsWithChildren } from "react";
import css from "./Badge.module.css";

interface iBadge {
  className?: string;
}

export const Badge: FC<PropsWithChildren<iBadge>> = ({
  children,
  className = "",
}) => {
  return <span className={`${css["root"]} ${className}`}>{children}</span>;
};
