import PropTypes from "prop-types";
import merge from "lodash.merge";

export const propTypeToString = (propType) => {
  return Object.keys(PropTypes).find((key) => {
    return PropTypes[key] === propType;
  });
};

export const ensureNonprofit = (nonprofit) => {
  const empty = {
    id: {},
    attributes: {
      publicData: {},
    },
    author: {},
  };
  return merge(empty, nonprofit);
};

export const ensureUser = (user) => {
  const empty = {
    id: {},
    attributes: {
      publicData: {},
      privateData: {},
    },
    profileImage: {},
  };
  return merge(empty, user);
};

export const ensureImage = (image) => {
  const empty = {
    attributes: {
      variants: {},
    },
  };
  return merge(empty, image);
};

export const ensureImageVariant = (imageVariant) => {
  const empty = {
    height: 0,
    url: null,
    width: 0,
  };
  return merge(empty, imageVariant);
};
