import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { fetchTenant } from "../api";
import { useAsyncCallback } from "../shared/useAsyncCallback";
import css from "../TenantManagerPage.module.css";
import TenantUsers from "./TenantUsers";
import { Button } from "../../../components";
import { TENANT_CAPABILITIES } from "@givsly/aws-tenant-manager";
import { ui_updateTenantCapabilities } from "../api";

interface iTenantDetail {
  tenant_id: string;
}

const GIVSLY_DOMAIN = process.env.REACT_APP_GIVSLY_DOMAIN || "";

const TEXT = {
  COPY: "Click to copy",
  COPIED: "Copied to clipboard",
};

const CAPABILITY_EXPLAINERS: Record<string, string> = {
  [TENANT_CAPABILITIES.ADS]:
    "Responsible Advertising",
  [TENANT_CAPABILITIES.MARKETING]:
    "Responsible Marketing",
  NO_EXPLANATION: "Unknown capability, no explanation, ask Stephen",
};

const TenantDetail: FC<iTenantDetail> = ({ tenant_id }) => {
  
  const _fetchTenant = useCallback(() => fetchTenant(tenant_id), [tenant_id]);
  const { data, isLoading, isError, retry } = useAsyncCallback(_fetchTenant);
  const inputRef = useRef<HTMLInputElement>(null);

  const [copyTooltipText, setCopyTooltipText] = useState(TEXT.COPY);
  const [capabilities, setCapabilities] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (data && data.admin.capabilities) {
      const capabilities = {
        "advertising": data.admin.capabilities.advertising,
        "marketing": data.admin.capabilities.marketing,
        "events": data.admin.capabilities.events,
        "platform": data.admin.capabilities.platform,
      }
      setCapabilities(capabilities);
    }
  }, [data]);

  const handleCapabilityChange = useCallback(
    (capability: string) => {
      setCapabilities((prevCapabilities) => ({
        ...prevCapabilities,
        [capability]: !prevCapabilities[capability],
      }));
    },
    []
  );

  const handleTenantCapabilitiesUpdate = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent default form submission behavior
    // API call to update tenant capabilities
    try {
      await ui_updateTenantCapabilities(tenant_id, capabilities);
      alert("Capabilities updated successfully!");
    } catch (error) {
      console.error("Failed to update capabilities", error);
      alert("Failed to update capabilities.");
    }
  };

  const copySignupUrl = useCallback(() => {
    const input = inputRef.current;
    if (input) {
      input.select();
      input.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(input.value);
      setCopyTooltipText(TEXT.COPIED);
    }
  }, []);

  useEffect(() => {
    if (copyTooltipText === TEXT.COPIED) {
      setTimeout(() => {
        setCopyTooltipText(TEXT.COPY);
      }, 2000);
    }
  }, [copyTooltipText]);

  if (isLoading) return <p>Loading info for {tenant_id} ...</p>;

  if (isError || !data)
    return (
      <p>
        Something went wrong.{" "}
        <button type="button" onClick={() => retry()}>
          refresh
        </button>
      </p>
    );


  return (
    <>
      <h1>
        {data.profile.name} ( {data.tenant} )
      </h1>
      <div>
        <h2>Logo</h2>
        <img src={data.profile.logo} alt="logo" height={40} />
      </div>
      <form onSubmit={handleTenantCapabilitiesUpdate}>
        <h2>Update Tenant Capabilities</h2>
        {Object.entries(TENANT_CAPABILITIES).map(([key, capability]) => (
          <div key={key}>
            <label>
              <input type="checkbox"
                className={css["checkbox"]}
                style={{ marginRight: 8 }}
                checked={!!capabilities[capability]}
                onChange={() => handleCapabilityChange(capability)}
              />
              {capability} - {CAPABILITY_EXPLAINERS[capability]}
            </label>
          </div>
        ))}
        <Button type="submit" className={css["btn-small"]}>
          Update capabilities
        </Button>
      </form>

      <div className={css.row}>
        <div>
          <h2>Advertising Admin</h2>
          <p>
            <a href={`mailto:${data.profile.admin.email}`}>{data.profile.admin.name}</a>
          </p>
        </div>
      </div>

      <div className={css.row}>
        <div>
          <h2>Marketing Admin</h2>
          <p>
            <a href={`mailto:${data.profile.admin_gm.email}`}>{data.profile.admin_gm.name}</a>
          </p>
        </div>
      </div>

      <div className={css.row}>
        <div>
          <h2>Givsly advertising account manager</h2>
          <p>
            <a href={`mailto:${data.admin.subscriptions.sub_ga.account_manager.email}`}>{data.admin.subscriptions.sub_ga.account_manager.name}</a>
          </p>
        </div>
      </div>

      <div className={css.row}>
        <div>
          <h2>Givsly marketing account manager</h2>
          <p>
            <a href={`mailto:${data.admin.subscriptions.sub_gm.account_manager.email}`}>{data.admin.subscriptions.sub_gm.account_manager.name}</a>
          </p>
        </div>
      </div>

      <div>
        <h2>Tenant signup url (classic givsly)</h2>
        <div className={css.row}>
          <input
            value={GIVSLY_DOMAIN.concat("/signup/").concat(tenant_id)}
            ref={inputRef}
            readOnly
          />
          <Button
            type="button"
            onClick={copySignupUrl}
            className={css["btn-small"]}
            data-tooltip={copyTooltipText}
          >
            Copy
          </Button>
        </div>
      </div>

      <TenantUsers
        currentDefaultPayer={data.admin.payer_id}
        tenant_id={tenant_id}
        isGA={data.admin.capabilities.advertising}
        isGM={data.admin.capabilities.marketing}
      />
      
    </>
  );
};

export default TenantDetail;
