import React from "react";
import { array, string } from "prop-types";
import css from "./Select.module.css";
import classNames from "classnames";
import { Field } from "react-final-form";

class SelectComponent extends React.Component {
  render() {
    const { id, input, label, meta, options, type, ...rest } = this.props;

    const { valid, invalid, touched, error } = meta;
    const { value: defaultValue, ...inputWithoutValue } = input;

    const selectProps = {
      id,
      type,
      defaultValue,
      ...inputWithoutValue,
      ...rest,
    };

    const hasError = (meta.error && meta.touched) || invalid;
    const hasSuccess = (touched && error) || valid;
    const classes = classNames(
      css.root,
      hasSuccess ? css.success : null,
      hasError ? css.error : null
    );

    return (
      <div className={classes}>
        {label && <label htmlFor={id}>{label}</label>}
        <select {...selectProps} id={id} className={css.options}>
          {options.map((option) => {
            console.log(option);
            return (
              <option key={`${id}-${option.key}`} value={option.key}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

SelectComponent.propTypes = {
  className: string,
  id: string.isRequired,
  label: string,
  options: array.isRequired,
};

SelectComponent.defaultProps = {
  label: null,
};

const Select = (props) => <Field component={SelectComponent} {...props} />;

export default Select;
