import React from "react";

const formatMoney = (value: number | undefined): string => {
  if (value === undefined) {
    return "";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(value);
};

const OfferCreatorSummary = ({
  offerCreatorSummary,
}: {
  offerCreatorSummary: any;
}) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Company name</th>
          <th>Last name</th>
          <th>First name</th>
          <th>Created all time</th>
          <th>Created this month</th>
          <th>Accepted all time</th>
          <th>Accepted this month</th>
          <th>Discarded all time</th>
          <th>Discarded this month</th>
          <th>Pending all time</th>
          <th>Acceptances processed all time</th>
          <th>Acceptances processed this month</th>
          <th>Donations paid all time</th>
          <th>Donations paid this month</th>
          <th>Payments processed all time</th>
          <th>Payments processed this month</th>
          <th>Credit deductions all time</th>
          <th>Credit deductions this month</th>
        </tr>
      </thead>
      <tbody>
        {offerCreatorSummary &&
          offerCreatorSummary.map((row: any, i: number) => {
            return (
              <tr key={i}>
                <td>{row.companyName}</td>
                <td>
                  <a
                    href={`https://flex-console.sharetribe.com/o/givsly/m/givsly/transactions?participant_id=${row.userId}`}
                  >
                    {row.lastName}
                  </a>
                </td>
                <td>{row.firstName}</td>
                <td>{row.createdAllTime}</td>
                <td>{row.createdThisMonth}</td>
                <td>{row.acceptedAllTime}</td>
                <td>{row.acceptedThisMonth}</td>
                <td>{row.discardedAllTime}</td>
                <td>{row.discardedThisMonth}</td>
                <td>{row.pendingAllTime}</td>
                <td>{row.acceptancesProcessedAllTime}</td>
                <td>{row.acceptancesProcessedThisMonth}</td>
                <td>{formatMoney(row.donationsPaidAllTime)}</td>
                <td>{formatMoney(row.donationsPaidThisMonth)}</td>
                <td>{formatMoney(row.paymentsProcessedAllTime)}</td>
                <td>{formatMoney(row.paymentsProcessedThisMonth)}</td>
                <td>{formatMoney(row.creditDeductionsAllTime)}</td>
                <td>{formatMoney(row.creditDeductionsThisMonth)}</td>
              </tr>
            );
          })}
        {offerCreatorSummary && (
          <tr key={offerCreatorSummary.length}>
            <td>
              <b>Total</b>
            </td>
            <td />
            <td />
            <td>
              {offerCreatorSummary.reduce(
                (a: number, b: any) => a + b.createdAllTime,
                0
              )}
            </td>
            <td>
              {offerCreatorSummary.reduce(
                (a: number, b: any) => a + b.createdThisMonth,
                0
              )}
            </td>
            <td>
              {offerCreatorSummary.reduce(
                (a: number, b: any) => a + b.acceptedAllTime,
                0
              )}
            </td>
            <td>
              {offerCreatorSummary.reduce(
                (a: number, b: any) => a + b.acceptedThisMonth,
                0
              )}
            </td>
            <td>
              {offerCreatorSummary.reduce(
                (a: number, b: any) => a + b.discardedAllTime,
                0
              )}
            </td>
            <td>
              {offerCreatorSummary.reduce(
                (a: number, b: any) => a + b.discardedThisMonth,
                0
              )}
            </td>
            <td>
              {offerCreatorSummary.reduce(
                (a: number, b: any) => a + b.pendingAllTime,
                0
              )}
            </td>
            <td>
              {offerCreatorSummary.reduce(
                (a: number, b: any) => a + b.acceptancesProcessedAllTime,
                0
              )}
            </td>
            <td>
              {offerCreatorSummary.reduce(
                (a: number, b: any) => a + b.acceptancesProcessedThisMonth,
                0
              )}
            </td>
            <td>
              {formatMoney(
                offerCreatorSummary.reduce(
                  (a: number, b: any) => a + b.donationsPaidAllTime,
                  0
                )
              )}
            </td>
            <td>
              {formatMoney(
                offerCreatorSummary.reduce(
                  (a: number, b: any) => a + b.donationsPaidThisMonth,
                  0
                )
              )}
            </td>
            <td>
              {formatMoney(
                offerCreatorSummary.reduce(
                  (a: number, b: any) => a + b.paymentsProcessedAllTime,
                  0
                )
              )}
            </td>
            <td>
              {formatMoney(
                offerCreatorSummary.reduce(
                  (a: number, b: any) => a + b.paymentsProcessedThisMonth,
                  0
                )
              )}
            </td>
            <td>
              {formatMoney(
                offerCreatorSummary.reduce(
                  (a: number, b: any) => a + b.creditDeductionsAllTime,
                  0
                )
              )}
            </td>
            <td>
              {formatMoney(
                offerCreatorSummary.reduce(
                  (a: number, b: any) => a + b.creditDeductionsThisMonth,
                  0
                )
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default OfferCreatorSummary;
