import { STATUS_ERROR, STATUS_OK } from "../utils/statusHelper";
import axios from "axios";
import { getApiHeaders } from "../utils/api";

const API_PATH = "members";

// Actions
const ACTION_FETCH_MEMBER_REQUEST =
  "givsly-dashboard/member/FETCH_MEMBER_REQUEST";
const ACTION_FETCH_MEMBER_SUCCESS =
  "givsly-dashboard/member/FETCH_MEMBER_SUCCESS";
const ACTION_FETCH_MEMBER_ERROR = "givsly-dashboard/member/FETCH_MEMBER_ERROR";
const ACTION_QUERY_MEMBERS_REQUEST =
  "givsly-dashboard/member/QUERY_MEMBERS_REQUEST";
const ACTION_QUERY_MEMBERS_SUCCESS =
  "givsly-dashboard/member/QUERY_MEMBERS_SUCCESS";
const ACTION_QUERY_MEMBERS_ERROR =
  "givsly-dashboard/member/QUERY_MEMBERS_ERROR";
const ACTION_UPDATE_MEMBER_REQUEST =
  "givsly-dashboard/member/UPDATE_MEMBER_REQUEST";
const ACTION_UPDATE_MEMBER_SUCCESS =
  "givsly-dashboard/member/UPDATE_MEMBER_SUCCESS";
const ACTION_UPDATE_MEMBER_ERROR =
  "givsly-dashboard/member/UPDATE_MEMBER_ERROR";

// Initial state
const initialState = {
  fetchMemberError: false,
  fetchMemberInProgress: false,
  member: null,
  members: [],
  queryError: false,
  queryInProgress: false,
  queryMeta: {},
  updateMemberError: false,
  updateMemberInProgress: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
    case ACTION_FETCH_MEMBER_REQUEST:
      return {
        ...state,
        fetchMemberError: false,
        fetchMemberInProgress: true,
        member: null,
      };
    case ACTION_FETCH_MEMBER_SUCCESS:
      return {
        ...state,
        fetchMemberInProgress: false,
        member: payload.data,
      };
    case ACTION_FETCH_MEMBER_ERROR:
      return {
        ...state,
        fetchMemberError: true,
        fetchMemberInProgress: false,
      };
    case ACTION_QUERY_MEMBERS_REQUEST:
      return {
        ...state,
        members: [],
        queryError: false,
        queryInProgress: true,
        queryMeta: {},
      };
    case ACTION_QUERY_MEMBERS_SUCCESS:
      return {
        ...state,
        members: payload.data.data,
        queryInProgress: false,
        queryMeta: payload.data.meta,
      };
    case ACTION_QUERY_MEMBERS_ERROR:
      return {
        ...state,
        members: [],
        queryError: true,
        queryInProgress: false,
        queryMeta: {},
      };
    case ACTION_UPDATE_MEMBER_REQUEST:
      return {
        ...state,
        updateMemberError: false,
        updateMemberInProgress: true,
      };
    case ACTION_UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        updateMemberError: false,
        updateMemberInProgress: false,
      };
    case ACTION_UPDATE_MEMBER_ERROR:
      return {
        ...state,
        updateMemberError: true,
        updateMemberInProgress: false,
      };
  }
}

// Action creators
const fetchMemberRequest = () => {
  return {
    type: ACTION_FETCH_MEMBER_REQUEST,
  };
};

const fetchMemberSuccess = (data) => {
  return {
    type: ACTION_FETCH_MEMBER_SUCCESS,
    payload: data,
  };
};

const fetchMemberError = (error) => {
  return {
    type: ACTION_FETCH_MEMBER_ERROR,
    payload: error,
  };
};

const queryMembersRequest = () => {
  return {
    type: ACTION_QUERY_MEMBERS_REQUEST,
  };
};

const queryMembersSuccess = (data) => {
  return {
    type: ACTION_QUERY_MEMBERS_SUCCESS,
    payload: data,
    status: STATUS_OK,
  };
};

const queryMembersError = (error) => {
  return {
    type: ACTION_QUERY_MEMBERS_ERROR,
    payload: error,
    status: STATUS_ERROR,
  };
};

const updateMemberRequest = () => {
  return {
    type: ACTION_UPDATE_MEMBER_REQUEST,
  };
};

const updateMemberSuccess = () => {
  return {
    type: ACTION_UPDATE_MEMBER_SUCCESS,
    status: STATUS_OK,
  };
};

const updateMemberError = (error) => {
  return {
    type: ACTION_UPDATE_MEMBER_ERROR,
    payload: error,
    status: STATUS_ERROR,
  };
};

// Thunks
export const fetchMember = (id) => (dispatch) => {
  dispatch(fetchMemberRequest());
  return axios
    .get(`${process.env.REACT_APP_API_END_POINT}${API_PATH}/${id}`, {
      timeout: 5000,
      headers: getApiHeaders(),
    })
    .then((response) => {
      dispatch(fetchMemberSuccess(response.data));
      return response.data.data;
    })
    .catch((e) => {
      return dispatch(fetchMemberError(e));
    });
};

export const queryMembers = (queryParams = {}) => (dispatch) => {
  dispatch(queryMembersRequest());
  return axios
    .get(`${process.env.REACT_APP_API_END_POINT}${API_PATH}`, {
      params: queryParams,
      timeout: 5000,
      headers: getApiHeaders(),
    })
    .then((response) => {
      return dispatch(queryMembersSuccess(response));
    })
    .catch((e) => {
      return dispatch(queryMembersError(e));
    });
};

export const updateMember = (id, updatedValues) => (dispatch) => {
  dispatch(updateMemberRequest());
  return axios
    .patch(
      `${process.env.REACT_APP_API_END_POINT}${API_PATH}/${id}`,
      updatedValues,
      {
        timeout: 5000,
        headers: getApiHeaders(),
      }
    )
    .then((response) => {
      dispatch(updateMemberSuccess());
      return response.data;
    })
    .catch((e) => {
      dispatch(updateMemberError(e));
      return e;
    });
};
