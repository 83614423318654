import { GROUPS, iUser } from "@givsly/aws-tenant-manager";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import {
  addUserToGroup,
  fetchTenantUsers,
  removeTenantUser,
  removeUserFromGroup,
  verifyUserEmail,
} from "../api";
import Table from "../shared/Table";
import TenantUserCsvDropZone from "./TenantUserCsvDropZone";
import TenantUserForm from "./TenantUserForm";
import { useAsyncCallback } from "../shared/useAsyncCallback";
import { TenantPayerForm } from "./TenantPayerForm";
import { AsyncCheckbox, checkboxStyle } from "../shared/AsyncCheckbox";

const TABLE_HEADERS = [
  "Name",
  "Email",
  "Status",
  "GA",
  "GM",
  "Sales",
  "Ops",
  "GM Payer",
  "Admin",
  "ST",
  "Email verified",
  "Actions",
];

interface iUserRow {
  user: iUser;
  setData: Dispatch<SetStateAction<iUser[] | undefined>>;
  isGA?: boolean;
  isGM?: boolean;
}

const UserRow: FC<iUserRow> = ({
  user,
  setData,
  isGA = false,
  isGM = false,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const {
    email,
    name,
    status,
    username,
    groups = [],
    tenant_id,
    email_verified,
    sharetribe_id,
  } = user;
  const updateUserGroup = useCallback(
    async (checked: boolean, group: GROUPS) => {
      setIsUpdating(true);
      const result = checked
        ? await removeUserFromGroup({ username, email, group, tenant_id })
        : await addUserToGroup({ username, email, group, tenant_id });

      if (result === 200) {
        const new_groups = checked
          ? groups.filter((_group) => _group !== group)
          : [...groups, group];
        setData((_users) => {
          if (_users && Array.isArray(_users)) {
            return _users.map((_user) =>
              _user.username === username
                ? { ..._user, groups: new_groups }
                : _user
            );
          }
          return _users;
        });
      }
      setIsUpdating(false);
    },
    [email, groups, setData, tenant_id, username]
  );

  const handeUserEmailVerification = useCallback(async () => {
    setIsUpdating(true);
    const result = await verifyUserEmail(username);

    if (result === 200) {
      setData((_users) => {
        if (_users && Array.isArray(_users)) {
          return _users.map((_user) =>
            _user.username === username
              ? { ..._user, email_verified: true }
              : _user
          );
        }
        return _users;
      });
    }
    setIsUpdating(false);
  }, [setData, username]);

  const removeUser = useCallback(async () => {
    setIsUpdating(true);
    const result = await removeTenantUser(username, email);
    if (result === 200) {
      setData((users = []) =>
        users.filter((_user) => _user.username !== username)
      );
    }
    setIsUpdating(false);
  }, [email, setData, username]);

  return (
    <tr key={email}>
      <td>{name}</td>
      <td>{email}</td>
      <td>{status}</td>
      <td>
        <AsyncCheckbox
          forwardRef={{ disabled: !isGA || isUpdating }}
          checked={groups.indexOf(GROUPS.SUB_GA) > -1}
          onChange={async (checked) => updateUserGroup(checked, GROUPS.SUB_GA)}
        />
      </td>
      <td>
        <AsyncCheckbox
          forwardRef={{
            disabled: !isGM || isUpdating || groups.includes(GROUPS.GM_PAYER),
          }}
          checked={groups.indexOf(GROUPS.SUB_GM) > -1}
          onChange={async (checked) => updateUserGroup(checked, GROUPS.SUB_GM)}
        />
      </td>
      <td>
        <AsyncCheckbox
          forwardRef={{ disabled: !isGA || isUpdating }}
          checked={groups.indexOf(GROUPS.GA_SALES) > -1}
          onChange={async (checked) =>
            updateUserGroup(checked, GROUPS.GA_SALES)
          }
        />
      </td>
      <td>
        <AsyncCheckbox
          forwardRef={{ disabled: !isGA || isUpdating }}
          checked={groups.indexOf(GROUPS.GA_OPS) > -1}
          onChange={async (checked) => updateUserGroup(checked, GROUPS.GA_OPS)}
        />
      </td>
      <td>
        <AsyncCheckbox
          forwardRef={{
            disabled: !isGM || isUpdating || !groups.includes(GROUPS.SUB_GM),
            style: {
              ...checkboxStyle,
              accentColor: sharetribe_id ? "lightgreen" : "orange",
            } as React.InputHTMLAttributes<HTMLInputElement>["style"],
          }}
          checked={groups.indexOf(GROUPS.GM_PAYER) > -1}
          onChange={async (checked) =>
            updateUserGroup(checked, GROUPS.GM_PAYER)
          }
        />
      </td>
      <td>
        <AsyncCheckbox
          checked={groups.indexOf(GROUPS.ADMINS) > -1}
          onChange={async (checked) => updateUserGroup(checked, GROUPS.ADMINS)}
        />
      </td>
      <td>
        <input
          type="checkbox"
          checked={
            typeof sharetribe_id === "string" && sharetribe_id.length > 0
          }
          readOnly
          disabled
          style={checkboxStyle}
        />
      </td>
      <td>
        <AsyncCheckbox
          forwardRef={{ disabled: email_verified || isUpdating }}
          checked={email_verified}
          onChange={async (checked) => handeUserEmailVerification()}
        />
      </td>
      <td>
        <button type="button" onClick={removeUser} disabled={isUpdating}>
          X
        </button>
      </td>
    </tr>
  );
};

interface iTenantUsers {
  tenant_id: string;
  isGA?: boolean;
  isGM?: boolean;
  currentDefaultPayer: string;
}

const TenantUsers: FC<iTenantUsers> = ({
  tenant_id,
  currentDefaultPayer,
  isGA = false,
  isGM = false,
}) => {
  const _fetchTenantUsers = useCallback(
    () => fetchTenantUsers(tenant_id),
    [tenant_id]
  );
  const {
    data = [],
    isLoading,
    isError,
    error,
    retry,
    setData,
  } = useAsyncCallback(_fetchTenantUsers);

  if (isError) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <TenantPayerForm
        options={data}
        tenantId={tenant_id}
        currentDefaultPayer={currentDefaultPayer}
      />
      <Table loading={isLoading} headers={TABLE_HEADERS}>
        {data.map((user) => (
          <UserRow
            key={user.username}
            user={user}
            setData={setData}
            isGA={isGA}
            isGM={isGM}
          />
        ))}
      </Table>
      <TenantUserCsvDropZone
        isGA={isGA}
        isGM={isGM}
        tenant_id={tenant_id}
        onUploadSuccess={() => retry()}
      />


      <h2>Add new user</h2>
      <div style={{
          marginTop: 10,
          marginBottom: 10,
          border: "1px solid #dadada",
          padding: 32,
          borderRadius: 4,
      }}>
        <TenantUserForm
          tenant_id={tenant_id}
          onAfterSubmit={() => retry()}
          isGA={isGA}
          isGM={isGM}
        />
      </div>


    </>
  );
};

export default TenantUsers;
