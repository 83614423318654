import React from "react";

const PopularOffers = ({
  acceptsByLinkByUser,
}: {
  acceptsByLinkByUser: any;
}) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Company name</th>
          <th>Last name</th>
          <th>First name</th>
          <th>Link Name</th>
          <th>Acceptances</th>
        </tr>
      </thead>
      <tbody>
        {acceptsByLinkByUser &&
          acceptsByLinkByUser.map((row: any, i: number) => {
            return (
              <tr key={i}>
                <td>{row.companyName}</td>
                <td>
                  <a
                    href={`https://flex-console.sharetribe.com/o/givsly/m/givsly/transactions?participant_id=${row.userId}`}
                  >
                    {row.lastName}
                  </a>
                </td>
                <td>{row.firstName}</td>
                <td>
                  <a
                    href={`${process.env.REACT_APP_GIVSLY_DOMAIN}/outreach/${row.linkId}`}
                  >
                    {row.linkName}
                  </a>
                </td>
                <td>{row.acceptedCount}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default PopularOffers;
