import { STATUS_ERROR, STATUS_OK, STATUS_WARNING } from "./statusHelper";

export const LEVEL_ERROR = "error";
export const LEVEL_INFO = "info";
export const LEVEL_NOTIFICATION = "notification";
export const LEVEL_SUCCESS = "success";
export const LEVEL_WARNING = "warning";

export const statusToNotificationLevel = (status) => {
  switch (status) {
    default:
      return LEVEL_NOTIFICATION;
    case STATUS_OK:
      return LEVEL_SUCCESS;
    case STATUS_ERROR:
      return LEVEL_ERROR;
    case STATUS_WARNING:
      return LEVEL_WARNING;
  }
};
