import { FormApi } from "final-form";
import React, { FC, useCallback, useState } from "react";
import { TextField } from "../../../components";
import css from "../TenantManagerPage.module.css";
import {
  FormRenderProps,
  Form as FinalForm,
  AnyObject,
} from "react-final-form";
import { createNonprofitUser } from "../api";
import { SubmitButton } from "../shared/SubmitButton";
import { FormTextField } from "../shared/FormTextField";
import { FormCheckboxField } from "../shared/FormCheckboxField/FormCheckboxField";

function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

interface iNonprofitUserForm {
  onAfterSubmit?: () => void;
  npo_id: string;
}

export const NonprofitUserForm: FC<iNonprofitUserForm> = ({
  onAfterSubmit,
  npo_id,
}) => {
  const [error, setError] = useState<string>();

  const onSubmit = useCallback(
    async (values: AnyObject, form: FormApi<AnyObject, Partial<AnyObject>>) => {
      if (error) {
        setError(undefined);
      }
      const result = await createNonprofitUser(npo_id, values);

      if (result.status >= 400) {
        setError(`[${result.json.code}]: ${result.json.message}`);

        return;
      }

      if (typeof onAfterSubmit === "function") {
        onAfterSubmit();
      }
      setTimeout(() => {
        form.reset();
      }, 1000);
    },
    [error, npo_id, onAfterSubmit]
  );

  const validateEmailField = (value: string) => {
    if (!value) {
      return "Email is required";
    }
    if (!isValidEmail(value)) {
      return "Must be a valid email address";
    }
  };

  return (
    <>
      {error && <div className={css["error"]}>{error}</div>}
      <FinalForm
        onSubmit={onSubmit}
        initialValues={{}}
        validate={(values) => {
          const errors: Record<string, string> = {};

          if (!values.email) {
            errors.email = "Email is required";
          }

          if (!isValidEmail(values.email)) {
            errors.email = "Email is invalid";
          }

          return errors;
        }}
        render={(formRenderProps: FormRenderProps) => {
          const { submitting, submitSucceeded } = formRenderProps;
          return (
            <form onSubmit={formRenderProps.handleSubmit}>
              <div className={css["stack"]}>
              
                <div className={[css.row, css.grow].join(" ")}>

                  <TextField
                    required
                    type="text"
                    name="name"
                    id="name"
                    label="User's first name"
                  />

                  <TextField
                    required
                    type="text"
                    name="family_name"
                    id="family_name"
                    label="User's last name"
                  />
                </div>

                <div className={[css.row, css.grow].join(" ")}>
                  <TextField
                    required
                    type="email"
                    name="email"
                    id="email"
                    validate={validateEmailField}
                    label="User's Email"
                  />
                </div>

                <div className={[css.row, css.grow].join(" ")}>
                  <FormCheckboxField name="is_admin" label="Make admin" />
                </div>

                <SubmitButton
                  label="Create user"
                  submitting={submitting}
                  submitSucceeded={!error && submitSucceeded}
                />
              </div>
            </form>
          );
        }}
      />
    </>
  );
};
