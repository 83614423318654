import React from "react";
import css from "./Sidebar.module.css";
import logo from "../../assets/images/givsly-logo.svg";
import { Navigation, UserInfo } from "../index";

class Sidebar extends React.Component {
  render() {
    return (
      <div className={css.root}>
        <img src={logo} className={css.logo} alt="Givsly Logo" />
        <Navigation />
        <UserInfo />
      </div>
    );
  }
}

export default Sidebar;
