import {
  AuthenticationPage,
  BookingBoardPage,
  InvitationsPage,
  MembersPage,
  NonprofitsPage,
  OutreachPage,
  GoodAdvertisingPage,
  UsersPage,
} from "./containers";
import TenantManagerPage from "./containers/TenantManagerPage/TenantManagerPage";

export const routeConfiguration = [
  {
    name: "InvitationsPage",
    path: "/invitations",
    auth: true,
    component: InvitationsPage,
  },
  {
    name: "ManageMemberPage",
    path: "/members/:id",
    auth: true,
    component: MembersPage,
    loadData: MembersPage.loadData,
  },
  {
    name: "BookingBoardPage",
    path: "/booking-board",
    auth: true,
    component: BookingBoardPage,
    // loadData: BookingBoardPage.loadData,
  },
  {
    name: "MembersPage",
    path: "/members",
    auth: true,
    component: MembersPage,
    loadData: MembersPage.loadData,
  },
  {
    name: "ManageNonprofitPage",
    path: "/nonprofit/:id",
    auth: true,
    component: NonprofitsPage,
    loadData: NonprofitsPage.loadData,
  },
  {
    name: "NonprofitsPage",
    path: "/nonprofits",
    auth: true,
    component: NonprofitsPage,
    loadData: NonprofitsPage.loadData,
  },
  {
    name: "NewUserPage",
    path: "/users/new",
    component: UsersPage,
    auth: true,
    loadData: UsersPage.loadData,
  },
  {
    name: "EditUserPage",
    path: "/users/:id/edit",
    component: UsersPage,
    auth: true,
    loadData: UsersPage.loadData,
  },
  {
    name: "UsersPage",
    path: "/users",
    component: UsersPage,
    auth: true,
    loadData: UsersPage.loadData,
  },
  {
    name: "OutreachPage",
    path: "/outreach",
    component: OutreachPage,
    auth: true,
    loadData: OutreachPage.loadData,
  },
  {
    name: "GoodAdvertisingPage",
    path: "/goodAdvertising",
    component: GoodAdvertisingPage,
    auth: true,
    loadData: GoodAdvertisingPage.loadData,
  },
  {
    name: "TenantManagerEditSectionPage",
    path: "/subscription-manager/:tab/:id/edit/:section",
    component: TenantManagerPage,
    auth: true,
  },
  {
    name: "TenantManagerEditPage",
    path: "/subscription-manager/:tab/:id/edit",
    component: TenantManagerPage,
    auth: true,
  },
  {
    name: "TenantManagerDetailPage",
    path: "/subscription-manager/:tab/:id",
    component: TenantManagerPage,
    auth: true,
  },
  {
    name: "TenantManagerPage",
    path: "/subscription-manager/:tab",
    component: TenantManagerPage,
    params: { tab: "subscribers" },
    auth: true,
  },
  {
    name: "LoginPage",
    path: "/",
    component: AuthenticationPage,
  },
];
