import React from "react";
import { array, bool } from "prop-types";
import { NamedLink } from "../index";
import css from "./NonprofitTable.module.css";
import { ensureImage, ensureImageVariant, ensureUser } from "../../utils/data";
import { latestNonprofitTermsAndPrivacyApprovalVersion } from "../../config";

class NonprofitTable extends React.Component {
  render() {
    const { nonprofits } = this.props;
    return (
      <table>
        <thead>
          <tr>
            <th className={css.statusHeader} />
            <th className={css.photoHeader} />
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {nonprofits && nonprofits.length > 0
            ? nonprofits.map((nonprofit) => {
                const { title: name } = nonprofit.attributes;

                const ensuredUser = ensureUser(nonprofit.author);

                const {
                  nonprofitTermsAndPrivacyApprovals,
                } = ensuredUser.attributes.profile.privateData;

                var stripeAccountStatus = false;
                if (ensuredUser && ensuredUser.stripeAccount) {
                  stripeAccountStatus = ensuredUser.stripeAccount.payouts_enabled
                } 

                const { agreePrivacyPolicy, agreeTermsOfService } =
                  (nonprofitTermsAndPrivacyApprovals &&
                    nonprofitTermsAndPrivacyApprovals[
                      latestNonprofitTermsAndPrivacyApprovalVersion
                    ]) ||
                  {};

                const state =
                  agreePrivacyPolicy &&
                  agreeTermsOfService &&
                  stripeAccountStatus
                    ? "ready"
                    : "notReady";

                const profileImage = ensureImageVariant(
                  ensureImage(ensuredUser.profileImage).attributes.variants[
                    "square-small"
                  ]
                );

                return (
                  <tr key={`nonprofit-${nonprofit.id.uuid}`}>
                    <td>
                      <span className={css[state]} />
                    </td>
                    <td>
                      {profileImage.url ? (
                        <img
                          alt={`${name}`}
                          className={css.profileImage}
                          src={profileImage.url}
                        />
                      ) : (
                        <span className={css.profileImagePlaceholder} />
                      )}
                    </td>
                    <td>
                      <NamedLink
                        name={"ManageNonprofitPage"}
                        pathParams={{ id: nonprofit.id.uuid }}
                      >
                        {name}
                      </NamedLink>
                    </td>
                  </tr>
                );
              })
            : Array.from({ length: 20 }).map((dummy, index) => {
                return (
                  <tr className={css.loading} key={`dummy-${index}`}>
                    <td>
                      <span className={css.dummyStatus} />
                    </td>
                    <td>
                      <span className={css.dummyPhoto} />
                    </td>
                    <td>
                      <span className={css.dummyName} />
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    );
  }
}

NonprofitTable.propTypes = {
  isLoading: bool,
  nonprofits: array,
};

NonprofitTable.defaultProps = {
  isLoading: true,
  nonprofits: [],
};

export default NonprofitTable;
