import React from "react";
import { array, string } from "prop-types";
import css from "./RadioGroup.module.css";
import classNames from "classnames";
import { RadioField } from "../index";
import { FieldArray } from "react-final-form-arrays";

class RadioGroupComponent extends React.Component {
  render() {
    const { className, fields, id, label, onChange, options } = this.props;
    const classes = classNames(css.root, className);

    return (
      <fieldset className={classes}>
        {label ? <legend>{label}</legend> : null}
        <ul className={css.options}>
          {options.map((option) => {
            return (
              <li key={`${id}-${option.key}`}>
                <RadioField
                  id={`${id}-${option.key}`}
                  label={option.label}
                  name={fields.name}
                  onChange={onChange}
                  value={option.value}
                />
              </li>
            );
          })}
        </ul>
      </fieldset>
    );
  }
}

RadioGroupComponent.propTypes = {
  className: string,
  id: string.isRequired,
  label: string,
  options: array.isRequired,
};

RadioGroupComponent.defaultProps = {
  label: null,
};

const RadioGroup = (props) => (
  <FieldArray component={RadioGroupComponent} {...props} />
);

export default RadioGroup;
