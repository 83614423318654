import React from "react";
import css from "./Notifications.module.css";
import { connect } from "react-redux";
import { compose } from "redux";
import { array, func } from "prop-types";
import classNames from "classnames";
import { clearNotification } from "../../ducks/notification.duck";

const AUTO_CLEAR_INTERVAL = 5000;

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      intervalTimers: {},
    };

    this.handleCloseNotification = this.handleCloseNotification.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { notifications, onClearNotification } = props;
    const { intervalTimers } = state;

    const notificationIds = Object.keys(notifications);
    if (notificationIds.length === 0) {
      // Garbage collection, clearing interval times once there are no longer any notifications
      return {
        intervalTimers: {},
      };
    } else {
      notificationIds.forEach((key) => {
        const { id } = notifications[key];
        if (!intervalTimers[id]) {
          intervalTimers[id] = setInterval(() => {
            clearInterval(intervalTimers[id]);
            onClearNotification(id);
          }, AUTO_CLEAR_INTERVAL);
        }
      });

      return {
        intervalTimers,
      };
    }
  }

  componentWillUnmount() {
    const { intervalTimers } = this.state;
    Object.keys(intervalTimers).forEach((key) => {
      clearInterval(intervalTimers[key]);
    });
  }

  handleCloseNotification = (id) => {
    const { onClearNotification } = this.props;
    const intervalTimers = { ...this.state.intervalTimers };

    onClearNotification(id);
    delete intervalTimers[id];

    this.setState({
      intervalTimers,
    });
  };

  render() {
    const { notifications } = this.props;
    return (
      <div className={css.root}>
        {notifications.map((notification) => {
          const classes = classNames(css.notification, css[notification.level]);
          return (
            <div className={classes} key={`notification-${notification.id}`}>
              <span className={css.message}>{notification.message}</span>
              <button
                className={css.close}
                onClick={() => this.handleCloseNotification(notification.id)}
                type={"button"}
              >
                x
              </button>
            </div>
          );
        })}
      </div>
    );
  }
}

Notifications.propTypes = {
  notifications: array.isRequired,
  onClearNotification: func.isRequired,
};

Notifications.defaultProps = {
  notifications: [],
};

const mapDispatchToProps = (dispatch) => ({
  onClearNotification: (id) => dispatch(clearNotification(id)),
});

const mapStateToProps = (state) => {
  const { notifications } = state.notification;

  return {
    notifications,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Notifications
);
