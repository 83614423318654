import { SelectValue } from "../../types";

export const perPageOptions = [
  { value: "10", label: "10" },
  { value: "25", label: "25" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];

export const columnsOptions: SelectValue[] = [
  { value: "customer", label: "Booker", isFixed: true },
  { value: "provider", label: "Volunteer", isFixed: true },
  { value: "nonprofit", label: "Nonprofit", isFixed: true },
  { value: "transitionedAt", label: "Last transition" },
  { value: "status", label: "Status" },
  { value: "type", label: "Type" },
  { value: "donation", label: "Donation" },
  { value: "operatorFee", label: "Givsly received" },
  { value: "creditDeduction", label: "Paid credit" },
  { value: "paymentFee", label: "Stripe fees" },
  { value: "cardTotal", label: "Paid card" },
  { value: "total", label: "Paid total" },
];

export const typeOptions = [
  { value: "proposal", label: "Proposal" },
  { value: "request", label: "Request" },
];

export const statusOptions = [
  { value: "initiated", label: "Initiated" },
  { value: "accepted", label: "Accepted" },
  { value: "declined", label: "Declined" },
  { value: "expired", label: "Expired" },
  { value: "cancelled", label: "Cancelled" },
  { value: "delivered", label: "Delivered" },
  { value: "completed", label: "Completed" },
  { value: "closed", label: "Closed" },
];
