import React from "react";
import { Form as FinalForm } from "react-final-form";
import css from "./UserForm.module.css";
import { Button, Form, TextField } from "../../components";
import { withRouter } from "react-router-dom";
import { bool, func, object } from "prop-types";
import * as validators from "../../utils/validator";
import { composeValidators } from "../../utils/validator";

class UserForm extends React.Component {
  render() {
    const { initialValues, submitInProgress, ...rest } = this.props;
    if (initialValues.password) {
      delete initialValues.password;
    }
    return (
      <div className={css.root}>
        <FinalForm
          {...rest}
          initialValues={initialValues}
          render={(fieldRenderProps) => {
            const {
              handleSubmit,
              invalid,
              pristine,
              values,
            } = fieldRenderProps;

            // @todo fix invalid state after submission (edit only)
            const disabled = invalid || pristine || submitInProgress;
            return (
              <Form
                className={css.form}
                onSubmit={(e) => {
                  this.submittedValues = values;
                  return handleSubmit(e);
                }}
              >
                <h2 className={css.header}>
                  {initialValues.id
                    ? `Edit dashboard user '${initialValues.firstName} ${initialValues.lastName}'`
                    : "Create a new dashboard user"}
                </h2>
                <TextField
                  id={"email"}
                  name={"email"}
                  label={"E-mail address"}
                  validate={composeValidators(
                    validators.isRequired("This field is required"),
                    validators.isValidEmail(
                      "This must be a valid e-mail address"
                    )
                  )}
                />
                <TextField
                  id={"firstName"}
                  name={"firstName"}
                  label={"First name"}
                  validate={validators.isRequired("This field is required")}
                />
                <TextField
                  id={"lastName"}
                  name={"lastName"}
                  label={"Last name"}
                  validate={validators.isRequired("This field is required")}
                />
                <TextField
                  id={"password"}
                  name={"password"}
                  label={"Password"}
                  type={"password"}
                  validate={
                    initialValues.id
                      ? null
                      : validators.isRequired("This field is required")
                  }
                />
                <Button disabled={disabled} type={"submit"}>
                  {initialValues.id
                    ? "Save dashboard user"
                    : "Create dashboard user"}
                </Button>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

UserForm.propTypes = {
  initialValues: object,
  onSubmit: func.isRequired,
  submitInProgress: bool,
};

UserForm.defaultProps = {
  initialValues: {},
  submitInProgress: false,
};

export default withRouter(UserForm);
