import React from "react";
import "./App.css";
import { routeConfiguration } from "./routes";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Notifications } from "./components";
import { compose } from "redux";
import { connect } from "react-redux";

const canShowComponent = (props) => {
  const { loggedIn, route } = props;
  const { auth } = route;
  return !auth || loggedIn;
};

const callLoadData = (props) => {
  const { match, location, route, dispatch, logoutInProgress } = props;
  const { loadData, name } = route;

  const shouldLoadData = typeof loadData === "function" && !logoutInProgress;

  if (shouldLoadData) {
    dispatch(loadData(match.params, location.search))
      .then(() => {
        // eslint-disable-next-line no-console
        console.log(`loadData success for ${name} route`);
      })
      .catch((e) => {
        console.error(e, "load-data-failed", { routeName: name });
      });
  }
};

class RouteComponentRenderer extends React.Component {
  componentDidMount() {
    callLoadData(this.props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.key !== this.props.location.key) {
      callLoadData(this.props);
    }
  }

  render() {
    const { authInfoLoaded, route, match, location } = this.props;
    const { component: RouteComponent } = route;
    const canShow = canShowComponent(this.props);
    return authInfoLoaded ? (
      canShow ? (
        <RouteComponent
          params={match.params}
          routeName={route.name}
          location={location}
          authInfo={this.props.authInfo}
        />
      ) : (
        <Redirect to={"/"} />
      )
    ) : null;
  }
}

class App extends React.PureComponent {
  render() {
    const {
      authInfo,
      authInfoInProgress,
      authInfoLoaded,
      dispatch,
      loggedIn,
      loginInProgress,
    } = this.props;
    return (
      <BrowserRouter>
        <Switch>
          {routeConfiguration.map((route) => {
            return (
              <Route
                key={route.name}
                path={route.path}
                render={(renderProps) => (
                  <>
                    <Notifications />
                    <RouteComponentRenderer
                      dispatch={dispatch}
                      route={route}
                      loggedIn={loggedIn}
                      loginInProgress={loginInProgress}
                      authInfo={authInfo}
                      authInfoInProgress={authInfoInProgress}
                      authInfoLoaded={authInfoLoaded}
                      {...renderProps}
                    />
                  </>
                )}
              />
            );
          })}
          <Route></Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    authInfo,
    authInfoInProgress,
    authInfoLoaded,
    loggedIn,
    loginInProgress,
  } = state.auth;

  return {
    loggedIn,
    authInfo,
    authInfoInProgress,
    authInfoLoaded,
    loginInProgress,
  };
};

export default compose(connect(mapStateToProps))(App);
