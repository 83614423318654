import React, { FC } from "react";
import { useParams, withRouter } from "react-router";
import { MainContent, NamedLink, Sidebar } from "../../components";
import TenantDetail from "./subscribers/TenantDetail";
import TenantList from "./subscribers/TenantList";
import css from "./TenantManagerPage.module.css";
import { NonprofitList } from "./nonprofits/NonprofitList";
import { NonprofitDetail } from "./nonprofits/NpoDetail";
import { getAuthInfo } from "./shared/utils";
import { AuthInfoProvider } from "./shared/AuthInfoProvider";

const TabNav: FC = () => {
  const params = useParams<any>();
  const tab = params?.tab;

  return (
    <div className={css["tabnav"]}>
      <NamedLink
        name="TenantManagerPage"
        pathParams={{ tab: "subscribers" }}
        className={tab === "subscribers" ? css["active-tab"] : ""}
      >
        Subscribers
      </NamedLink>
      <NamedLink
        name="TenantManagerPage"
        pathParams={{ tab: "nonprofits" }}
        className={tab === "nonprofits" ? css["active-tab"] : ""}
      >
        Nonprofits
      </NamedLink>
    </div>
  );
};
interface iViewSwitch {
  tab: "nonprofits" | "subscribers";
  id?: string;
}

const ViewSwitch: FC<iViewSwitch> = ({ tab, id }) => {
  if (id) {
    if (tab === "subscribers") {
      return (
        <>
          <div className={css.row} style={{ justifyContent: "space-between" }}>
            <h3>Manage {id} Subscriptions</h3>
            <NamedLink name="TenantManagerPage" pathParams={{ id: "", tab }}>
              Back to all tenants
            </NamedLink>
          </div>
          <TenantDetail tenant_id={id} />
        </>
      );
    }

    if (tab === "nonprofits") {
      return (
        <>
          <div className={css.row} style={{ justifyContent: "space-between" }}>
            <h3>Manage {id} Nonprofits</h3>
            <NamedLink name="TenantManagerPage" pathParams={{ id: "", tab }}>
              Back to all nonprofits
            </NamedLink>
          </div>
          <NonprofitDetail npoId={id} />
        </>
      );
    }
  }

  return (
    <>
      <div className={css.row} style={{ justifyContent: "space-between" }}>
        <h3>Manage Givsly Subscriptions and Nonprofits</h3>
      </div>
      <TabNav />
      {tab === "subscribers" && <TenantList />}
      {tab === "nonprofits" && <NonprofitList />}
    </>
  );
};

const TenantManagerPage: FC = (props: unknown) => {
  const params = useParams<any>();
  const tenant_id = params?.id || "";
  const tab = params?.tab || "subscribers";
  const authInfo = getAuthInfo(props);

  return (
    <div className={css.root}>
      <Sidebar />
      <MainContent>
        <AuthInfoProvider value={authInfo}>
          <ViewSwitch id={tenant_id} tab={tab} />
        </AuthInfoProvider>
      </MainContent>
    </div>
  );
};

export default withRouter(TenantManagerPage);
