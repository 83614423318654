import {
  iCreateUserPayload,
  iCreateUserResponse,
} from "@givsly/aws-tenant-manager/lib/types";
import React, { FC, useCallback, useState } from "react";
import Dropzone from "react-dropzone";
import { createTenantUser } from "../api";
import Table from "../shared/Table";
import css from "../TenantManagerPage.module.css";

interface iTenantUserCsvDropZone {
  tenant_id: string;
  onUploadSuccess: () => void;
  isGA?: boolean;
  isGM?: boolean;
}

function readFileToCSV(file: File): Promise<string[][]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      const data =
        typeof reader.result === "string"
          ? reader.result
              .split("\n")
              .map((line) => line.split(",").map((slice) => slice.trim()))
          : [];
      resolve(data);
    };
    reader.onerror = (reason) => {
      reject(reason);
    };
  });
}

interface iCreateUserRejection {
  email: string;
  reason: string;
}

const TenantUserCsvDropZone: FC<iTenantUserCsvDropZone> = ({
  tenant_id,
  onUploadSuccess,
  isGA = false,
  isGM = false,
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState<Error>();
  const [rejectedUploads, setRejectedUploads] = useState<
    Partial<iCreateUserRejection[]>
  >([]);

  const handleAcceptedFiles = useCallback(
    async (acceptedFiles: File[]) => {
      setUploading(true);
      setUploadError(undefined);
      setRejectedUploads([]);
      try {
        for (const file of acceptedFiles) {
          const result = await readFileToCSV(file);
          const [keys, ...values] = result;
          const createUserPayloads: Partial<iCreateUserPayload>[] = values.map(
            (value) => {
              let payload: Partial<iCreateUserPayload> = { tenant_id };
              keys.forEach((key, index) => {
                switch (key) {
                  case "email": {
                    payload.email = value[index];
                    break;
                  }
                  case "is_sub_ga": {
                    const bool_string = value[index].toLowerCase();
                    const is_sub_ga =
                      !!bool_string && (JSON.parse(bool_string) as boolean);
                    if (is_sub_ga && !isGA) {
                      console.warn(
                        `Cannot set user ${payload.email} to GA, tenant ${tenant_id} is not GA capable`
                      );
                    }
                    payload[key] = isGA ? is_sub_ga : false;
                    break;
                  }
                  case "is_sub_gm": {
                    const bool_string = value[index].toLowerCase();
                    const is_sub_gm =
                      !!bool_string && (JSON.parse(bool_string) as boolean);
                    if (is_sub_gm && !isGM) {
                      console.warn(
                        `Cannot set user ${payload.email} to GM, tenant ${tenant_id} is not GM capable`
                      );
                    }
                    break;
                  }
                  case "is_sales":
                  case "is_ops":
                  case "email_verified":
                  case "send_invite": {
                    const bool_string = value[index].toLowerCase();
                    payload[key] =
                      !!bool_string && (JSON.parse(bool_string) as boolean);
                    break;
                  }
                  default:
                    break;
                }
              });

              return payload;
            }
          );

          const results = await Promise.allSettled(
            createUserPayloads.map(async (payload) => {
              try {
                const result = await createTenantUser(payload);
                if ("error" in result) {
                  return Promise.reject({
                    email: payload.email,
                    reason: result["error"],
                  });
                }
                return result;
              } catch (error) {
                console.warn(error);
                return Promise.reject({ email: payload.email, reason: error });
              }
            })
          );

          const _reducerDefault: {
            fulfilled: Partial<iCreateUserResponse[]>;
            rejected: Partial<iCreateUserRejection[]>;
          } = { fulfilled: [], rejected: [] };
          const { fulfilled, rejected } = results.reduce((acc, curr) => {
            switch (curr.status) {
              case "fulfilled": {
                return {
                  ...acc,
                  fulfilled: [...acc.fulfilled, curr.value],
                };
              }
              case "rejected": {
                return {
                  ...acc,
                  rejected: [...acc.rejected, curr.reason],
                };
              }
              default: {
                return acc;
              }
            }
          }, _reducerDefault);
          if (rejected.length > 0) {
            setRejectedUploads(rejected);
          }

          if (fulfilled.length > 0 && typeof onUploadSuccess === "function") {
            onUploadSuccess();
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          setUploadError(err);
        }
      }
      setUploading(false);
    },
    [isGA, isGM, onUploadSuccess, tenant_id]
  );
  return (
    <>
      {rejectedUploads.length > 0 && (
        <>
          <Table
            headers={["email", "reason"]}
            className={css["error-table"]}
            caption="Rejected uploads"
          >
            {rejectedUploads.map((payload) => (
              <tr key={payload?.email}>
                <td>{payload?.email}</td>
                <td>{payload?.reason}</td>
              </tr>
            ))}
          </Table>
        </>
      )}
      <Dropzone
        onDrop={handleAcceptedFiles}
        accept="text/csv"
        disabled={uploading}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className={css.dropzone}>
                {!uploading && !uploadError && (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
                {uploading && <p>Uploading in progress...</p>}
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </>
  );
};

export default TenantUserCsvDropZone;
