import React, { FC } from "react";

interface iTenantAdminForm {
  onAfterSubmit?: () => void;
}

export const TenantAdminForm: FC<iTenantAdminForm> = ({ onAfterSubmit }) => {
  return (
    <>
      <h1>Tenant Admin Form</h1>
    </>
  );
};
