import React, { FC } from "react";
import { Field } from "react-final-form";
import { Badge } from "../Badge";
import css from "./FormCheckboxField.module.css";

interface iFormCheckboxField {
  name: string;
  label: string;
  st?: boolean;
  hint?: string;
  showValuesAsImage?: boolean;
  readOnly?: boolean;
}

export const FormCheckboxField: FC<iFormCheckboxField> = ({
  name,
  label,
  st = false,
  hint,
  readOnly = false,
}) => (
  <Field
    name={name}
    type="checkbox"
    render={({ input, meta }) => (
      <label className={css["label"]}>
        <input {...input} readOnly={readOnly} className={css["input"]} />
        <div>
          {label} {st && <Badge>ST</Badge>}
          {hint && (
            <>
              <br />
              <small>{hint}</small>
            </>
          )}
        </div>
        {meta.error && meta.touched && (
          <span style={{ color: "var(--failColor)" }}>{meta.error}</span>
        )}
      </label>
    )}
  />
);
