import React from "react";
import { Field } from "react-final-form";
import { func, string } from "prop-types";
import css from "./RadioField.module.css";
import classNames from "classnames";

class RadioField extends React.Component {
  render() {
    const {
      className,
      id,
      input,
      label,
      meta,
      onChange,
      type,
      ...rest
    } = this.props;

    const classes = classNames(css.root, className);

    const radioButtonProps = {
      id,
      className: css.radioButton,
      type: "radio",
      ...rest,
    };

    const handleChange = (propsOnChange, inputOnChange) => (event) => {
      console.log("Changing!");
      if (propsOnChange) {
        const value = event.nativeEvent.target.value;
        propsOnChange(value);

        console.log("Value", value);
      }

      inputOnChange(event);
    };

    return (
      <Field
        className={classes}
        {...radioButtonProps}
        type="radio"
        render={(fieldProps) => {
          const {
            onChange: inputOnChange,
            ...restInputProps
          } = fieldProps.input;
          return (
            <>
              <input
                {...restInputProps}
                {...radioButtonProps}
                onChange={handleChange(onChange, inputOnChange)}
              />
              <label htmlFor={id}>{label}</label>
            </>
          );
        }}
      />
    );
  }
}

RadioField.propTypes = {
  className: string,
  id: string.isRequired,
  label: string,
  onChange: func.isRequired,
};

RadioField.defaultProps = {
  className: null,
  label: null,
};

export default RadioField;
