import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { auth, member, nonprofit, notification, user } from "./ducks/";
import thunkMiddleware from "redux-thunk";
import { authInfo } from "./ducks/auth.duck";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { TOKEN_KEY } from "./utils/sessionStorage";

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_END_POINT,
  cache: new InMemoryCache(),
  headers: {
    authorization: `Bearer ${sessionStorage.getItem(TOKEN_KEY)}`,
  },
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const store = createStore(
  combineReducers({
    auth,
    member,
    nonprofit,
    notification,
    user,
  }),
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

const { authInfoLoaded } = store.getState().auth;
const info = authInfoLoaded ? Promise.resolve({}) : store.dispatch(authInfo());
info
  .then(() => {
    ReactDOM.render(
      <ApolloProvider client={client}>
        <Provider store={store}>
          <App />
        </Provider>
      </ApolloProvider>,
      document.getElementById("root")
    );
  })
  .catch((e) => {
    console.log("An error occurred", e);
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
