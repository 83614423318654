import React from "react";
import { object, string } from "prop-types";
import { withRouter } from "react-router-dom";
import { createUrlForRoute } from "../../utils/urlHelper";

class NamedLink extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick = (e) => {
    e.preventDefault();
    const { history, name, params, pathParams } = this.props;
    history.push(createUrlForRoute(name, pathParams, params));
  };

  render() {
    const { children, className, name, params, pathParams } = this.props;
    const url = createUrlForRoute(name, pathParams, params);
    return url ? (
      <a href={url} className={className} onClick={this.handleOnClick}>
        {children}
      </a>
    ) : null;
  }
}

NamedLink.propTypes = {
  className: string,
  name: string.isRequired,
  params: object,
  pathParams: object,
};

NamedLink.defaultProps = {
  className: null,
  params: {},
  pathParams: {},
};

export default withRouter(NamedLink);
