import React from "react";
import css from "./ActionButton.module.css";
import { func, object, shape, string } from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router";
import { createUrlForRoute } from "../../utils/urlHelper";

class ActionButton extends React.Component {
  handleOnClick = () => {
    const { history, name, onClick, params, pathParams } = this.props;
    if (name) {
      const url = createUrlForRoute(name, pathParams, params);
      history.push(url);
    } else if (onClick) {
      onClick();
    } else {
      console.log("No action specified");
    }
  };

  render() {
    const {
      children,
      className,
      onClick,
      name,
      params,
      pathParams,
      staticContext,
      ...rest
    } = this.props;
    const classes = classNames(css.root, className);
    return (
      <button className={classes} onClick={this.handleOnClick} {...rest}>
        {children}
      </button>
    );
  }
}

ActionButton.propTypes = {
  className: string,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  name: string,
  onClick: func,
  params: object,
  pathParams: object,
  type: string,
};

ActionButton.defaultProps = {
  className: null,
  href: "",
  onClick: null,
  type: "button",
};

export default withRouter(ActionButton);
