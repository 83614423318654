import React, { FC, useCallback, useEffect } from "react";
import { useForm, useFormState } from "react-final-form";
import { searchDafs } from "../api";
import { useAsyncCallback } from "../shared/useAsyncCallback";
import { FormSelectField } from "../shared/FormSelectField";
import { iDAFNonprofit } from "@givsly/aws-tenant-manager/lib/types";

function extractDomain(address: string): string {
    address = address.trim().toLowerCase();
  
    if (!address) {
      return "";
    }
  
    if (!address.includes('://')) {
      address = `http://${address}`;
    }
  
    try {
      const parsedURL = new URL(address);
      const hostname = parsedURL.hostname;
  
      // Regular expression to extract the main domain
      const re = /(?:\w+\.)?(\w+\.\w+)$/;
      const matches = hostname.match(re);
  
      return matches && matches.length > 1 ? matches[1] : hostname;
    } catch (err) {
      console.error('Error parsing URL:', err);
      return "";
    }
}

const DafSelect: FC = () => {
  const form = useForm();
  const formState = useFormState({
    subscription: { values: true },
  });

  const tax_id = formState.values?.tax_id?.trim();
  const country = formState.values?.country?.trim();

  const searchDafsFunction = useCallback(async () => {
    if (!tax_id || !country) {
      return { nonprofits: [] };
    }
    return searchDafs({ tax_id, country });
  }, [tax_id, country]);

  const { data, retry } = useAsyncCallback(searchDafsFunction);

  useEffect(() => {
    if (tax_id && country) {
      retry();
    }
  }, [tax_id, country, retry]);

  // Define options based on the number of nonprofits
  let options = [];
  if (data && data.nonprofits && data.nonprofits.length > 0) {
    options = [
      { value: "", label: `Choose a nonprofit - found ${data.nonprofits.length}` },
      ...data.nonprofits.map(npo => ({
        value: npo.daf_id,
        label: npo.name || npo.website || npo.daf_id,
      })),
    ];
  } else {
    if (tax_id) {
      options = [{ value: "", label: `Nonprofit with EIN ${tax_id} not found` }];
    } else {
      options = [{ value: "", label: `Start by entering an EIN to start searching...` }];
    }
  }

  return (
    <FormSelectField
      disabled={data?.nonprofits?.length === 0}
      name="daf_id"
      label="Select matching nonprofit from GetChange"
      options={options}
      onChange={(value) => {
        console.log(`SELECTED ${value}`);
        const daf_response = data?.nonprofits?.find((npo: iDAFNonprofit) => npo.daf_id === value);

        if (!daf_response) {
          return;
        }

        const domain = extractDomain(daf_response.website);

        // Populate the FORM fields with the NPO selection
        form.change("npo_id", domain);
        form.change("npo_name", daf_response.name);
        form.change("category", daf_response.category);
        form.change("website", daf_response.website);
        form.change("daf_response", daf_response);
      }}
    />
  );
};

export default DafSelect;
