import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  FlexUserLink,
  MainContent,
  Money,
  Pagination,
  Sidebar,
  TransactionStatus,
} from "../../components";
import css from "./BookingBoardPage.module.css";
import classNames from "classnames";
import { parse } from "../../utils/urlHelper";
import { gql, useQuery } from "@apollo/client";
import { Marketplace, OrderDirection } from "../../constants";
import qs from "qs";
import Select, { ActionMeta } from "react-select";
import {
  columnsOptions,
  perPageOptions,
  statusOptions,
  typeOptions,
} from "./constants";
import { statusColor } from "../../components/TransactionStatus/TransactionStatus";
import { OptionTypeBase, ValueType } from "react-select/src/types";
import {
  SelectValue,
  TransactionsFilters,
  TransactionsOptions,
  TransactionsOrderings,
} from "../../types";

type BookingBoardQueryParams = {
  page?: number;
  perPage?: number;
};

// GraphQL transactions query
const QUERY_TRANSACTIONS = gql`
  query TransactionsQuery(
    $page: Int!
    $perPage: Int!
    $options: TransactionsOptionsInput
  ) {
    transactions(page: $page, perPage: $perPage, options: $options) {
      transactions {
        id
        bookingUuid
        paymentUuid
        donation
        operatorFee
        paymentFee
        creditDeduction
        cardTotal
        paidTotal
        transitionedAt
        status
        bookingType
        customerMember {
          id
          uuid
          listingUuid
          firstName
          lastName
          companyName
        }
        providerMember {
          id
          uuid
          listingUuid
          firstName
          lastName
          companyName
        }
        nonprofit {
          id
          uuid
          name
        }
        transitions {
          transition
          transitionedAt
        }
      }
      meta {
        page
        perPage
        pageTotal
        itemTotal
      }
    }
  }
`;

const initialOptions: TransactionsOptions = {
  filters: {
    company: "",
    keywords: "",
    meetingMethod: new Array<string>(),
    nonprofit: null,
    type: new Array<string>(),
    status: new Array<string>(),
  },
};

type SortableTableHeaderProps = {
  currentDirection?: OrderDirection;
  isLocked?: boolean;
  children: React.ReactNode;
  column: keyof TransactionsOrderings;
  onSort: (
    name: keyof TransactionsOrderings,
    direction: OrderDirection
  ) => void;
  visibleColumns: OptionTypeBase | null;
};

const SortableTableHeader = (props: SortableTableHeaderProps) => {
  const isVisible = props.visibleColumns
    ?.map((c: SelectValue) => c.value)
    .includes(props.column);
  return isVisible ? (
    <th
      className={
        props.currentDirection
          ? css[props.currentDirection.toLowerCase()]
          : undefined
      }
      onClick={() =>
        props.onSort(
          props.column,
          props.currentDirection || OrderDirection.NONE
        )
      }
    >
      {props.children}
    </th>
  ) : null;
};

type ColumnProps = {
  children: React.ReactNode;
  className?: string | undefined;
  column: string;
  visibleColumns: OptionTypeBase | null;
};

const Column = ({
  children,
  className = undefined,
  column,
  visibleColumns,
}: ColumnProps) => {
  const isVisible = visibleColumns
    ?.map((c: SelectValue) => c.value)
    .includes(column);
  return isVisible ? <td className={className}>{children}</td> : null;
};

/**
 * Sanitizes query options for injection into the query string. This removes any blank filters and orderings.
 */
const sanitizeOptions = (options: TransactionsOptions): TransactionsOptions => {
  if (options?.filters) {
    let kf: keyof TransactionsFilters;
    for (kf in options.filters) {
      if (options.filters.hasOwnProperty(kf) && !options.filters[kf]) {
        delete options.filters[kf];
      }
    }
  }

  if (options?.orderings) {
    let ko: keyof TransactionsOrderings;
    for (ko in options.orderings) {
      if (options.orderings.hasOwnProperty(ko) && !options.orderings[ko]) {
        delete options.orderings[ko];
      }
    }
  }

  return options;
};

const nextDirection = (currentDirection: OrderDirection) => {
  switch (currentDirection) {
    default:
      return OrderDirection.ASC;
    case OrderDirection.ASC:
      return OrderDirection.DESC;
    case OrderDirection.DESC:
      return OrderDirection.NONE;
  }
};

const BookingBoardPage = () => {
  const { pathname, search } = useLocation();
  // @todo Enable dynamic marketplace
  const marketplace = Marketplace.production;
  const history = useHistory();
  const [options, setOptions] = useState(initialOptions);
  const { page = 1, perPage: qPerPage = 10 }: BookingBoardQueryParams =
    parse(search);
  const [columns, setColumns] = useState<OptionTypeBase | null>(columnsOptions);
  const [perPage, setPerPage] = useState<number>(qPerPage);

  const handleSort = (
    column: keyof TransactionsOrderings,
    direction: OrderDirection
  ) => {
    const newOptions = { ...options };
    if (!newOptions?.orderings) {
      newOptions.orderings = {};
    }

    newOptions.orderings[column] = nextDirection(direction);
    setOptions(newOptions);
  };

  const handleFilter = (
    filter: keyof TransactionsFilters,
    value: string | number | Array<string | number> | undefined
  ) => {
    const newOptions = { ...options };
    if (!newOptions?.filters) {
      newOptions.filters = {};
    }

    // @ts-ignore
    newOptions.filters[filter] = value;
    setOptions(newOptions);
  };

  const handleChangeColumns = (
    value: ValueType<OptionTypeBase, boolean>,
    actionMeta: ActionMeta<OptionTypeBase>
  ) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          console.log("Should return without setting");
          return;
        }
        break;
      case "clear":
        value = columnsOptions.filter((v) => v.isFixed);
        break;
    }

    value = value
      ?.filter((v: SelectValue) => v.isFixed)
      .concat(value.filter((v: SelectValue) => !v.isFixed));
    setColumns(value);
  };

  // Query GraphQL and unpack the results
  const { loading, error, data, refetch } = useQuery(QUERY_TRANSACTIONS, {
    variables: {
      page,
      perPage,
      options,
    },
  });
  const { itemTotal = 0, pageTotal = 0 } = data?.transactions?.meta || {};

  // Update the results when filters are applied
  useEffect(() => {
    // @todo Reset paging to 1 if filters are changed
    // Update query parameters
    const sanitizedOptions = sanitizeOptions(options);
    history.push(
      `${pathname}?${qs.stringify({
        page,
        perPage,
        filters: sanitizedOptions.filters,
        orderings: sanitizedOptions.orderings,
      })}`
    );

    // Refetch data from GraphQL
    refetch({
      page,
      perPage,
      options,
    })?.catch(console.error);
  }, [options, page, perPage, refetch, history, pathname]);

  const header = (
    <tr>
      <SortableTableHeader
        column="customer"
        currentDirection={options?.orderings?.customer}
        onSort={handleSort}
        visibleColumns={columns}
      >
        Booker
      </SortableTableHeader>
      <SortableTableHeader
        column="provider"
        currentDirection={options?.orderings?.provider}
        onSort={handleSort}
        visibleColumns={columns}
      >
        Volunteer
      </SortableTableHeader>
      <SortableTableHeader
        column="nonprofit"
        currentDirection={options?.orderings?.nonprofit}
        onSort={handleSort}
        visibleColumns={columns}
      >
        Nonprofit
      </SortableTableHeader>
      <SortableTableHeader
        column="transitionedAt"
        currentDirection={options?.orderings?.transitionedAt}
        onSort={handleSort}
        visibleColumns={columns}
      >
        Last transition
      </SortableTableHeader>
      <SortableTableHeader
        column="status"
        currentDirection={options?.orderings?.status}
        onSort={handleSort}
        visibleColumns={columns}
      >
        Status
      </SortableTableHeader>
      <SortableTableHeader
        column="type"
        currentDirection={options?.orderings?.type}
        onSort={handleSort}
        visibleColumns={columns}
      >
        Type
      </SortableTableHeader>
      <SortableTableHeader
        column="donation"
        currentDirection={options?.orderings?.donation}
        onSort={handleSort}
        visibleColumns={columns}
      >
        Donation
      </SortableTableHeader>
      <SortableTableHeader
        column="operatorFee"
        currentDirection={options?.orderings?.operatorFee}
        onSort={handleSort}
        visibleColumns={columns}
      >
        Givsly received
      </SortableTableHeader>
      <SortableTableHeader
        column="creditDeduction"
        currentDirection={options?.orderings?.creditDeduction}
        onSort={handleSort}
        visibleColumns={columns}
      >
        Paid credit
      </SortableTableHeader>
      <SortableTableHeader
        column="paymentFee"
        currentDirection={options?.orderings?.paymentFee}
        onSort={handleSort}
        visibleColumns={columns}
      >
        Stripe fees
      </SortableTableHeader>
      <SortableTableHeader
        column="cardTotal"
        currentDirection={options?.orderings?.cardTotal}
        onSort={handleSort}
        visibleColumns={columns}
      >
        Paid card
      </SortableTableHeader>
      <SortableTableHeader
        column="total"
        currentDirection={options?.orderings?.total}
        onSort={handleSort}
        visibleColumns={columns}
      >
        Paid total
      </SortableTableHeader>
    </tr>
  );

  return (
    <div className={css.root}>
      <Sidebar />
      <MainContent className={css.mainContent}>
        <h1>Booking board</h1>
        {error ? (
          <div className={css.error}>
            An error occurred while attempting to load the data:
            <code>{error?.message}</code>
          </div>
        ) : null}
        <div className={css.controls}>
          <div className={classNames(css.controlOpen, css.settings)}>
            <h2>Settings</h2>
            <div className={css.setting}>
              <label htmlFor="settingPerPage">Results per page</label>
              <Select
                defaultValue={{
                  value: perPage.toString(),
                  label: perPage.toString(),
                }}
                id="settingsPerPage"
                options={perPageOptions}
                onChange={(value, meta) =>
                  setPerPage(parseInt(value?.value || "25"))
                }
              />
            </div>
            <div className={classNames(css.setting, css.fullWidth)}>
              <label htmlFor="settingColumns">Columns</label>
              <Select
                defaultValue={columns}
                isMulti={true}
                options={columnsOptions}
                onChange={handleChangeColumns}
                styles={{
                  multiValueLabel: (base, state) => ({
                    background: state.data?.isFixed ? "darkgrey" : "unset",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "row",
                    padding: "0.5rem",
                  }),
                  multiValueRemove: (base, state) => ({
                    alignSelf: "center",
                    cursor: "pointer",
                    display: state.data?.isFixed ? "none" : "inherit",
                    paddingRight: "0.5rem",
                  }),
                }}
              />
            </div>
          </div>
          <div className={classNames(css.controlOpen, css.filters)}>
            <h2 className={css.filtersHeadline}>Filters</h2>
            <div className={css.filter}>
              <label htmlFor="filterType">Type</label>
              <Select
                name="type"
                options={typeOptions}
                isMulti={true}
                onChange={(value, meta) =>
                  handleFilter(
                    "type",
                    value.map((el) => el.value)
                  )
                }
              />
            </div>
            <div className={css.filter}>
              <label htmlFor="filterType">Status</label>
              <Select
                options={statusOptions}
                isMulti={true}
                onChange={(value, meta) =>
                  handleFilter(
                    "status",
                    value.map((el) => el.value)
                  )
                }
                styles={{
                  multiValueLabel: (provided, state) => {
                    return {
                      background: statusColor(state.data.value),
                      borderRadius: "4px",
                      color: "white",
                      fontSize: "0.8rem",
                      padding: "0.25rem",
                      textTransform: "uppercase",
                    };
                  },
                }}
              />
            </div>
          </div>
        </div>
        <Pagination
          params={{
            perPage,
          }}
          pageName={"BookingBoardPage"}
          currentPage={page}
          perPage={perPage}
          totalPages={pageTotal}
          totalItems={itemTotal}
        />
        {itemTotal > 0 ? (
          <h2>
            Showing results <strong>{(page - 1) * perPage + 1}</strong> -
            <strong>{Math.min(page * perPage, itemTotal)}</strong> of{" "}
            <strong>{itemTotal}</strong>
          </h2>
        ) : (
          <h2>There were no results for your query</h2>
        )}
        <table>
          <thead>{header}</thead>
          <tbody>
            {!loading && !error ? (
              data?.transactions?.transactions?.map((t: any) => {
                const { customerMember, providerMember, nonprofit } = t;

                return (
                  <tr key={`tx${t.id}`}>
                    <Column column="customer" visibleColumns={columns}>
                      <FlexUserLink
                        marketplace={marketplace}
                        uuid={customerMember?.uuid}
                      >
                        {customerMember?.firstName} {customerMember?.lastName}
                      </FlexUserLink>
                      <span className={css.companyName}>
                        {customerMember?.companyName}
                      </span>
                    </Column>
                    <Column column="provider" visibleColumns={columns}>
                      <FlexUserLink
                        marketplace={marketplace}
                        uuid={providerMember?.uuid}
                      >
                        {providerMember?.firstName} {providerMember?.lastName}
                      </FlexUserLink>
                      <span className={css.companyName}>
                        {providerMember?.companyName}
                      </span>
                    </Column>
                    <Column column="nonprofit" visibleColumns={columns}>
                      <FlexUserLink
                        marketplace={marketplace}
                        uuid={nonprofit?.uuid}
                      >
                        {nonprofit?.name}
                      </FlexUserLink>
                    </Column>
                    <Column column="transitionedAt" visibleColumns={columns}>
                      {new Date(t?.transitionedAt)?.toLocaleString()}
                    </Column>
                    <Column column="status" visibleColumns={columns}>
                      <TransactionStatus status={t?.status} />
                    </Column>
                    <Column column="type" visibleColumns={columns}>
                      {t?.bookingType}
                    </Column>
                    <Column
                      column="donation"
                      visibleColumns={columns}
                      className={css.money}
                    >
                      <Money>{t?.donation}</Money>
                    </Column>
                    <Column
                      column="operatorFee"
                      visibleColumns={columns}
                      className={css.money}
                    >
                      <Money>{t?.operatorFee}</Money>
                    </Column>
                    <Column
                      column="creditDeduction"
                      visibleColumns={columns}
                      className={css.money}
                    >
                      <Money>{t?.creditDeduction}</Money>
                    </Column>
                    <Column
                      column="paymentFee"
                      visibleColumns={columns}
                      className={css.money}
                    >
                      <Money>{t?.paymentFee}</Money>
                    </Column>
                    <Column
                      column="cardTotal"
                      visibleColumns={columns}
                      className={css.money}
                    >
                      <Money>{t?.cardTotal}</Money>
                    </Column>
                    <Column
                      column="total"
                      visibleColumns={columns}
                      className={css.money}
                    >
                      <Money>{t?.paidTotal}</Money>
                    </Column>
                  </tr>
                );
              })
            ) : error ? (
              <tr>
                <td colSpan={11}>Failed to load data</td>
              </tr>
            ) : (
              Array(perPage).map((v, i) => (
                <tr key={`loading-skeleton-${i}`}>
                  <td colSpan={11} className={css.loading} />
                </tr>
              ))
            )}
          </tbody>
          <tfoot>{header}</tfoot>
        </table>
        <Pagination
          params={{
            perPage,
          }}
          pageName={"BookingBoardPage"}
          currentPage={page}
          perPage={perPage}
          totalPages={pageTotal}
          totalItems={itemTotal}
        />
      </MainContent>
    </div>
  );
};

export default BookingBoardPage;
