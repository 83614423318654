import React from "react";

type MoneyProps = {
  className?: string;
  children: number;
  currency?: string;
};

/**
 * Formats raw money input to something human readable. This expect the input to be raw, in cents.
 *
 * @param props
 * @constructor
 */
const Money = (props: MoneyProps) => {
  const { children, className } = props;
  return (
    <span className={className}>${((children || 0) / 100).toFixed(2)}</span>
  );
};

export default Money;
