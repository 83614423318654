import React from "react";
import { Field } from "react-final-form";
import { string } from "prop-types";
import css from "./TextField.module.css";
import classNames from "classnames";

class TextFieldComponent extends React.Component {
  render() {
    const { id, input, label, meta, type, ...rest } = this.props;

    const { valid, touched, error } = meta;
    const { value: defaultValue, ...inputWithoutValue } = input;

    const inputProps = {
      id,
      type,
      defaultValue,
      ...inputWithoutValue,
      ...rest,
    };

    const hasError = meta.error && meta.touched;
    const hasSuccess = (touched && error) || valid;
    const classes = classNames(
      css.root,
      hasSuccess ? css.success : null,
      hasError ? css.error : null
    );

    return (
      <div className={classes}>
        {label && <label htmlFor={id}>{label}</label>}
        <input id={id} type={type} {...inputProps} />
        {hasError && <span className={css.message}>{meta.error}</span>}
      </div>
    );
  }
}

TextFieldComponent.propTypes = {
  id: string.isRequired,
  label: string,
  type: string,
};

TextFieldComponent.defaultProps = {
  label: null,
  type: "text",
};

class TextField extends React.Component {
  render() {
    return <Field component={TextFieldComponent} {...this.props} />;
  }
}

export default TextField;
