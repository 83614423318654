import React from "react";
import { MainContent, Sidebar } from "../../components";
import { InvitationForm } from "../../forms";

class InvitationsPage extends React.Component {
  render() {
    return (
      <>
        <Sidebar />
        <MainContent>
          <InvitationForm />
        </MainContent>
      </>
    );
  }
}

export default InvitationsPage;
