import React from "react";
import {
  Filters,
  KeywordFilter,
  MainContent,
  MemberTable,
  Pagination,
  Sidebar,
} from "../../components";
import {
  fetchMember,
  queryMembers,
  updateMember,
} from "../../ducks/member.duck";
import { compose } from "redux";
import { connect } from "react-redux";
import css from "./MembersPage.module.css";
import { array, bool, func, number, shape, string } from "prop-types";
import { createUrlForRoute, parse } from "../../utils/urlHelper";
import debounce from "lodash/debounce";
import { withRouter } from "react-router";
import { MemberForm } from "../../forms";
import {
  clearQueryResults,
  fetchNonprofit,
  queryNonprofits,
} from "../../ducks/nonprofit.duck";
import { LEVEL_INFO, LEVEL_SUCCESS } from "../../utils/notificationHelper";
import { addNotification } from "../../ducks/notification.duck";

const ALLOWED_EVENT_ROLES = ["aw2020_speaker", "aw2020_attendee", "aw2020_vip"]; // TODO: Read these from event configuration, when event config has been moved to api

const DEBOUNCE_WAIT_TIME = 600;

const debouncedSearch = debounce(
  (onSearch, queryParams) => {
    onSearch(queryParams);
  },
  DEBOUNCE_WAIT_TIME,
  { leading: false, trailing: true }
);

class MembersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: "",
      cachedNonprofits: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { cachedNonprofits } = state;
    const { cacheableNonprofit } = props;

    if (cacheableNonprofit && !cachedNonprofits[cacheableNonprofit.id.uuid]) {
      cachedNonprofits[cacheableNonprofit.id.uuid] = cacheableNonprofit;
    }

    return {
      cachedNonprofits,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = parse(location.search);
    if (queryParams.keywords !== this.state.keywords) {
      this.setState({
        keywords: queryParams.keywords || "",
      });
    }
  }

  handleAttachNonprofit = (nonprofit) => {
    if (!this.state.cachedNonprofits[nonprofit.id.uuid]) {
      const cachedNonprofits = { ...this.state.cachedNonprofits };
      cachedNonprofits[nonprofit.id.uuid] = nonprofit;
      this.setState({
        cachedNonprofits,
      });
    }
  };

  handleChangeKeyword = ({ target }) => {
    const { history, location, onSearch } = this.props;
    const queryParams = parse(location.search);
    const { value } = target;
    if (value.length > 2 || value.length === 0) {
      debouncedSearch.cancel();
      this.setState({
        keywords: value,
      });
      queryParams.keywords = value;
      debouncedSearch(onSearch, queryParams);
      history.push(createUrlForRoute("MembersPage", {}, queryParams));
    }
  };

  handleUpdateMember = (submittedValues) => {
    const { onAddNotification, onUpdate, params } = this.props;
    const { id } = params;
    const { isListingVisible, ...publicData } = submittedValues;

    const isListingVisibleBoolean =
      isListingVisible === "false" ? false : !!isListingVisible;

    const updatedValues = {
      publicData: { ...publicData },
      metadata: {
        isListingVisible: isListingVisibleBoolean,
      },
    };

    return onUpdate(id, updatedValues).then(() => {
      onAddNotification("Member successfully updated", LEVEL_SUCCESS);
    });
  };

  get mainContent() {
    const {
      location,
      member,
      members,
      nonprofitsResult,
      nonprofitsQueryInProgress,
      onClearNonprofitsResult,
      onQueryNonprofits,
      queryMeta = {},
      routeName,
    } = this.props;
    const { cachedNonprofits } = this.state;

    if (routeName === "ManageMemberPage") {
      return (
        <>
          <h1>Members</h1>
          {member && member.id ? (
            <MemberForm
              cachedNonprofits={cachedNonprofits}
              eventRoles={ALLOWED_EVENT_ROLES}
              member={member}
              nonprofitsResult={nonprofitsResult}
              nonprofitsQueryInProgress={nonprofitsQueryInProgress}
              onAttachNonprofit={this.handleAttachNonprofit}
              onClearNonprofitsResult={onClearNonprofitsResult}
              onSubmit={this.handleUpdateMember}
              onQueryNonprofits={onQueryNonprofits}
            />
          ) : (
            <React.Fragment />
          )}
        </>
      );
    } else {
      const { keywords } = this.state;
      const { page, perPage, totalItems, totalPages } = queryMeta;
      const hasPagination = totalPages > 1;

      return (
        <>
          <h1>Members ({queryMeta.totalItems})</h1>
          <Filters location={location} pageName={"MembersPage"}>
            <KeywordFilter
              keywords={keywords}
              onChange={this.handleChangeKeyword}
            />
          </Filters>
          <MemberTable members={members} />
          {hasPagination ? (
            <Pagination
              currentPage={page}
              pageName={"MembersPage"}
              perPage={perPage}
              totalItems={totalItems}
              totalPages={totalPages}
            />
          ) : null}
        </>
      );
    }
  }

  render() {
    return (
      <div className={css.root}>
        <Sidebar />
        <MainContent className={css.mainContent}>
          {this.mainContent}
        </MainContent>
      </div>
    );
  }
}

MembersPage.propTypes = {
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  member: shape({
    id: shape({
      uuid: string,
    }),
    attributes: shape({
      title: string,
      publicData: shape({
        companyName: string,
      }),
    }),
  }),
  members: array,
  nonprofitsResult: array,
  nonprofitsQueryInProgress: bool,
  onClearNonprofitsResult: func.isRequired,
  onSearch: func.isRequired,
  onQueryNonprofits: func.isRequired,
  onUpdate: func.isRequired,
  queryError: bool,
  queryInProgress: bool,
  queryMeta: shape({
    totalItems: number,
  }),
};

MembersPage.defaultProps = {
  member: null,
  members: [],
  nonprofitsResult: [],
  nonprofitsQueryInProgress: false,
  queryError: false,
  queryInProgress: false,
};

const mapStateToProps = (state) => {
  const {
    member,
    members,
    queryError,
    queryInProgress,
    queryMeta,
    updateError,
    updateInProgress,
  } = state.member;
  const {
    nonprofit: cacheableNonprofit,
    nonprofits: nonprofitsResult,
    queryError: nonprofitsQueryError,
    queryInProgress: nonprofitsQueryInProgress,
  } = state.nonprofit;

  return {
    cacheableNonprofit,
    member,
    members,
    nonprofitsResult,
    nonprofitsQueryError,
    nonprofitsQueryInProgress,
    queryError,
    queryInProgress,
    queryMeta,
    updateError,
    updateInProgress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddNotification: (message, level = LEVEL_INFO) =>
    dispatch(addNotification(message, level)),
  onSearch: (queryParams) => dispatch(queryMembers(queryParams)),
  onClearNonprofitsResult: () => dispatch(clearQueryResults()),
  onQueryNonprofits: (queryParams) => dispatch(queryNonprofits(queryParams)),
  onUpdate: (id, updatedValues) => dispatch(updateMember(id, updatedValues)),
});

MembersPage.loadData = (params, queryString) => (dispatch) => {
  if (params.id) {
    return dispatch(fetchMember(params.id)).then((member) => {
      const { boardMemberNPOs = [] } = member.attributes.publicData;
      boardMemberNPOs.forEach((id) => {
        dispatch(fetchNonprofit(id));
      });
    });
  } else {
    return dispatch(queryMembers(parse(queryString)));
  }
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MembersPage);
