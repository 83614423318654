import React from "react";
import { Button, MainContent, Sidebar } from "../../components";
import {
  createUser,
  deleteUser,
  fetchSingleUser,
  fetchUsers,
  updateUser,
} from "../../ducks/user.duck";
import { compose } from "redux";
import { connect } from "react-redux";
import { array, bool, func } from "prop-types";
import { UserForm } from "../../forms";
import { UserTable } from "../../components";
import { createUrlForRoute } from "../../utils/urlHelper";
import css from "./UsersPage.module.css";
import { withRouter } from "react-router-dom";
import { addNotification } from "../../ducks/notification.duck";
import {
  LEVEL_INFO,
  statusToNotificationLevel,
} from "../../utils/notificationHelper";
import { isFailureStatus } from "../../utils/statusHelper";

class UsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  handleClickAddUser = () => {
    const { history } = this.props;
    history.push(createUrlForRoute("NewUserPage"));
  };

  handleCreateUser = (submittedValues) => {
    const { history, onAddNotification, onCreateUser } = this.props;
    return onCreateUser(submittedValues).then((response) => {
      const hasFailed = isFailureStatus(response.status);
      const user = hasFailed ? {} : response.data.user;
      console.log("V", {
        user,
        response,
        hasFailed,
      });
      const message = hasFailed
        ? response.reason
        : `User ${user.email} was created successfully.`;
      onAddNotification(message, statusToNotificationLevel(response.status));
      if (hasFailed) {
        history.push(createUrlForRoute("UsersPage"));
      } else {
        history.push(createUrlForRoute("EditUserPage", { id: user.id }));
      }
    });
  };

  handleUpdateUser = (submittedValues) => {
    if (!submittedValues.password) {
      delete submittedValues.password;
    }

    const { params, onAddNotification, onUpdateUser } = this.props;
    return onUpdateUser(params.id, submittedValues).then((response) => {
      const hasFailed = isFailureStatus(response.status);
      const message = hasFailed
        ? response.payload
        : `User ${submittedValues.email} was updated successfully.`;
      return onAddNotification(
        message,
        statusToNotificationLevel(response.status)
      );
    });
  };

  handleDeleteUser = (id) => {
    const { onAddNotification, onDeleteUser } = this.props;

    if (window.confirm("Are you sure you want to delete this user?")) {
      return onDeleteUser(id).then((response) => {
        const hasFailed = isFailureStatus(response.status);
        const message = hasFailed
          ? response.payload
          : `The user was deleted successfully.`;
        return onAddNotification(
          message,
          statusToNotificationLevel(response.status)
        );
      });
    }

    return Promise.resolve();
  };

  get mainContent() {
    const { routeName, user, users } = this.props;
    switch (routeName) {
      default:
        return null;
      case "UsersPage":
        return (
          <>
            <UserTable onDelete={this.handleDeleteUser} users={users} />
            <div className={css.controls}>
              <Button onClick={this.handleClickAddUser}>
                Create a new user
              </Button>
            </div>
          </>
        );
      case "EditUserPage":
        return (
          <UserForm onSubmit={this.handleUpdateUser} initialValues={user} />
        );
      case "NewUserPage":
        return <UserForm onSubmit={this.handleCreateUser} />;
    }
  }

  render() {
    return (
      <>
        <Sidebar />
        <MainContent>
          <h1>Dashboard users</h1>
          {this.mainContent}
        </MainContent>
      </>
    );
  }
}

UsersPage.propTypes = {
  fetchUsersError: bool,
  fetchUsersInProgress: bool,
  onAddNotification: func.isRequired,
  onCreateUser: func.isRequired,
  onDeleteUser: func.isRequired,
  onUpdateUser: func.isRequired,
  users: array,
};

UsersPage.defaultProps = {
  fetchUsersError: null,
  fetchUsersInProgress: false,
  users: [],
};

const mapStateToProps = (state) => {
  const {
    fetchSingleUserError,
    fetchSingleUserInProgress,
    fetchUsersError,
    fetchUsersInProgress,
    user,
    users,
  } = state.user;
  return {
    fetchSingleUserError,
    fetchSingleUserInProgress,
    fetchUsersError,
    fetchUsersInProgress,
    user,
    users,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddNotification: (message, level = LEVEL_INFO) =>
    dispatch(addNotification(message, level)),
  onCreateUser: (data) => dispatch(createUser(data)),
  onDeleteUser: (id) => {
    return dispatch(deleteUser(id)).then((response) => {
      dispatch(fetchUsers());
      return response;
    });
  },
  onUpdateUser: (id, data) => dispatch(updateUser(id, data)),
});

UsersPage.loadData = (params) => (dispatch) => {
  return Promise.all([
    params && params.id
      ? dispatch(fetchSingleUser(params.id))
      : dispatch(fetchUsers()),
  ]);
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UsersPage);
