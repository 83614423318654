import React from "react";
import css from "./Navigation.module.css";
import NamedLink from "../NamedLink/NamedLink";

class Navigation extends React.Component {
  render() {
    return (
      <nav className={css.root}>
        <ul className={css.items}>
          <li className={css.item}>
            <NamedLink className={css.itemLink} name={"BookingBoardPage"}>
              Booking Board
            </NamedLink>
          </li>
          <li className={css.item}>
            <NamedLink className={css.itemLink} name={"OutreachPage"}>
              Outreach offers
            </NamedLink>
          </li>
          <li className={css.item}>
            <NamedLink className={css.itemLink} name={"GoodAdvertisingPage"}>
              Good Advertising
            </NamedLink>
          </li>
          <li className={css.item}>
            <NamedLink className={css.itemLink} name={"MembersPage"}>
              Members
            </NamedLink>
          </li>
          <li className={css.item}>
            <NamedLink className={css.itemLink} name={"NonprofitsPage"}>
              Nonprofits
            </NamedLink>
          </li>
          <li className={css.item}>
            <NamedLink className={css.itemLink} name={"InvitationsPage"}>
              Invitations
            </NamedLink>
          </li>
          <li className={css.item}>
            <NamedLink className={css.itemLink} name={"UsersPage"}>
              Dashboard users
            </NamedLink>
          </li>
          <li className={css.item}>
            <NamedLink
              className={css.itemLink}
              name="TenantManagerPage"
              pathParams={{ tab: "subscribers" }}
            >
              Subscription manager
            </NamedLink>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navigation;
